/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleGen } from './role.gen.model';
import { UtilisateurFonctionGen } from './utilisateur-fonction.gen.model';
import { EntiteGen } from './entite.gen.model';


export interface UtilisateurGen { 
    actif?: boolean;
    created: Date;
    createdBy: string;
    dtype?: string;
    email?: string;
    fonction?: UtilisateurFonctionGen;
    id?: number;
    instanceLabel: string;
    listEntite?: Set<EntiteGen>;
    listRole?: Array<RoleGen>;
    nom?: string;
    password?: string;
    prenom?: string;
    receptionRapportMail?: boolean;
    tel1?: string;
    tel2?: string;
    updated?: Date;
    updatedBy?: string;
    username: string;
    versionNum?: number;
}

export class UtilisateurGen_ {
  static readonly actif = 'actif';
  readonly actif = 'actif';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly fonction = 'fonction';
  readonly fonction = 'fonction';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listEntite = 'listEntite';
  readonly listEntite = 'listEntite';
  static readonly listRole = 'listRole';
  readonly listRole = 'listRole';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly password = 'password';
  readonly password = 'password';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly receptionRapportMail = 'receptionRapportMail';
  readonly receptionRapportMail = 'receptionRapportMail';
  static readonly tel1 = 'tel1';
  readonly tel1 = 'tel1';
  static readonly tel2 = 'tel2';
  readonly tel2 = 'tel2';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly username = 'username';
  readonly username = 'username';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new UtilisateurGen_();
  }
}

