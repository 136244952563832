import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export class Message {
  constructor(public message: string = '') {
  }
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private success = new Subject<Message>();
  public successObservable = this.success.asObservable();

  private warning = new Subject<Message>();
  public warningObservable = this.warning.asObservable();

  private erreur = new Subject<Message>();
  public erreurObservable = this.erreur.asObservable();

  constructor() {
  }

  showSuccess(message: string): void {
    this.success.next(new Message(message));
  }

  showWarning(message: string): void {
    this.warning.next(new Message(message));
  }

  showError(message: string): void {
    this.erreur.next(new Message(message));
  }

}
