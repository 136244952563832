import { RxStompService } from './rx-stomp.service';
import { RxStompConfig } from "@stomp/rx-stomp";

export function rxStompServiceFactory() {

    const stompConfig: RxStompConfig = {
        heartbeatIncoming: 20000,
        heartbeatOutgoing: 20000,
        reconnectDelay: 10000,
    };
    const rxStomp = new RxStompService();
    rxStomp.configure(stompConfig);
    rxStomp.activate();
    return rxStomp;
}
