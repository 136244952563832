import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AsCoreTab } from '../../../../shared/ascore/component/ascore-tab/ascore-tab.model';
import { AsCoreFormControl } from '../../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import {
    EntiteGen_,
    EntiteServiceGen,
    RoleGen_,
    RoleServiceGen,
    UtilisateurFonctionServiceGen,
    UtilisateurGen_,
    UtilisateurServiceGen
} from '../../../../shared/generated';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AsCoreColumn } from '../../../../shared/ascore/component/ascore-search/ascore-search.component';
import { WithFilterSearchHelper } from '../../../../shared/ascore/service/api/with-filter-search.helper';
import { Filter, filterTextFromForm } from '../../../../shared/ascore/component/ascore-search/filter';
import { AsCoreSelectComponent } from '../../../../shared/ascore/component/ascore-select/ascore-select.component';
import { ComponentCanDeactivate } from '../../../../shared/guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { UtilisateurCourantService } from "../../utilisateur-courant.service";

@Component({
    selector: 'app-utilisateur-detail',
    templateUrl: './utilisateur-detail.component.html',
    styleUrls: ['./utilisateur-detail.component.scss']
})
export class UtilisateurDetailComponent implements ComponentCanDeactivate, OnInit {


    @ViewChild('fonctionSelectSearch', { static: true }) fonctionSelectSearch: AsCoreSelectComponent;
    fonctionWithFilterSearchHelper: WithFilterSearchHelper;
    @ViewChild('listEntiteSelectSearch', { static: true }) listEntiteSelectSearch: AsCoreSelectComponent;
    listEntiteWithFilterSearchHelper: WithFilterSearchHelper;


    faTimes = faTimes;

    public form: FormGroup;

    tabRoleColumns: AsCoreColumn[] = [
        { header: 'Libellé', fieldName: RoleGen_.instanceLabel, sortFieldName: RoleGen_.instanceLabel },
    ];

    tabEntiteColumns: AsCoreColumn[] = [
        { header: 'Libellé', fieldName: EntiteGen_.instanceLabel, sortFieldName: EntiteGen_.instanceLabel },
    ];


    tabs: AsCoreTab[] = [];

    creation = false;

    fields = UtilisateurGen_.fields();

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                public utilisateurFonctionService: UtilisateurFonctionServiceGen,
                public roleService: RoleServiceGen,
                public entiteService: EntiteServiceGen,
                public service: UtilisateurServiceGen,
                private utilisateurCourantService: UtilisateurCourantService) {

        this.fonctionWithFilterSearchHelper = WithFilterSearchHelper.forAsCoreSearch(
            this.utilisateurFonctionService,
            this.fonctionFilterBuilderFactory());
        this.listEntiteWithFilterSearchHelper = WithFilterSearchHelper.forAsCoreSearch(
            this.entiteService,
            this.listEntiteFilterBuilderFactory());
    }

    canDeactivate: () => boolean | Observable<boolean> = () => {
        return this.form.pristine;
    };

    fonctionFilterBuilderFactory(): () => Filter {
        return () => {
            const searchForm: FormGroup = this.fb.group({ ['recherche']: AsCoreFormControl.init(this.fonctionSelectSearch?.currentSearch) });

            let result = Filter.empty()
                .and(Filter.empty()
                    .or(filterTextFromForm(searchForm, 'recherche', 'contains', 'libelle'))
                );

            return result;
        };
    }

    listEntiteFilterBuilderFactory(): () => Filter {
        return () => {
            const searchForm: FormGroup = this.fb.group({ ['recherche']: AsCoreFormControl.init(this.listEntiteSelectSearch?.currentSearch) });

            let result = Filter.empty()
                .and(Filter.empty()
                    .or(filterTextFromForm(searchForm, 'recherche', 'contains', 'libelle'))
                );

            return result;
        };
    }

    getTabs(): AsCoreTab[] {
        const tabs: AsCoreTab[] = [
            {
                titre: 'Rôles',
                type: 'lien',
                withSearch: this.roleService,
                columns: this.tabRoleColumns,
                formControlName: UtilisateurGen_.listRole,
                searchFieldName: 'recherche',
            },

            {
                titre: 'Entités',
                type: 'lien',
                withFilterSearchHelper: this.listEntiteWithFilterSearchHelper,
                columns: this.tabEntiteColumns,
                formControlName: UtilisateurGen_.listEntite,
                searchFieldName: 'recherche',
            },
        ];

        return tabs;
    }

    ngOnInit(): void {
        this.creation = this.route.snapshot.routeConfig.path === 'creation';
        this.createForm();
        this.tabs = this.getTabs();
    }

    createForm(): void {
        this.form = this.fb.group({
            [this.fields.username]: AsCoreFormControl.init().required().label('Identifiant'),
            [this.fields.password]: AsCoreFormControl.init().label('Mot de passe'),
            [this.fields.nom]: AsCoreFormControl.init().label('Nom'),
            [this.fields.prenom]: AsCoreFormControl.init().label('Prénom'),
            [this.fields.fonction]: AsCoreFormControl.init().required().label('Fonction'),
            [this.fields.tel1]: AsCoreFormControl.init().label('Téléphone 1'),
            [this.fields.tel2]: AsCoreFormControl.init().label('Téléphone 2'),
            [this.fields.email]: AsCoreFormControl.init().label('Email'),
            [this.fields.actif]: AsCoreFormControl.init().required().label('Actif'),
            [this.fields.listRole]: AsCoreFormControl.init().label('Rôles'),
            [this.fields.listEntite]: AsCoreFormControl.init([]).label('Entités'),
            [this.fields.id]: AsCoreFormControl.init().noLabel(),
            [this.fields.versionNum]: AsCoreFormControl.init().noLabel(),
            [this.fields.receptionRapportMail]: AsCoreFormControl.init().label('Réception des rapports par mail'),
        });
    }

    public isAdmin() {
        return this.utilisateurCourantService.utilisateurCourantHasRole("ROLE_ADMIN")
    }
}
