import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[asCoreCapitalizeFully]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CapitalizeFullyDirective),
    multi: true
  }]
})
export class CapitalizeFullyDirective extends DefaultValueAccessor {

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  private static transformValue(value: any): any {
    if (!value || (typeof value) !== 'string') {
      return value;
    }
    let result: string = value;

    // jean-louis david => Jean-louis David
    result = result.replace(
      /\.*?\S*/g,
      (w) => (w.trim().toLowerCase().replace(/^./, (c) => c.toUpperCase())));

    // Jean-louis => Jean-Louis David
    result = result.replace(
      /-./g,
      (w) => '-' + w.charAt(1).toUpperCase());

    return result;
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const transformed = CapitalizeFullyDirective.transformValue((event.target as any).value);
    super.writeValue(transformed);
    this.onChange(transformed);
    this.onTouched();
  }
}
