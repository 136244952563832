import { SortObjectGen } from '../../generated';

/**
 * Vérifié : chaque champ ci-dessous est renseigné dans le flux venant du back (hors additionalInfo rajouté)
 */
export class PagedRessource<T> {
  // champs standards:
  content?: T[] = [];
  first ? = true;
  last ? = true;
  number ? = 0;
  numberOfElements ? = 0;
  pageable ? = new Pageable();
  sort?: SortObjectGen = new Sort();
  /** taille de la page */
  size ? = 0;
  totalElements ? = 0;
  totalPages ? = 0;

  // champ complémentaire custom:
  additionalInfo?: Map<string, any>;
}

/**
 * Vérifié : chaque champ ci-dessous est renseigné dans le flux venant du back
 */
export class Pageable {
  offset ? = 0;
  pageNumber ? = 0;
  pageSize ? = 0;
  paged ? = true;
  sort ? = new Sort();
  unpaged ? = false;
}

/**
 * Vérifié : chaque champ ci-dessous est renseigné dans le flux venant du back
 */
export class Sort {
  sorted ? = true;
  unsorted ? = false;
  empty ? = true;
}
