/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientExternalDtoGen { 
    adresse?: string;
    email?: string;
    nom?: string;
    prenom?: string;
    professionnel?: boolean;
    prospect?: boolean;
    telephone?: string;
}

export class ClientExternalDtoGen_ {
  static readonly adresse = 'adresse';
  readonly adresse = 'adresse';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly professionnel = 'professionnel';
  readonly professionnel = 'professionnel';
  static readonly prospect = 'prospect';
  readonly prospect = 'prospect';
  static readonly telephone = 'telephone';
  readonly telephone = 'telephone';
  static fields() {
    return new ClientExternalDtoGen_();
  }
}

