/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactFonctionGen } from './contact-fonction.gen.model';
import { EntiteGen } from './entite.gen.model';
import { ClientGen } from './client.gen.model';
import { ContactTagGen } from './contact-tag.gen.model';


export interface ContactGen { 
    client: ClientGen;
    commentaire?: string;
    created: Date;
    createdBy: string;
    dtype?: string;
    fonction?: ContactFonctionGen;
    id?: number;
    instanceLabel: string;
    listEntite?: Set<EntiteGen>;
    listTag?: Set<ContactTagGen>;
    mail?: string;
    nom: string;
    prenom?: string;
    telephone?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ContactGen_ {
  static readonly client = 'client';
  readonly client = 'client';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly fonction = 'fonction';
  readonly fonction = 'fonction';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listEntite = 'listEntite';
  readonly listEntite = 'listEntite';
  static readonly listTag = 'listTag';
  readonly listTag = 'listTag';
  static readonly mail = 'mail';
  readonly mail = 'mail';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly telephone = 'telephone';
  readonly telephone = 'telephone';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ContactGen_();
  }
}

