<div class="modal-header">
    <h6 class="modal-title">Résolution d'un conflit</h6>
    <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>
<div class="modal-body" [formGroup]="form">

    <div class="alert alert-danger mb-2 font-weight-bold text-center">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        Quelqu'un a modifié les mêmes données que vous. Vous pouvez choisir quels champs conserver ou recharger et
        abandonner vos changements.
    </div>

    <div class="table-container">
        <ascore-table [formGroup]="form"
                      [columns]="columns"
                      [content]="content">
        </ascore-table>
    </div>
</div>

<ng-template #propertyTpl let-value="value">
    <div class="d-flex" style="justify-content: space-between; align-items: baseline">
        <div class="ml-2 mt-3 pb-1">
            <p class="form-control form-control-sm p-0 m-0 text-large">{{ getLibelleFromProperty(value) }}</p>
        </div>
        <fa-icon class="pb-2" [icon]="sameValue(value) ? faCheckCircle : faExclamationTriangle"
                 [style.color]="sameValue(value) ? 'green' : 'yellow'"></fa-icon>
    </div>
</ng-template>

<ng-template #versionTpl let-value="value">
    <div class="d-flex align-items-center">
        <div class="col-1 d-flex" [formGroup]="form">
            <input type="checkbox" *ngIf="!sameValue(value)"
                   [formControlName]="value"
            >
        </div>
        <div class="col-10 d-flex mt-3" [formGroup]="form">
            <input type="text" *ngIf="!isArray(value + 'Value')"
                   [readOnly]="true"
                   [formControlName]="value + 'Value'"
            >
            <p *ngIf="isArray(value + 'Value')"
               class="form-control form-control-sm p-0 m-0 text-large">{{ getArrayAsString(value + 'Value') }}</p>
        </div>
    </div>
</ng-template>

<ng-template #resultTpl let-value="value">
    <div class="d-flex" style="justify-content: space-between">
        <div class="col-10 d-flex mt-3" [formGroup]="form">
            <input type="text" *ngIf="!isArray(value + 'Result')"
                   [readOnly]="true"
                   [formControlName]="value + 'Result'"
            >
            <p *ngIf="isArray(value + 'Result')"
               class="form-control form-control-sm p-0 m-0 text-large">{{ getArrayAsString(value + 'Result') }}</p>
        </div>
        <fa-icon class="m-2" *ngIf="noVersionSelected(value)" [icon]="faExclamationCircle" [style.color]="'red'"
                 ngbTooltip="Vous devez choisir une version pour ce champ"></fa-icon>
    </div>
</ng-template>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.dismiss()">
        <fa-icon [icon]="faReply"></fa-icon>
        Revenir à l'édition
    </button>
    <button type="button" class="btn btn-primary"
            ngbTooltip="Vous devez choisir une version pour chaque champ"
            (click)="everyPropertyHasVersionSelected() ? modal.close(getResult()) : ''">
        <fa-icon [icon]="faSave"></fa-icon>
        Valider les changements
    </button>
    <button type="button" class="btn btn-secondary"
            (click)="modal.close()">
        <fa-icon [icon]="faRedoAlt"></fa-icon>
        Abandonner mes changements
    </button>
</div>
