import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AsCoreColumn } from '../ascore-search/ascore-search.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { WithSearchAudit } from '../../service/api/with-search-audit';
import { WithSearch } from '../../service/api/with-search';
import { WithSearchAuditAdapter } from './with-search-audit-adapter';
import { AuditChangeGen, AuditCommitGen, SliceAuditCommitGen } from '../../../generated';


@Component({
  selector: 'ascore-audit-commit',
  templateUrl: './ascore-audit.component.html',
  styleUrls: ['./ascore-audit.component.scss']
})
export class AsCoreAuditComponent implements OnInit {

  columns: AsCoreColumn[];
  content: AuditCommitGen[];

  @Input()
  withSearchAudit: WithSearchAudit

  @Input()
  entityId: number;

  withSearch: WithSearch<any, SliceAuditCommitGen>

  @ViewChild('listAuditChangeTemplate', {static: true}) listAuditChangeTemplate: ElementRef;

  searchForm: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.searchForm = this.fb.group({});
    this.withSearch = new WithSearchAuditAdapter(this.entityId, this.withSearchAudit);
  }

  private getColumns() {
    return [
      {header: 'Auteur', fieldName: 'author'},
      {header: 'Date/heure', fieldName: 'dateTime'},
      {
        header: 'Changements',
        fieldName: 'listAuditChange',
        template: this.listAuditChangeTemplate
      }
    ];
  }

  arrayToListRepr(array: AuditChangeGen[]): string {
    return array.map(item => item.description).join('\n');
  }
}
