import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import {
  AsCoreConfirmModalComponent
} from '../ascore/component/ascore-confirm-modal/ascore-confirm-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private modalService: NgbModal) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ?
      true :
      // Le message s'affiche seulement si on navigue dans l'application, si on sort de l'app on aura un message générique du navigateur.
      // cf http://stackoverflow.com/a/42207299/7307355
      from(this.getModalConfirmNavigation().result.then((ok) => true, (annuler) => false));
  }

  private getModalConfirmNavigation(): NgbModalRef {
    const modalConfirm = this.modalService.open(AsCoreConfirmModalComponent, {backdrop: 'static', size: 'xl'});
    modalConfirm.componentInstance.titre = 'Confirmer la navigation';
    modalConfirm.componentInstance.messageImportant = 'Vous avez des modifications non enregistrées';
    modalConfirm.componentInstance.labelOk = 'Abandonner les modifications et changer de page';
    modalConfirm.componentInstance.labelAnnuler = 'Revenir aux modifications pour les enregistrer';
    return modalConfirm;
  }
}
