/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LabelValueDtoGen { 
    label?: string;
    value?: number;
}

export class LabelValueDtoGen_ {
  static readonly label = 'label';
  readonly label = 'label';
  static readonly value = 'value';
  readonly value = 'value';
  static fields() {
    return new LabelValueDtoGen_();
  }
}

