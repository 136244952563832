import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PasswordConstants } from './password-constants';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnInit, OnChanges {

  @Input() passwordControl: AbstractControl;
  securityLevel: number;
  securityText: string;

  lessThanEight: boolean;
  missingLowercase: boolean;
  missingLetter: boolean;
  missingNumber: boolean;
  missingSpecial: boolean;

  ngOnInit() {
    this.setDefaultValues();
  }

  ngOnChanges() {
    this.passwordControl.valueChanges.subscribe(value => {
      this.updateSecurityMeter(value);
    });
  }

  /**
   * Valeurs par défaut des différents éléments affichés
   */
  setDefaultValues() {
    this.securityLevel = 0;
    this.securityText = '';
    this.lessThanEight = true;
    this.missingLowercase = true;
    this.missingLetter = true;
    this.missingNumber = true;
    this.missingSpecial = true;
  }

  checkConditions(password: string): number {
    let nbValidConditions: number = PasswordConstants.NB_REGEX;

    // Cette condition ne fait pas partie des 3 conditions minimum valides : elle est obligatoire et donc bloquante si pas respectée
    if (password.length < PasswordConstants.MIN_LENGTH) {
      this.lessThanEight = true;
    } else {
      this.lessThanEight = false;
    }

    // Lettre obligatoire
    if (!password.match(PasswordConstants.REGEX_LETTER)) {
      this.missingLetter = true;
      nbValidConditions -= 1;
    } else {
      this.missingLetter = false;
    }

    // Numérique obligatoire
    if (!password.match(PasswordConstants.REGEX_NUMERIC)) {
      this.missingNumber = true;
      nbValidConditions -= 1;
    } else {
      this.missingNumber = false;
    }

    // Caractère spécial obligatoire
    if (!password.match(PasswordConstants.REGEX_SPECIAL)) {
      this.missingSpecial = true;
      nbValidConditions -= 1;
    } else {
      this.missingSpecial = false;
    }

    return nbValidConditions;
  }

  /**
   * Mise à jour des informations de la barre de niveau de sécurité du mot de passe
   */
  updateSecurityMeter(password: string) {
    if (!password) {
      this.setDefaultValues();
    } else {
      const nbValidConditions = this.checkConditions(password);

      // RG - Niveau de sécurité
      if (password.length === 0) {
        this.securityText = '';
        this.securityLevel = 0;
      } else if (nbValidConditions < PasswordConstants.NB_VALID_REGEX_MIN || password.length < PasswordConstants.MIN_LENGTH) {
        this.securityText = 'Faible';
        this.securityLevel = 1;
      } else if (nbValidConditions <= PasswordConstants.NB_VALID_REGEX_MIN && password.length < PasswordConstants.SECURE_LENGTH) {
        this.securityText = 'Moyenne';
        this.securityLevel = 2;
      } else {
        this.securityText = 'Forte';
        this.securityLevel = 4;
      }
    }
  }

}
