import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './flatable-components';
import { DataFilterPipe } from './flatable-components/data-table/data-filter.pipe';
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { SpinnerComponent } from './flatable-components/spinner/spinner.component';
import { ToastComponent } from './flatable-components/toast/toast.component';
import { ToastService } from './flatable-components/toast/toast.service';
import { GalleryComponent } from './flatable-components/gallery/gallery.component';
import { LightboxModule } from 'ngx-lightbox';
import { AsCoreModule } from './ascore/ascore.module';
import { ColorPickerModule } from "ngx-color-picker";
import { MentionModule } from "angular-mentions";
import { ComboChartComponent, ComboSeriesVerticalComponent } from "./combo-chart";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { DocumentUtil } from "../administration/referentiel/document/document-util";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        ClickOutsideModule,
        LightboxModule,
        AsCoreModule,
        ColorPickerModule,
        MentionModule,
        NgxChartsModule
    ],
    exports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        DataFilterPipe,
        ClickOutsideModule,
        SpinnerComponent,
        GalleryComponent,
        ToastComponent,
        ColorPickerModule,
        MentionModule,
        ComboChartComponent,
        ComboSeriesVerticalComponent
    ],
    declarations: [
        DataFilterPipe,
        SpinnerComponent,
        ToastComponent,
        GalleryComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ToastService,
        DocumentUtil
    ]
})
export class SharedModule {
}
