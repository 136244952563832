<ul class="navbar-nav mr-auto">
    <li class="nav-item" *ngIf="!this.flatConfig['box-layout']">
        <div class="mega-menu dropdown" ngbDropdown placement="auto">
            <a class="h-drop" href="javascript:" ngbDropdownToggle style="color: white">
                <i class="fas fa-user-shield"></i>&nbsp;&nbsp;Paramétrage / référentiel
            </a>
            <div class="profile-notification" ngbDropdownMenu>
                <div class="row no-gutters">
                    <div class="col">
                        <ul class="pro-body">
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="pro-body">
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="pro-body">
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </li>
</ul>
