<div class="text-center">
    <div class="btn-container">
        <button *ngFor="let option of options"
                class="btn btn-primary ml-2 mr-2"
                [class.active]="isSelected(option)"
                type="button"
                (click)="select(option.value)">
            {{option.label}}
        </button>
    </div>
</div>
<div>

    <div *ngFor="let option of options">
        <div *ngIf="isSelected(option)">
            <ng-container
                    *ngTemplateOutlet="option.template">
            </ng-container>
        </div>
    </div>

</div>

