<ul class="pagination">

    <li title="Précédent" class="page-item" [class.disabled]="first">
        <a class="page-link" (click)="selectPreviousSlice()">
            <span aria-hidden="true">«</span>
        </a>
    </li>

    <li title="Page courante" class="page-item active" aria-current="page">
        <a class="page-link ng-star-inserted"> {{page}}</a>
    </li>

    <li title="Suivant" class="page-item" [class.disabled]="last">
        <a class="page-link" (click)="selectNextSlice()">
            <span aria-hidden="true">»</span>
        </a>
    </li>
</ul>
