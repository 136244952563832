import { Injectable, Optional } from '@angular/core';
import { LoginForm } from '../../authentication/login/login.component';
import { isNil } from 'lodash';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AsCoreConfiguration, RoleGen, UtilisateurGen, UtilisateurServiceGen } from '../../shared/generated';
import { Observable } from 'rxjs';
import { enumEquals } from "../../shared/ascore/models/ascore-model-util";

@Injectable({
    providedIn: 'root'
})
export class UtilisateurCourantService {

    private static nbInstanciation: number = 0;
    private static utilisateurCourant: UtilisateurGen = null;
    public configuration = new AsCoreConfiguration();

    constructor(protected httpClient: HttpClient,
                public router: Router,
                public utilisateurService: UtilisateurServiceGen,
                @Optional() configuration: AsCoreConfiguration) {
        UtilisateurCourantService.nbInstanciation++;
        this.configuration = configuration;
    }

    public updateUtilisateurCourant(utilisateurCourant: UtilisateurGen): void {
        UtilisateurCourantService.utilisateurCourant = utilisateurCourant;
        if (utilisateurCourant == null) {
            localStorage.removeItem('utilisateur');
        } else {
            localStorage.setItem('utilisateur', JSON.stringify(utilisateurCourant));
        }
    }

    public getUtilisateurCourant(): UtilisateurGen {
        let result: UtilisateurGen;
        if (!isNil(UtilisateurCourantService.utilisateurCourant?.id)) {
            result = UtilisateurCourantService.utilisateurCourant;
        } else {
            UtilisateurCourantService.utilisateurCourant = JSON.parse(localStorage.getItem('utilisateur')) as UtilisateurGen;
            result = UtilisateurCourantService.utilisateurCourant;
        }
        return result;
    }

    login(loginForm: LoginForm): void {
        this.httpClient.post<void>(`${this.configuration.basePath}/api/login`, loginForm, { observe: 'response' }).subscribe(response => {
            localStorage.setItem('jwt_token', response.headers.get('authorization'));
            this.utilisateurService.findByUsername(loginForm.username).subscribe(result => {
                const utilisateur = result as UtilisateurGen;
                this.updateUtilisateurCourant(utilisateur);
                const url = sessionStorage.getItem('routeBeforeLogin');
                this.router.navigate([isNil(url) ? '/' : url]);
                sessionStorage.removeItem('routeBeforeLogin');
            });
        });
    }

    verifieLogin(param: LoginForm): Observable<HttpResponse<void>> {
        return this.httpClient.post<void>('/api/login', param, { observe: 'response' });
    }

    reset(): void {
        this.updateUtilisateurCourant(null);
        this.clearSessionStorageExceptRouteBeforeLogin();
    }

    logout(): void {
        this.reset();
        this.router.navigate(['/auth/connexion']);
    }

    utilisateurCourantHasRole(role: RoleGen): boolean {
        return !isNil(this.getUtilisateurCourant()?.listRole?.find(value => enumEquals(value, role)));
    }

    // getListDroitCourant(): Array<DroitDtoGen> {
    //   const utilisateurCourant = this.getUtilisateurCourant();
    //   const result = utilisateurCourant?.listDroitCourant;
    //   return !isNil(result) ? result : [];
    // }

    // public setUtilisateurCourant(utilisateur: Utilisateur): void {
    //   this.utilisateurCourant.set(utilisateur);
    //   if (utilisateur == null) {
    //     sessionStorage.removeItem('utilisateur');
    //   } else {
    //     sessionStorage.setItem('utilisateur', JSON.stringify(utilisateur));
    //   }
    // }

    // public getUtilisateurCourant(): Utilisateur {
    //   let utilisateur = this.utilisateurCourant.get();
    //   if (utilisateur != null && utilisateur.id != null) {
    //     return utilisateur;
    //   }
    //   utilisateur = Utilisateur.from(JSON.parse(sessionStorage.getItem('utilisateur')));
    //   this.utilisateurCourant.set(utilisateur);
    //   return utilisateur;
    // }

    // reset(): void {
    //   this.setUtilisateurCourant(null);
    //   sessionStorage.clear();
    // }
    //
    // logout(): void {
    //   this.router.navigate(['/auth/connexion']);
    // }

    private clearSessionStorageExceptRouteBeforeLogin() {
        const routeBeforeLogin = sessionStorage.getItem('routeBeforeLogin');
        sessionStorage.clear();
        if (!isNil(routeBeforeLogin)) {
            sessionStorage.setItem('routeBeforeLogin', routeBeforeLogin);
        }
    }
}
