import {Injectable} from '@angular/core';
import { WebSocketOptions, WebSocketService } from './websocket.service';
import { environment } from '../../../../environments/environment';
import { RxStompConfig } from "@stomp/rx-stomp";
import { RxStompService } from "../../../rx-stomp.service";

export const stompConfig: RxStompConfig = {
  webSocketFactory: () => {
    return new WebSocket(environment.webSocketHandshakeURI)
  }
};

@Injectable()
export class RefreshWebsocketService extends WebSocketService {
  constructor(stompService: RxStompService) {
    super(stompService, stompConfig, new WebSocketOptions('/topic/refresh'))
  }
}
