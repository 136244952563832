export const generatedNavigationItems = [{
    "id": "clients",
    "title": "Clients",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
        "id": "clients",
        "title": "Clients",
        "type": "item",
        "icon": "fa fa-users",
        "url": "clients/clients",
        "role": "ROLE_CONSULTATION",
        "children": null
    },{
        "id": "facture",
        "title": "Facture",
        "type": "item",
        "icon": "fa fa-file-invoice-dollar",
        "url": "factures/factures",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "devis",
        "title": "Devis",
        "type": "item",
        "icon": "fa fa-file-invoice",
        "url": "devis/devis",
        "role": "ROLE_CONSULTATION",
        "children": null
    } ]
}, {
    "id": "contacts",
    "title": "Contacts",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
        "id": "contacts",
        "title": "Contacts",
        "type": "item",
        "icon": "fa fa-address-card",
        "url": "contacts/contacts",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "fonctions",
        "title": "Fonctions",
        "type": "item",
        "icon": "fa fa-user-cog",
        "url": "contacts/contacts-fonctions",
        "role": "ROLE_CONSULTATION",
        "children": null
    }]
}, {
    "id": "actions",
    "title": "Actions",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
        "id": "actions",
        "title": "Actions",
        "type": "item",
        "icon": "fa fa-tasks",
        "url": "actions/actions-clients",
        "role": "ROLE_CONSULTATION",
        "children": null
    },{
        "id": "rappels",
        "title": "Rappels",
        "type": "item",
        "icon": "fa fa-clock",
        "url": "actions/rappels",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "objets",
        "title": "Objets",
        "type": "item",
        "icon": "fa fa-clipboard-list",
        "url": "actions/actions-objets",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "types",
        "title": "Types",
        "type": "item",
        "icon": "fa fa-cogs",
        "url": "actions/actions-types",
        "role": "ROLE_CONSULTATION",
        "children": null
    }]
}, {
    "id": "groupe",
    "title": "Groupe",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
        "id": "entites",
        "title": "Entités",
        "type": "item",
        "icon": "fa fa-building",
        "url": "groupe/entites",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "fonctions",
        "title": "Fonctions",
        "type": "item",
        "icon": "fa fa-user-cog",
        "url": "groupe/utilisateurs-fonctions",
        "role": "ROLE_CONSULTATION",
        "children": null
    }, {
        "id": "utilisateurs",
        "title": "Utilisateurs",
        "type": "item",
        "icon": "fa fa-user-tie",
        "url": "groupe/utilisateurs",
        "role": "ROLE_ADMIN",
        "children": null
    }]
}, {
    "id": "parametrage",
    "title": "Paramétrage",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
        "id": "tags",
        "title": "Tags",
        "type": "item",
        "icon": "fa fa-tags",
        "url": "parametrage/tags",
        "role": "ROLE_CONSULTATION",
        "children": null
    }]
}];

