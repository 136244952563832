import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { RoleGen } from '../../../../shared/generated';
import { UtilisateurCourantService } from '../../../../administration/referentiel/utilisateur-courant.service';
import { generatedNavigationItems } from './navigation-generated';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  param?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const navigationItems = [
  {
    id: 'id-user',
    title: '',
    type: 'group',
    children: [
      {
        id: 'mon-compte',
        title: 'Mon compte',
        type: 'item',
        url: '/mon-compte',
        classes: 'nav-item',
        icon: 'fas fa-user'
      }
    ]
  },
  {
    id: 'dashboard',
    title: null,
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Tableau de bord',
        type: 'item',
        url: '/dashboard',
        classes: 'nav-item',
        icon: 'fas fa-chart-line',
        role: RoleGen.GESTIONNAIRE
      }
    ]
  }
];


@Injectable()
export class NavigationItem {

  constructor(public utilisateurCourantService: UtilisateurCourantService) {
  }

  private isVisible(navigationItem): boolean {
    if (isNil(navigationItem)) {
      return false;
    }
    return isNil(navigationItem.role) || this.utilisateurCourantService.utilisateurCourantHasRole(navigationItem.role);
  }

  private filtreNavigationItemsTree(navigationItems: Array<any>): Array<any> {
    if (isNil(navigationItems)) {
      return navigationItems;
    }
    let result = navigationItems.filter(navigationItem => this.isVisible(navigationItem));
    result?.forEach(navigationItem => navigationItem.children = this.filtreNavigationItemsTree(navigationItem.children));
    return result;
  }

  public get() {
    let nav = [].concat(navigationItems).concat(generatedNavigationItems)
    return this.filtreNavigationItemsTree(nav);
  }
}
