<ascore-detail
        [form]="form"
        domainName="Utilisateur"
        [withCrud]="utilisateurService"
        [title]="'Mise à jour de mes informations personnelles'"
        [entity]="utilisateur"
>

    <form [formGroup]="form" autocomplete="off">

        <div class="row">

            <div class="col-md-4">
                <input type="text"
                       formControlName="email">
                <input type="text"
                       asCoreUppercase
                       formControlName="tel1"
                >
                <input type="text"
                       asCoreUppercase
                       formControlName="tel2"
                >
            </div>

            <div class="col-md-4">
                <div style="height: 1em"></div>
                <app-alert type="info">
                    Pour modifier vos autres informations (fonction, rôles...) vous devez contacter un administrateur.
                </app-alert>
            </div>

            <div class="col-md-4">
                <label class="label-ascore mt-2">Société</label>
                <a href="https://gcf.nc" alt="AgileSoft.NC" class="d-flex">
                    <img [style.max-height]="'40px'"
                         alt="Logo"
                         src="assets/images/logo-GCF.png">
                </a>

                <button type="button" class="btn btn-primary mt-2"
                        (click)="changePassword()">
                    <fa-icon [icon]="faKey"></fa-icon>
                    Changer le mot de passe
                </button>
            </div>
        </div>

    </form>
</ascore-detail>
