<ul class="navbar-nav ml-auto" style="color: #535763">

    <li class="ml-2">{{utilisateurConnecte}}</li>

    <li>
    <span>
      <i class="p-1 fas fa-user-cog" (click)="ouvreCompte()" ngbTooltip="Mon compte"></i>
    </span>
    </li>

    <li class="ml-3">
        <span>
                <i class="p-1 fas fa-sign-out-alt"
                   (click)="logout()"
                   style="cursor: pointer;"
                   ngbTooltip="Se déconnecter"></i>
        </span>
    </li>
</ul>
