import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[asCoreRib]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RibDirective),
    multi: true
  }]
})
export class RibDirective extends DefaultValueAccessor {

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  private static lastValue = '';

  private static transformValue(value: any): any {

    if (!value || (typeof value) !== 'string') {
      this.lastValue = value;
      return value;
    }
    let result: string = value;

    // Suppression de tous les espaces
    result = result.split(' ').join('');

    // format code banque
    if (result.length > 5) {
      result = [result.slice(0, 5), ' ', result.slice(5)].join('');
    }
    if (result.length > 11) {
      result = [result.slice(0, 11), ' ', result.slice(11)].join('');
    }
    if (result.length > 23) {
      result = [result.slice(0, 23), ' ', result.slice(23)].join('');
    }

    this.lastValue = result.toUpperCase();
    return result.toUpperCase();
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const transformed = RibDirective.transformValue(event.target['value']);
    super.writeValue(transformed);
    this.onChange(transformed);
    this.onTouched();
  }

  @HostListener('ngModelChange', ['$event'])
  public ngModelChange(value) {

    if (RibDirective.lastValue === value) {
      return;
    }

    const transformed = RibDirective.transformValue(value);
    super.writeValue(transformed);
    this.onChange(transformed);
    this.onTouched();
  }
}
