import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../service/message.service';
import { AsCoreBaseDomain } from '../../models/ascore-base-domain';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AsCoreTab } from '../ascore-tab/ascore-tab.model';
import { AsCoreConfirmModalComponent } from '../ascore-confirm-modal/ascore-confirm-modal.component';
import { WithCrud } from '../../service/api/with-crud';
import { infoHistorique } from '../../models/ascore-model-util';
import { IdInstanceLabel } from '../../models/id-instance-label';
import { WithCreate } from '../../service/api/with-create';

@UntilDestroy()
@Component({
  selector: 'ascore-detail',
  templateUrl: './ascore-detail.component.html',
  styleUrls: ['./ascore-detail.component.scss']
})
export class AsCoreDetailComponent implements OnChanges, OnInit {

  faSave = faSave;
  faTimes = faTimes;

  infoHistorique: string;

  @Input()
  title: string;

  @Input()
  readOnly = false;

  @Input()
  status: string;

  @Input()
  domainName: string;

  @Input()
  showDomainName = true;

  @Input()
  form: FormGroup;

  @Input()
  withCrud: WithCrud<AsCoreBaseDomain>;

  @Input()
  withCreate: WithCreate<AsCoreBaseDomain>;

  @Input()
  tabs: AsCoreTab[] = [];

  /** barre de boutons avec enregistrer, annuler */
  @Input()
  toolbarVisible = true;

  @Input()
  disablePadding = false;

  @Input()
  exitAfterUpdate = true;

  @Input()
  exitAfterCreate = true;

  @Input()
  entity: AsCoreBaseDomain;

  @Input()
  header = true;

  @Input()
  customCancel = false;

  @Input()
  customSave = false;

  @Input()
  customTitle: TemplateRef<any>;

  @Input()
  confirmationMessage: string = null;

  @Input()
  annulerVisible: boolean = true;

  @Input()
  forceShowHistory: boolean = false;

  @Input()
  transformDomainBeforeSave = () => this.form.getRawValue()

  @Output()
  afterInitForm = new EventEmitter<IdInstanceLabel>();

  @Output()
  afterSave = new EventEmitter();

  @Output()
  cancelTriggered = new EventEmitter();

  @Output()
  saveTriggered = new EventEmitter();

  initialData: AsCoreBaseDomain = null;

  loaded = false;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private activeRoute: ActivatedRoute,
              private messageService: MessageService,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService) {
    this.initialData = router.getCurrentNavigation()?.extras?.state?.data;
  }

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        untilDestroyed(this),
        skip(1)
      ) // Skip le premier chargement du composant
      .subscribe(routeParams => {
        this.initFormValue();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.services || changes.form || changes.domainName || changes.entity)
      && this.withCrud && this.form && this.domainName) {
      this.initFormValue();
    }
  }

  initFormValue(): void {
    // On veut toujours la donnée la plus fraiche possible !
    const id = this.route.snapshot?.params?.id || this.entity?.id;

    if (this.readOnly) {
      this.form.disable();
    }

    if (id) {
      const spinnerTimer = setTimeout(() => this.spinner.show('ascore-detail-spinner'), 1000);
      this.withCrud.find(id).subscribe((result: AsCoreBaseDomain) => {
        this.patchExistingDomain(result);
        this.loaded = true;
        this.spinner.hide('ascore-detail-spinner');
        clearTimeout(spinnerTimer);
        this.afterInitForm.emit(result);
      });
    } else {
      if (this.showDomainName) {
        this.title = `${this.domainName} : création`;
      } else {
        this.title = `Création`;
      }
      if (this.entity || this.initialData) {
        this.form.patchValue(this.entity || this.initialData);
      }
      this.loaded = true;
      this.afterInitForm.emit(this.entity);
    }
  }

  patchExistingDomain(result: AsCoreBaseDomain): void {
    this.form.patchValue(result);
    if (this.showDomainName) {
      this.title = `${this.domainName} ${result.instanceLabel}`;
    } else {
      this.title = result.instanceLabel;
    }
    this.infoHistorique = infoHistorique(result);
  }

  save(): void {

    if (this.form.invalid) {
      return;
    }

    if (this.confirmationMessage) {
      const modal = this.modalService.open(AsCoreConfirmModalComponent, {backdrop: 'static'});
      modal.componentInstance.message = this.confirmationMessage;
      modal.result.then(() => {
          this.triggerSave();
        },
        () => {
          // Do nothing
        });
    } else {
      this.triggerSave();
    }
  }

  private triggerSave(): void {
    if (!this.customSave) {
      this.doSave();
    } else {
      const domain = this.form.getRawValue();
      this.saveTriggered.emit(domain);
    }
  }

  public doSave(): void {
    const domain = this.transformDomainBeforeSave();
    if (domain.id) {
      this.withCrud.update(domain.id, domain).subscribe((result) => {
        this.messageService.showSuccess(`${this.domainName} mis(e) à jour`);
        if (this.exitAfterUpdate) {
          this.goToSearchComponent();
          this.form.reset();
        } else {
          this.patchExistingDomain(result);
        }
        this.afterSave.emit(result);
      }, (error) => {
      });
    } else {
      (this.withCreate ? this.withCreate : this.withCrud).create(domain).subscribe((result) => {
        this.messageService.showSuccess(`${this.domainName} créé(e)`);
        if (this.exitAfterCreate) {
          this.goToSearchComponent();
          this.form.reset();
        } else {
          this.patchExistingDomain(result);
        }
        this.afterSave.emit(result);
      });
    }
  }

  cancel(): void {
    if (!this.customCancel) {
      this.goToSearchComponent();
    } else {
      this.cancelTriggered.emit();
    }
  }

  goToSearchComponent(): void {
    this.location.back();
  }
}
