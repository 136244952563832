import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { looksLikeDate } from '../../utils/date-util';
import { AsCoreFormControl } from './AsCoreFormControl';
import * as moment from 'moment';


export function requiredIfNotBrouillon(control: AsCoreFormControl): ValidationErrors {
  if (!control.brouillon) {
    // @ts-ignore
    return Validators.required(control);
  }
  return null;
}

export function email(control: AbstractControl): ValidationErrors {
  const value = control.value;
  if (value && !value.match('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')) {
    return {email: {value}};
  }
  return null;
}

export function noFuture(control: FormControl): ValidationErrors {
  const value = control.value;
  if (!isNil(value) && moment(value, 'YYYY-MM-DD', true).isValid()) {
    const dateValue = new Date(value);
    const now = new Date();
    const isoToday = new Date(new Date().getTime() - now.getTimezoneOffset() * 60000);
    if (dateValue > isoToday) {
      return {noFuture: {value}};
    }
  }
  return null;
}

export function noPast(control: FormControl): ValidationErrors {
  const value = control.value;
  if (!isNil(value) && moment(value, 'YYYY-MM-DD', true).isValid()) {
    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (dateValue < now) {
      return {noPast: {value}};
    }
  }
  return null;
}

export function positive(control: FormControl): ValidationErrors {
  const value = control.value;
  if (value < 0) {
    return {positive: {value}};
  }
  return null;
}

export function minDate(min: Date): ValidatorFn {

  return (control: FormControl) => {
    const value = control.value;
    if (!isNil(value) && looksLikeDate(value)) {
      const dateValue = new Date(value);
      if (dateValue < new Date(min)) {
        return {minDate: {minDate: min}};
      }
    }
    return null;
  };
}

export function maxDate(max: Date): ValidatorFn {

  return (control: FormControl) => {
    const value = control.value;
    if (!isNil(value) && looksLikeDate(value)) {
      const dateValue = new Date(value);
      if (dateValue > new Date(max)) {
        return {maxDate: {maxDate: max}};
      }
    }
    return null;
  };
}

export function range(param: RangeParam): ValidatorFn {
  return (control: FormControl) => {
    const value = control.value;
    if (!isNil(value) && (value < param.min || value > param.max)) {
      return {range: {min: param.min, max: param.max}};
    }
    return null;
  };
}

export function requiredIfEmpty(property: string): ValidatorFn {
  return (control: FormControl) => {
    const group = control.parent;
    const value = control.value;

    const linkedControl = group.get(property);

    if (control.dirty && !linkedControl.value && !value) {
      // Pour éviter les boucles, ajout d'une propriété à la volée pour ne pas complexifier plus AsCoreControl
      (control as any).validationCroiseeEnCours = true;
      if (linkedControl.valid && !(linkedControl as any).validationCroiseeEnCours) {
        linkedControl.markAsDirty();
        linkedControl.updateValueAndValidity();
      }
      return {requiredIfEmpty: {requiredIfEmpty: property.toUpperCase()}};
    }

    (control as any).validationCroiseeEnCours = false;

    if (value && linkedControl.invalid) {
      linkedControl.updateValueAndValidity();
    }

    return null;
  };
}

export interface RangeParam {
  min: number;
  max: number;
}
