/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EntiteGen { 
    code: string;
    created: Date;
    createdBy: string;
    dtype?: string;
    holding?: boolean;
    id?: number;
    instanceLabel: string;
    libelle: string;
    logo?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class EntiteGen_ {
  static readonly code = 'code';
  readonly code = 'code';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly holding = 'holding';
  readonly holding = 'holding';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly libelle = 'libelle';
  readonly libelle = 'libelle';
  static readonly logo = 'logo';
  readonly logo = 'logo';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new EntiteGen_();
  }
}

