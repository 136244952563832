/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SortObjectGen { 
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
}

export class SortObjectGen_ {
  static readonly empty = 'empty';
  readonly empty = 'empty';
  static readonly sorted = 'sorted';
  readonly sorted = 'sorted';
  static readonly unsorted = 'unsorted';
  readonly unsorted = 'unsorted';
  static fields() {
    return new SortObjectGen_();
  }
}

