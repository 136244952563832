import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { faArrowUp, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fill, isEmpty, isNil } from 'lodash';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';
import { isBlank } from '../../utils/string-util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PagedRessource } from '../../service/paged-ressources';
import { PageableGen } from '../../../generated';
import { WithSearch } from '../../service/api/with-search';
import { IdInstanceLabel } from '../../models/id-instance-label';
import { environment } from '../../../../../environments/environment';
import { WithFilterSearchHelper } from '../../service/api/with-filter-search.helper';
import { Filter, filterDate, filterEntityEquals, filterText } from '../ascore-search/filter';
import { looksLikeDate, looksLikeDateTime } from '../../utils/date-util';
import { extractSearchParamsForRouter } from '../../utils/url-util';

@UntilDestroy()
@Component({
  selector: 'ascore-select',
  templateUrl: './ascore-select.component.html',
  styleUrls: ['./ascore-select.component.scss']
})
export class AsCoreSelectComponent implements OnInit, OnChanges, OnDestroy {

  private inputSelect: ElementRef;

  @ViewChild('inputSingleSelect', {static: false}) set inputSingle(content: ElementRef) {
    if (content) {
      this.inputSelect = content;
      this.listenOnInputChanges();
    }
  }

  @ViewChild('inputMultiSelect', {static: false}) set inputMulti(content: ElementRef) {
    if (content) {
      this.inputSelect = content;
      this.inputSelect.nativeElement.focus();
      this.listenOnInputChanges();
    }
  }

  iconCaretDown = faCaretDown;
  iconTimes = faTimes;
  faArrowUp = faArrowUp;

  @Input()
  label: string = null;

  /**
   * Deux type de recherche différents
   */
  @Input()
  withSearch: WithSearch<any, PagedRessource<any>>;

  /**
   * Deux type de recherche différents
   */
  @Input()
  withFilterSearchHelper: WithFilterSearchHelper;

  @Input()
  searchFieldName = 'recherche';

  @Input()
  sortFieldName = 'instanceLabel';

  /**
   * Force la propriété affichée dans le select
   */
  @Input()
  showFieldName: string = null;

  @Input()
  form: FormGroup;

  @Input()
  formControlName_: string;

  // Elements à exclure de la liste proposée à l'utilisateur
  @Input()
  listExclusion: IdInstanceLabel[] = [];

  @Input()
  placeHolder = '';

  @Input()
  cleanOnSelect = false;

  @Input()
  minChar = 1;

  @Input()
  dependsOn: DependsOn[] = null;

  @Input()
  readOnly = false;

  @Input()
  uppercase = false;

  @Input()
  pageSize = 200;

  @Input()
  inline = false;

  @Input()
  multiSelect = false;

  // En px
  @Input()
  labelWidth;

  @Input()
  required = false;

  @Input()
  showDiffStyle = false;

  @Input()
  resultTemplate;

  // Utilisé uniquement pour le readonly pour afficher une propriété custom d'un objet complexe.
  @Input()
  propertyToDisplay;

  @Output()
  selectEvent = new EventEmitter();

  @Output()
  keyEnterEvent = new EventEmitter();

  @Input() containerDivCustomClass = '';

  /** Affiche la description du champ à saisir dans le champ quand il est vide, sinon au-dessus */
  @Input()
  asMaterial = false;

  @Input()
  autoLoadOnInit = false;

  @Input()
  autoSelectIfOneResult = false;

  @Input()
  autoSelectDefault: (entity: IdInstanceLabel) => boolean = null;

  // Est-ce que l'utilisateur est dans un processus de recherche-selection
  rechercheEnCours = false;

  currentSearch = '';

  subscriptionOnDependsOn: Subscription[] = null;
  lastValueDependsOn: any[];

  lastMultiselected: IdInstanceLabel;

  content$: Subject<PagedRessource<any>> = new Subject<PagedRessource<any>>();

  formatData = (entity: IdInstanceLabel) => {

    if (isNil(entity)) {
      return '';
    }
    return this.showFieldName ? entity[this.showFieldName] : entity.instanceLabel;
  }

  formatDataCode = (entity: IdInstanceLabel | string) => {
    if (entity && this.showFieldName && entity.hasOwnProperty(this.showFieldName) && !isNil(entity[this.showFieldName])) {
      return entity[this.showFieldName];
    } else { // @ts-ignore
      if (entity && entity.hasOwnProperty('code') && !isNil(entity.code)) {
        // @ts-ignore
        return entity.code;
      } else if (typeof entity === 'string') {
        return entity;
      } else {
        return this.formatData(entity);
      }
    }
  }

  constructor(private renderer: Renderer2, private fb: FormBuilder) {
    // FormGroup par defaut
    this.form = this.fb.group({
      select: AsCoreFormControl.init().noLabel()
    });
    this.formControlName_ = 'select';
  }

  ngOnInit(): void {
    if (this.autoLoadOnInit || this.autoSelectIfOneResult || this.autoSelectDefault !== null) {
      this.doSearch();
      this.content$.pipe(first()).subscribe(result => {
        if (this.autoSelectIfOneResult && !this.form.get(this.formControlName_).value && result.content.length === 1) {
          this.form.get(this.formControlName_).setValue(result.content[0]);
        } else if (this.autoSelectDefault !== null && !this.form.get(this.formControlName_).value) {
          this.form.get(this.formControlName_).setValue(result.content.find(it => this.autoSelectDefault(it)));
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.dependsOn) {
      return;
    }
    // Première init des tableaux de souscriptions et valeurs
    if (isNil(this.subscriptionOnDependsOn)) {
      this.subscriptionOnDependsOn = fill(Array(this.dependsOn.length), null);
    }
    if (isNil(this.lastValueDependsOn)) {
      this.lastValueDependsOn = fill(Array(this.dependsOn.length), undefined);
    }

    this.dependsOn.forEach((dependsOn: DependsOn, index) => {
      if (isNil(this.subscriptionOnDependsOn[index])) {
        // Passer lastValueDependsOn à undefined plutôt qu'à null lors de la 1ère initialisation,
        // cela permet de ne pas vider les listes dépendantes lors du patchValue du formulaire.
        let lastValue;
        if (this.dependsOn[index].fixedValue !== undefined) {
          lastValue = this.dependsOn[index].fixedValue;
        } else if (!isNil(this.dependsOn[index].field)) {
          lastValue = this.form.get(this.dependsOn[index].field)?.value ?? undefined;
        }
        this.lastValueDependsOn[index] = lastValue;

        if (this.dependsOn[index].fixedValue !== undefined) { // On initialise une souscription vide
          this.subscriptionOnDependsOn[index] = new Subscription();
        } else if (!isNil(this.dependsOn[index].field)) { // On souscrit au field
          this.subscriptionOnDependsOn[index] = this.form.get(this.dependsOn[index].field).valueChanges.subscribe((newValue) => {
            if (this.lastValueDependsOn[index]?.id !== newValue?.id && this.lastValueDependsOn[index] !== undefined) {
              if (newValue) {
                // Si la derniere valeur séléctionnée est toujours présente, on la conserve, sinon on vide le champs
                this.searchForLastValue();
              } else {
                // le depends on est vide : on vide le champ
                this.clear(null);
              }
            }
            this.lastValueDependsOn[index] = newValue ?? undefined;
          });
        }
      }
    });
  }

  listenOnInputChanges(): void {

    if (this.inputSelect) {
      fromEvent(this.inputSelect.nativeElement, 'input')
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(environment.defaultDebounceTime))
        .pipe(distinctUntilChanged())
        .pipe(untilDestroyed(this))
        .subscribe(data => this.onSearchChange(data));
    }
  }

  onSearchChange(search: string): void {

    this.currentSearch = search;

    if (search === '' && this.form && this.getValue() != null && !this.multiSelect) {
      this.clear(null);
    }

    this.doSearch();
  }

  /**
   * @param text$ un observable de textes tapés
   * @return un observable de tableau d'éléments à afficher dans la popup "typeahead"
   */
  search = (text$: Observable<string>) => {
    return this.content$.pipe(map(it => {
        // return this.service?.getRessource().map(it => {
        // On calcule le résultat avant pour avoir le minimum d'opérations entre l'envoi de valeur dans l'observable
        // et l'ajout d'infos dans la fenêtre de recherche

      const exclusion = this.listExclusion;
        // On ne veut pas afficher les resultats deja selectionnés
        if (this.multiSelect) {
          exclusion.concat(this.getValue());
        }
        const listExclutionNotNull = exclusion ? exclusion : [];
        const content = Array.isArray(it) ? it : it.content;
        const result = content.filter(obj => listExclutionNotNull.map(exclusion => exclusion.id).indexOf(obj?.id) < 0);
        if (this.inputSelect) {
          const dropDown = this.inputSelect.nativeElement;
          setTimeout(() => { // On rend la main au scheduler pour que l'observable soit traité et que la fenêtre s'affiche
            const typeaheadWindow = dropDown?.parentElement?.parentElement?.parentElement?.querySelector('ngb-typeahead-window');
            if (typeaheadWindow) {
              let countDisplayText = '';

              if (it instanceof PagedRessource) {
                if (it.first && it.last) {
                  countDisplayText = '#' + result.length;
                } else if (it.pageable) {
                  countDisplayText = it.size + '/' + it.totalElements;
                }
              }

              if (countDisplayText === '') {
                countDisplayText = '#' + result.length;
              }

              let countDisplayElement = typeaheadWindow.querySelector('.count-display-element');
              if (!countDisplayElement) { // Si pas affiché précédemment, on le crée
                countDisplayElement = this.renderer.createElement('div');
                this.renderer.addClass(countDisplayElement, 'count-display-element');
                this.renderer.appendChild(typeaheadWindow, countDisplayElement);
              }
              this.renderer.setProperty(countDisplayElement, 'innerHTML', countDisplayText);
            }
          }, 0);
        }
        // On en veut pas afficher les resultats deja selectionnés
        return result;
      }
    ));
  }

  clickOnCaret(event: Event): void {

    if (this.isDependsOnDisabled()) {
      return;
    }

    event.stopPropagation();
    if (this.rechercheEnCours) {
      this.onFocusOut();
    } else {
      this.onFocus(null);
    }
  }

  onFocus(event: MouseEvent): void {
    if (this.multiSelect) {
      event?.stopPropagation();
    }
    if (!this.isDisabled()) {
      this.rechercheEnCours = true;
      this.doSearch();
    }
  }

  onFocusOut(): void {

    if (!this.multiSelect) {
      if (this.rechercheEnCours
        && this.formatData(this.getValue()) !== this.inputSelect?.nativeElement.value) {
        this.clear(null);
      }
    }

    this.currentSearch = '';
    this.rechercheEnCours = false;
  }

  onSelect(event): void {
    this.selectEvent.emit(event);
    this.rechercheEnCours = false;
    if (this.cleanOnSelect && this.getValue() != null) {
      this.clear(null);
    }
  }

  doSearch(): void {
    if (this.currentSearch.length >= this.minChar) {
      const searchForm = {};
      // Récupère la valeur du control de recherche
      searchForm[this.searchFieldName] = this.currentSearch;

      this.applyDependsOn(searchForm);

      const pageable: PageableGen = {
        page: 0,
        size: this.pageSize,
        sort: this.sortFieldName ? [this.sortFieldName] : []
      };
      this.callSearch(searchForm, pageable)
        .subscribe(value => this.content$.next(value));
    }
  }

  searchForLastValue(): void {

    const lastValue = this.getValue();

    if (!lastValue) {
      return;
    }

    const searchForm = {};
    // Récupère la valeur du control de recherche
    searchForm[this.searchFieldName] = lastValue[this.searchFieldName];

    this.applyDependsOn(searchForm);

    const pageable: PageableGen = {
      page: 0,
      size: this.pageSize,
      sort: this.sortFieldName ? [this.sortFieldName] : []
    };

    this.callSearch(searchForm, pageable)
      .subscribe((results: PagedRessource<any>) => {
        if (results && results.content && results.content.filter(it => it.id === lastValue.id).length > 0) {
          // Valeur toujours présente dans la liste : on la conserve
        } else {
          // Valeur plus présente dans la liste, on clear
          this.clear(null);
        }
      });
  }

  private callSearch(searchForm: {}, pageable: PageableGen): Observable<PagedRessource<any>> {

    const searchParam = extractSearchParamsForRouter(searchForm);

    if (this.withSearch) {
      return this.withSearch.search(searchParam, pageable);
    } else if (this.withFilterSearchHelper) {
      if (!isNil(this.withFilterSearchHelper.filterBuilder)) {
        // Si le filterSearch définit un filterBuilder, on l'utilise.
        return this.withFilterSearchHelper.callSearch(pageable);
      } else {
        // Sinon, on utilise le buildFilter par défaut.
        return this.withFilterSearchHelper.callSearchWithFilter(this.buildFilter(searchParam), pageable);
      }
    }
  }

  buildFilter(searchForm: {}): Filter {
    return AsCoreSelectComponent.buildFilter(searchForm, this.searchFieldName);
  }

  public static buildFilter(searchForm: {}, searchFieldName: string): Filter {
    let result = filterText(searchForm[searchFieldName], searchFieldName, 'contains');
    Object.entries(searchForm).forEach(entry => {
        const key = entry[0];

        if (key !== searchFieldName) {
          const value: any = entry[1];
          if (value instanceof IdInstanceLabel) {
            result = result.and(filterEntityEquals(value, key));
          } else if (looksLikeDateTime(value) || looksLikeDate(value)) {
            result = result.and(filterDate(value, key, 'eq'));
          } else {
            result = result.and(filterText(value.toString(), key, 'eq'));
          }
        }
      }
    );
    return result;
  }

  private applyDependsOn(searchForm): void {
    if (!isNil(this.dependsOn)) {
      this.dependsOn.forEach(dependsOn => {
        const searchParam = dependsOn.searchParam ? dependsOn.searchParam : dependsOn.field;
        searchForm[searchParam] = dependsOn.fixedValue !== undefined ? dependsOn.fixedValue : this.form.get(dependsOn.field).value;
      });
    }
  }

  optionsVisible(): boolean {
    return this.rechercheEnCours && this.currentSearch.length >= this.minChar;
  }

  isDisabled(): boolean {
    return this.getControl().disabled || !isNil(this.inputSelect?.nativeElement.getAttribute('disabled'));
  }

  isReadOnly(): boolean {
    return this.readOnly || !isNil(this.inputSelect?.nativeElement.getAttribute('readonly'));
  }

  isDependsOnDisabled(): boolean {
    if (isEmpty(this.dependsOn)) {
      return false;
    }

    // allDependsOnValueSet = true si tous les champs dont dépend cette combo sont remplis ou bien ont une fixedValue renseignée
    const allDependsOnValueSet = this.dependsOn.map(dependsOn => {
      if (!isNil(dependsOn.fixedValue)) {
        return true;
      } else if (dependsOn.field) {
        return this.form.get(dependsOn.field)?.value !== null;
      }
      return false;
    });

    return !allDependsOnValueSet.every(_ => _);
  }

  clear(event: MouseEvent): void {

    if (event) {
      event.stopPropagation();
    }

    // On ne modifie pas la valeur si elle était déjà vide, cela évite l'encadré rouge d'erreur
    if (this.getValue() !== null) {
      this.getControl().setValue(null);
      this.getControl().markAsDirty();

      // Pour le multiselect, le fait de mettre à jour la valeur ne va pas déclencher le ngModelChanges et appeler onMultiSelect
      // On émet donc directement l'évènement de sélection pour déclencher une recherche.
      if (this.multiSelect) {
        this.selectEvent.emit(null);
      }
    }
  }

  getControl(): AsCoreFormControl {
    // @ts-ignore
    return this.form.get(this.formControlName_) as AsCoreFormControl;
  }

  ngOnDestroy(): void {
    this.subscriptionOnDependsOn?.forEach(subscription => subscription?.unsubscribe());
  }

  onMultiSelect(item): void {
    if (!this.getValue()) {
      this.getControl().setValue([]);
    }

    if (this.getValue().map(it => it.id ? it.id : it.code).indexOf(item.id ? item.id : item.code) < 0) {
      this.getValue().push(item);
    }

    this.inputSelect.nativeElement.value = this.currentSearch;
    this.selectEvent.emit(item);
    this.doSearch();
  }

  removeItemMultiSelect(event: MouseEvent, index: number, item: IdInstanceLabel): void {
    event.stopPropagation();
    this.getValue().splice(index, 1);
    this.selectEvent.emit(item);
  }

  isSelectedValue(): boolean {

    if (isNil(this.getValue())) {
      return false;
    }

    if (this.multiSelect && this.getValue().length === 0) {
      return false;
    }

    return true;
  }

  getValue(): any {
    return this.form?.get(this.formControlName_)?.value;
  }

  getWidth(): string {
    return this.inline && this.labelWidth ? 'width:' + this.labelWidth + 'px' : '';
  }

  getLabel(): string {
    return !isBlank(this.label) ? this.label : this.getControl().getLabel();
  }

  isSearchDisabled(): Boolean {
    return isNil(this.withSearch) && isNil(this.withFilterSearchHelper);
  }
}

export interface DependsOn {

  /**
   * Le paramètre de recherche pour la requête vers le back
   */
  searchParam?: string;
  /**
   * Le nom du field si un champ de formulaire est cliblé
   */
  field?: string;
  /**
   * Une valeur fixe pour la recherche si spécifié
   */
  fixedValue?: any;
}
