/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoleGen = 'ROLE_CONSULTATION' | 'ROLE_GESTIONNAIRE' | 'ROLE_ADMIN';

export const RoleGen = {
        CONSULTATION: 'ROLE_CONSULTATION' as RoleGen,
        GESTIONNAIRE: 'ROLE_GESTIONNAIRE' as RoleGen,
        ADMIN: 'ROLE_ADMIN' as RoleGen
        };

export class RoleGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new RoleGen_();
}
}

