import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faRedoAlt,
  faReply,
  faSave,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';
import { AsCoreColumn } from '../ascore-search/ascore-search.component';
import { isNil } from 'lodash';
import { capitalize, isBlank } from '../../utils/string-util';

@Component({
  selector: 'ascore-confirm-modal',
  templateUrl: './ascore-conflict-modal.component.html',
  styleUrls: ['./ascore-conflict-modal.component.scss']
})
export class AsCoreConflictModalComponent implements AfterContentInit {
  @ViewChild('propertyTpl', {static: true}) propertyTpl: ElementRef;
  @ViewChild('versionTpl', {static: true}) versionTpl: ElementRef;
  @ViewChild('resultTpl', {static: true}) resultTpl: ElementRef;

  faTimes = faTimes;
  faExclamationCircle = faExclamationCircle;
  faExclamationTriangle = faExclamationTriangle;
  faCheckCircle = faCheckCircle;
  faSave = faSave;
  faReply = faReply;
  faRedoAlt = faRedoAlt;

  @Input()
  updatedVersion: any;

  @Input()
  localVersion: any;

  @Input()
  mapPropertyToLibelle: any;

  // Simplement retournée à la fermeture de la modal.
  @Input()
  request: any;

  form: FormGroup;

  listProperties = [];
  columns: AsCoreColumn[] = [];
  content = [];
  private technicalProperties = ['new', 'dtype', 'versionNum'];

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {
    this.form = this.fb.group({});

  }

  ngAfterContentInit(): void {

    this.listProperties = [...new Set(Object.keys(this.updatedVersion).concat(Object.keys(this.localVersion)))]
      .filter(property => !this.technicalProperties.some(p => p === property) && !isNil(this.mapPropertyToLibelle[property]));

    this.listProperties.forEach(property => {
      this.form.addControl(property + 'Local', AsCoreFormControl.init(false).noLabel());
      this.form.addControl(property + 'Updated', AsCoreFormControl.init(true).noLabel());
      this.form.addControl(property + 'LocalValue', AsCoreFormControl.init(this.localVersion[property]).noLabel());
      this.form.addControl(property + 'UpdatedValue', AsCoreFormControl.init(this.updatedVersion[property]).noLabel());
      this.form.addControl(property + 'Result', AsCoreFormControl.init(this.updatedVersion[property]).noLabel());

      this.form.get(property + 'Local').valueChanges.subscribe(change => {
        if (change) {
          this.form.get(property + 'Updated').setValue(false);
          this.form.get(property + 'Result').setValue(this.localVersion[property]);
        } else {
          this.form.get(property + 'Result').setValue(null);
        }
      });

      this.form.get(property + 'Updated').valueChanges.subscribe(change => {
        if (change) {
          this.form.get(property + 'Local').setValue(false);
          this.form.get(property + 'Result').setValue(this.updatedVersion[property]);
        } else {
          this.form.get(property + 'Result').setValue(null);
        }
      });

      this.content.push({
        property: property,
        local: property + 'Local',
        updated: property + 'Updated',
        result: property + 'Result'
      })
    })

    this.columns = [
      {header: 'Champ', fieldName: 'property', template: this.propertyTpl},
      {header: 'Votre version', fieldName: 'local', template: this.versionTpl},
      {header: 'La version enregistrée', fieldName: 'updated', template: this.versionTpl},
      {header: 'Résultat', fieldName: 'property', template: this.resultTpl}
    ];
  }

  isArray(s: string): any {
    return this.form.get(s)?.value instanceof Array;
  }

  getArrayAsString(s: string): any {
    let result = this.form.get(s)?.value?.map(p => p.instanceLabel).join(',');

    if (isNil(result) || isBlank(result)) {
      return '-';
    }

    if (result.length < 50) {
      return result + ` (${this.form.get(s)?.value.length})`;
    } else {
      return result.substring(0, 47) + '...' + ` (${this.form.get(s)?.value.length})`;
    }
  }

  sameValue(property: any): Boolean {
    if (property.endsWith('Local')) {
      property = property.substring(0, property.length - ('Local').length)
    } else if (property.endsWith('Updated')) {
      property = property.substring(0, property.length - ('Updated').length)
    }

    return (isNil(this.form.get(property + 'LocalValue')?.value) && isNil(this.form.get(property + 'UpdatedValue')?.value)) ||
      (isNil(this.form.get(property + 'LocalValue')?.value) && !this.form.get(property + 'UpdatedValue')?.value?.length) ||
      (!this.form.get(property + 'UpdatedValue')?.value?.length && isNil(this.form.get(property + 'UpdatedValue')?.value)) ||
      this.form.get(property + 'LocalValue')?.value === this.form.get(property + 'UpdatedValue')?.value ||
      (!isNil(this.form.get(property + 'LocalValue')?.value?.id) && this.form.get(property + 'LocalValue')?.value?.id === this.form.get(property + 'UpdatedValue')?.value?.id) ||
      (this.isArray(property + 'LocalValue') && this.sameArrayContent(this.form.get(property + 'LocalValue')?.value, this.form.get(property + 'UpdatedValue')?.value));
  }

  private sameArrayContent(array1: any[], array2: any[]): Boolean {
    return array1.length === array2.length && !array1.some(element1 => !array2.some(element2 => element2?.id === element1.id));
  }

  noVersionSelected(property: any): any {
    return !this.form.get(property + 'Local')?.value && !this.form.get(property + 'Updated')?.value;
  }

  everyPropertyHasVersionSelected(): Boolean {
    return !this.listProperties.some(property => this.noVersionSelected(property));
  }

  getResult(): any {
    const result = this.fb.group({
      id: AsCoreFormControl.init(this.updatedVersion?.id),
      versionNum: AsCoreFormControl.init(this.updatedVersion?.versionNum),
      created: AsCoreFormControl.init(this.updatedVersion?.created),
      createdBy: AsCoreFormControl.init(this.updatedVersion?.createdBy)
    });
    this.listProperties.forEach(property => {
      result.addControl(property, AsCoreFormControl.init(this.form.get(property + 'Result')?.value));
    })
    return {form: result.getRawValue(), request: this.request};
  }

  getLibelleFromProperty(value: any): any {
    const libelle = this.mapPropertyToLibelle[value];
    return capitalize(!isBlank(libelle) ? libelle : value);
  }
}
