import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AsCoreConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActionClientServiceGen } from './api/action-client.gen.service';
import { ActionObjetServiceGen } from './api/action-objet.gen.service';
import { ActionTypeServiceGen } from './api/action-type.gen.service';
import { AllEnumServiceGen } from './api/all-enum.gen.service';
import { AppConfigServiceGen } from './api/app-config.gen.service';
import { AuditServiceGen } from './api/audit.gen.service';
import { AuditAuthorServiceGen } from './api/audit-author.gen.service';
import { ClientServiceGen } from './api/client.gen.service';
import { ClientFamilleServiceGen } from './api/client-famille.gen.service';
import { ClientSousFamilleServiceGen } from './api/client-sous-famille.gen.service';
import { ClientStatistiqueTypeServiceGen } from './api/client-statistique-type.gen.service';
import { ContactServiceGen } from './api/contact.gen.service';
import { ContactFonctionServiceGen } from './api/contact-fonction.gen.service';
import { DevisServiceGen } from './api/devis.gen.service';
import { DevisStatutServiceGen } from './api/devis-statut.gen.service';
import { DocumentServiceGen } from './api/document.gen.service';
import { DureeTypeServiceGen } from './api/duree-type.gen.service';
import { EntiteServiceGen } from './api/entite.gen.service';
import { ExternalServiceGen } from './api/external.gen.service';
import { FactureServiceGen } from './api/facture.gen.service';
import { OuiNonServiceGen } from './api/oui-non.gen.service';
import { PasswordServiceGen } from './api/password.gen.service';
import { RappelAutomatiqueServiceGen } from './api/rappel-automatique.gen.service';
import { RappelAutomatiqueTypeServiceGen } from './api/rappel-automatique-type.gen.service';
import { RechercheRapideServiceGen } from './api/recherche-rapide.gen.service';
import { RoleServiceGen } from './api/role.gen.service';
import { StatistiqueServiceGen } from './api/statistique.gen.service';
import { TagServiceGen } from './api/tag.gen.service';
import { TagTypeServiceGen } from './api/tag-type.gen.service';
import { UtilisateurServiceGen } from './api/utilisateur.gen.service';
import { UtilisateurFonctionServiceGen } from './api/utilisateur-fonction.gen.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class AsCoreApiModule {
    public static forRoot(configurationFactory: () => AsCoreConfiguration): ModuleWithProviders<AsCoreApiModule> {
        return {
            ngModule: AsCoreApiModule,
            providers: [ { provide: AsCoreConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AsCoreApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AsCoreApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
