import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AsCoreTab } from '../ascore-tab/ascore-tab.model';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';
import { RechercheRapideGen_, RechercheRapideServiceGen } from '../../../generated';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ComponentCanDeactivate } from '../../../guards/pending-changes.guard';
import { Observable } from 'rxjs';
import { UtilisateurCourantService } from '../../../../administration/referentiel/utilisateur-courant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AsCoreSelectComponent } from '../ascore-select/ascore-select.component';
import { AsCoreColumn } from '../ascore-table/ascore-table.model';

@Component({
    selector: 'app-recherche-rapide-create-detail',
    templateUrl: './recherche-rapide-create-detail.component.html',
    styleUrls: ['./recherche-rapide-create-detail.component.scss']
})
export class RechercheRapideCreateDetailComponent implements ComponentCanDeactivate, OnInit {

    faTimes = faTimes;

    public form: FormGroup;

    tabs: AsCoreTab[] = [];

    @Input()
    libelleParDefaut: string;

    @Input()
    rechercheType: string;

    @Input()
    sort: string;

    @Input()
    size: string;

    @Input()
    page: string;

    @Input()
    listColumn: string[] = [];

    @Input()
    allColumns: AsCoreColumn[] = [];

    fields = RechercheRapideGen_.fields();

    @ViewChild('asCoreSelectComponent', { static: true }) asCoreSelectComponent: AsCoreSelectComponent;

    constructor(private fb: FormBuilder,
                public service: RechercheRapideServiceGen,
                private utilisateurCourantService: UtilisateurCourantService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    canDeactivate: () => boolean | Observable<boolean> = () => {
        return this.form.pristine;
    };

    ngOnInit(): void {
        this.createForm();
        this.asCoreSelectComponent.content$.next({
            content: this.listColumn
        })

    }

    createForm(): void {
        this.form = this.fb.group({
            [RechercheRapideGen_.libelle]: AsCoreFormControl.init(this.libelleParDefaut).required().label('Libellé'),
            [RechercheRapideGen_.parDefaut]: AsCoreFormControl.init(false).label('Recherche par défaut'),
            [RechercheRapideGen_.rechercheType]: AsCoreFormControl.init(this.rechercheType).required().noLabel(),
            [RechercheRapideGen_.url]: AsCoreFormControl.init(this.router.url + this.getPagination()).required().noLabel(),
            [RechercheRapideGen_.listColumn]: AsCoreFormControl.init(this.listColumn).required().noLabel(),
            // Nécessaire d'avoir ce control front uniquement pour afficher le libellé
            ['displayListColumn']: AsCoreFormControl.init(this.allColumns.map(col => ({
                fieldName: col.fieldName,
                header: col.header
            }))).required().label('Choisir les colonnes')
        });
    }

    private getPagination(): string {
        return (!(Object.keys(this.route.snapshot?.queryParams).length) ? '?' : '') +
            (this.sort ? ('&sort=' + this.sort) : '') +
            (this.size ? ('&size=' + this.size) : '') +
            (this.page ? ('&page=' + this.page) : '');
    }

    listColumnChanged(): void {
        this.form.get(RechercheRapideGen_.listColumn).setValue(this.form.get('displayListColumn').value.map(column => column.fieldName));
    }
}
