/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RappelAutomatiqueTypeGen = 'RDV' | 'APPEL' | 'RELANCE' | 'MAIL' | 'ECHANGE';

export const RappelAutomatiqueTypeGen = {
        RDV: 'RDV' as RappelAutomatiqueTypeGen,
        APPEL: 'APPEL' as RappelAutomatiqueTypeGen,
        RELANCE: 'RELANCE' as RappelAutomatiqueTypeGen,
        MAIL: 'MAIL' as RappelAutomatiqueTypeGen,
        ECHANGE: 'ECHANGE' as RappelAutomatiqueTypeGen
        };

export class RappelAutomatiqueTypeGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new RappelAutomatiqueTypeGen_();
}
}

