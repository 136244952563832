import { Directive, ElementRef, Renderer2 } from '@angular/core';


@Directive({
  selector: 'input[asCoreOnlyDigits]'
})
export class OnlyDigitsDirective {

  constructor(public el: ElementRef, private renderer: Renderer2) {
    this.el.nativeElement.onkeypress = (evt) => {
      if (isNaN(evt.key * 1)) {
        evt.preventDefault();
      }
    };
    this.renderer.listen(el.nativeElement, 'paste', (event) => {
      const value = event.clipboardData.getData('text');
      if (isNaN(value * 1)) {
        event.preventDefault();
      }
    });
  }
}
