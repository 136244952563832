import { debounce } from 'lodash';

/**
 * Debounce a method
 */
export function Debounce(this: any, ms): any {
  // tslint:disable-next-line:only-arrow-functions
  return function(target: any, key: any, descriptor: any): any {
    const oldFunc = descriptor.value;
    const newFunc = debounce(oldFunc, ms);
    descriptor.value = function(): any {
      return newFunc.apply(this, arguments);
    };
  };
}
