import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    AbstractCompositionDetailComponent
} from "../../../../shared/ascore/component/composition/abstract-composition-detail.component";
import { MessageService } from "../../../../shared/ascore/service/message.service";
import { AsCoreFormControl } from "../../../../shared/ascore/component/ascore-input/AsCoreFormControl";
import { DocumentGen, DocumentServiceGen } from "../../../../shared/generated";
import { TabUpdateResult } from "../../../../shared/ascore/component/ascore-tab/ascore-tab.component";

@Component({
    selector: 'app-document-detail',
    templateUrl: './document-detail.component.html',
    styleUrls: ['./document-detail.component.scss'],
})
export class DocumentDetailComponent extends AbstractCompositionDetailComponent<DocumentGen> {

    constructor(private fb: FormBuilder,
                private documentJointService: DocumentServiceGen,
                public modal: NgbActiveModal,
                private messageService: MessageService) {
        super();
    }

    createForm(): void {
        this.form = this.fb.group({
            id: AsCoreFormControl.init(),
            typeDocument: AsCoreFormControl.init().required(),
            situation: AsCoreFormControl.init(this.parent),
            statutDocument: AsCoreFormControl.init(),
            commentaire: AsCoreFormControl.init()
        });
    }

    updateDocumentJoint() {
        const domain = this.form.getRawValue();
        this.documentJointService.update(domain.id, domain).subscribe(documentJointUpdated => {
            this.messageService.showSuccess(`Document mis à jour`);
            this.modal.close(new TabUpdateResult(documentJointUpdated, false));
        });
    }
}
