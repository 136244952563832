/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditSearchDtoGen { 
    author?: string;
    dateMax?: Date;
    dateMin?: Date;
}

export class AuditSearchDtoGen_ {
  static readonly author = 'author';
  readonly author = 'author';
  static readonly dateMax = 'dateMax';
  readonly dateMax = 'dateMax';
  static readonly dateMin = 'dateMin';
  readonly dateMin = 'dateMin';
  static fields() {
    return new AuditSearchDtoGen_();
  }
}

