import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsCoreColumn } from '../ascore-search/ascore-search.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuditAuthorServiceGen, AuditCommitGen, AuditServiceGen } from '../../../generated';
import { Router } from '@angular/router';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';

@Component({
  selector: 'ascore-audit-search',
  templateUrl: './ascore-audit-search.component.html',
  styleUrls: ['./ascore-audit-search.component.scss']
})
export class AsCoreAuditSearchComponent implements OnInit {

  columns: AsCoreColumn[];
  content: AuditCommitGen[];

  @ViewChild('listAuditChangeTemplateWithEntityDetail', {static: true}) listAuditChangeTemplateWithEntityDetail: ElementRef;

  searchForm: FormGroup;

  constructor(public auditCommitService: AuditServiceGen,
              public auditAuthorService: AuditAuthorServiceGen,
              private router: Router,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.searchForm = this.fb.group({
      author: AsCoreFormControl.init().label('Auteur'),
      dateMin: AsCoreFormControl.init().label('A partir du').noFuture(),
      dateMax: AsCoreFormControl.init().label('Jusqu\'au').noFuture()
    });
  }

  private getColumns() {
    return [
      {header: 'Auteur', fieldName: 'author'},
      {header: 'Date/heure', fieldName: 'dateTime'},
      {
        header: 'Changements',
        fieldName: 'listAuditChange',
        template: this.listAuditChangeTemplateWithEntityDetail
      }
    ];
  }
}
