import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'ascore-radio-btn-container',
  templateUrl: './ascore-radio-btn-container.component.html',
  styleUrls: ['./ascore-radio-btn-container.component.scss']
})
export class AsCoreRadioBtnContainerComponent {

  @Input()
  options: AsCoreContainerRadioOption[] = [];

  @Output()
  valueChanges = new EventEmitter<any>();

  value: any;

  constructor() {
  }

  select(value: any): void {
    if (value !== this.value) {
      this.value = value;
      this.valueChanges.emit(value);
    }
  }

  isSelected(option: AsCoreContainerRadioOption): boolean {
    return option.value === this.value || (isNil(this.value) && option.isDefault);
  }
}

export interface AsCoreContainerRadioOption {
  label: string;
  value: any;
  template: any;
  isDefault: boolean;
}
