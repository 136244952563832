import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';

@Component({
  selector: 'ascore-montant-numeraire',
  templateUrl: './montant-numeraire.component.html',
  styleUrls: ['./montant-numeraire.component.scss']
})
export class MontantNumeraireComponent implements OnChanges {
  @ViewChild('inputElement', {static: true}) inputElement: ElementRef;

  form: FormGroup;

  @Input()
  montant: number;

  @Input()
  left = false;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      montant: AsCoreFormControl.init()
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.get('montant').setValue(this.montant);
  }
}
