/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentDtoGen { 
    actionClientId?: number;
    clientId?: number;
    commentaire?: string;
    contentType?: string;
    libelle?: string;
}

export class DocumentDtoGen_ {
  static readonly actionClientId = 'actionClientId';
  readonly actionClientId = 'actionClientId';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly contentType = 'contentType';
  readonly contentType = 'contentType';
  static readonly libelle = 'libelle';
  readonly libelle = 'libelle';
  static fields() {
    return new DocumentDtoGen_();
  }
}

