<ascore-search #utilisateurSearch
               [columns]="columns"
               [withSearch]="utilisateurService"
               [withDelete]="utilisateurService"
               [searchForm]="searchForm"
               [buttonAddVisible]="true"
               [customCreate]="true" (addEvent)="openCustomCreate($event)"
               typeDetail="editable"
               [title]="'Utilisateurs'">

    <div class="subSearchForm input-upper" [formGroup]="searchForm">
        <div class="col-12 p-0 d-inline-flex flex-wrap">
            <div class="col-md-4">
                <input type="text"
                       asCoreUppercase
                       [formControlName]="'recherche'"
                       [asMaterial]="true"
                >
            </div>
        </div>
    </div>

</ascore-search>
