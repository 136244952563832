import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NotFoundComponent } from './home/maintenance/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { MonCompteComponent } from './home/mon-compte/mon-compte.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'groupe',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'actions',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'parametrage',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./home/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'factures',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'devis',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'mon-compte',
        component: MonCompteComponent
      }
    ]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
