<p *ngIf="!inline && !_hide"
   class="control-error"
   [ngbTooltip]="getError()">
    {{getError()}}
</p>

<fa-icon *ngIf="inline && !_hide"
         class="control-error-inline"
         [ngbTooltip]="tipContent" tooltipClass="control-error-inline-tooltip" [icon]="faExclamationTriangle"></fa-icon>

<ng-template #tipContent>{{getError()}}</ng-template>
