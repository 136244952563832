import { Component, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { back } from '../../utils/url-util';
import { Router } from '@angular/router';

@Component({
  selector: 'ascore-container',
  templateUrl: './ascore-container.component.html',
  styleUrls: ['./ascore-container.component.scss']
})
export class AsCoreContainerComponent {

  faTimes = faTimes;

  @Input()
  title: string;

  @Input()
  header = true;

  @Input()
  status: string = null;

  @Input()
  closable = true;

  @Input()
  closeParams = '';

  constructor(private router: Router) {
  }

  close(): void {
    back(this.router, this.closeParams);
  }
}
