import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import localeFr from '@angular/common/locales/fr';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbDateParserFormatter, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import { AuthGuard } from './shared/guards/auth.guard';
import { AutorisationGuard } from './shared/guards/autorisation.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './shared/ascore/interceptor/http-interceptor.service';
import { AsCoreModule } from './shared/ascore/ascore.module';
import { NotFoundComponent } from './home/maintenance/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomDateParserFormatter } from './custom-date-parser.formatter';
import { MonCompteComponent } from './home/mon-compte/mon-compte.component';
import { AppConfigService } from './shared/ascore/app-config.service';
import { AsCoreConfiguration } from './shared/generated';
import { UtilisateurModule } from './administration/referentiel/utilisateur/utilisateur.module';
import { RefreshWebsocketService } from './shared/ascore/service/refresh-websocket.service';

import { PendingChangesGuard } from './shared/guards/pending-changes.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RxStompService } from "./rx-stomp.service";
import { rxStompServiceFactory } from "./rx-stomp-service-factory";

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        ConfigurationComponent,
        NotFoundComponent,
        MonCompteComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        AsCoreModule.forRoot(),
        HttpClientModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbNavModule,
        NgSelectModule,
        UtilisateurModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 10 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:10000'
        })
    ],
    providers: [
        NavigationItem,
        AuthGuard,
        AutorisationGuard,
        PendingChangesGuard,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        {
            provide: APP_INITIALIZER,
            useFactory: bootstrap,
            deps: [AppConfigService],
            multi: true
        },
        {
            provide: AsCoreConfiguration,
            useFactory: (_: any) => new AsCoreConfiguration(
                {
                    basePath: location.origin
                }
            ),
            multi: false
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
        },
        RefreshWebsocketService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function bootstrap(appConfigService: AppConfigService) {
    return () => appConfigService.loadConf();
}
