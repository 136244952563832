export * from './action-client.gen.service';
import { ActionClientServiceGen } from './action-client.gen.service';
export * from './action-objet.gen.service';
import { ActionObjetServiceGen } from './action-objet.gen.service';
export * from './action-type.gen.service';
import { ActionTypeServiceGen } from './action-type.gen.service';
export * from './all-enum.gen.service';
import { AllEnumServiceGen } from './all-enum.gen.service';
export * from './app-config.gen.service';
import { AppConfigServiceGen } from './app-config.gen.service';
export * from './audit.gen.service';
import { AuditServiceGen } from './audit.gen.service';
export * from './audit-author.gen.service';
import { AuditAuthorServiceGen } from './audit-author.gen.service';
export * from './client.gen.service';
import { ClientServiceGen } from './client.gen.service';
export * from './client-famille.gen.service';
import { ClientFamilleServiceGen } from './client-famille.gen.service';
export * from './client-sous-famille.gen.service';
import { ClientSousFamilleServiceGen } from './client-sous-famille.gen.service';
export * from './client-statistique-type.gen.service';
import { ClientStatistiqueTypeServiceGen } from './client-statistique-type.gen.service';
export * from './contact.gen.service';
import { ContactServiceGen } from './contact.gen.service';
export * from './contact-fonction.gen.service';
import { ContactFonctionServiceGen } from './contact-fonction.gen.service';
export * from './devis.gen.service';
import { DevisServiceGen } from './devis.gen.service';
export * from './devis-statut.gen.service';
import { DevisStatutServiceGen } from './devis-statut.gen.service';
export * from './document.gen.service';
import { DocumentServiceGen } from './document.gen.service';
export * from './duree-type.gen.service';
import { DureeTypeServiceGen } from './duree-type.gen.service';
export * from './entite.gen.service';
import { EntiteServiceGen } from './entite.gen.service';
export * from './external.gen.service';
import { ExternalServiceGen } from './external.gen.service';
export * from './facture.gen.service';
import { FactureServiceGen } from './facture.gen.service';
export * from './oui-non.gen.service';
import { OuiNonServiceGen } from './oui-non.gen.service';
export * from './password.gen.service';
import { PasswordServiceGen } from './password.gen.service';
export * from './rappel-automatique.gen.service';
import { RappelAutomatiqueServiceGen } from './rappel-automatique.gen.service';
export * from './rappel-automatique-type.gen.service';
import { RappelAutomatiqueTypeServiceGen } from './rappel-automatique-type.gen.service';
export * from './recherche-rapide.gen.service';
import { RechercheRapideServiceGen } from './recherche-rapide.gen.service';
export * from './role.gen.service';
import { RoleServiceGen } from './role.gen.service';
export * from './statistique.gen.service';
import { StatistiqueServiceGen } from './statistique.gen.service';
export * from './tag.gen.service';
import { TagServiceGen } from './tag.gen.service';
export * from './tag-type.gen.service';
import { TagTypeServiceGen } from './tag-type.gen.service';
export * from './utilisateur.gen.service';
import { UtilisateurServiceGen } from './utilisateur.gen.service';
export * from './utilisateur-fonction.gen.service';
import { UtilisateurFonctionServiceGen } from './utilisateur-fonction.gen.service';
export const APIS = [ActionClientServiceGen, ActionObjetServiceGen, ActionTypeServiceGen, AllEnumServiceGen, AppConfigServiceGen, AuditServiceGen, AuditAuthorServiceGen, ClientServiceGen, ClientFamilleServiceGen, ClientSousFamilleServiceGen, ClientStatistiqueTypeServiceGen, ContactServiceGen, ContactFonctionServiceGen, DevisServiceGen, DevisStatutServiceGen, DocumentServiceGen, DureeTypeServiceGen, EntiteServiceGen, ExternalServiceGen, FactureServiceGen, OuiNonServiceGen, PasswordServiceGen, RappelAutomatiqueServiceGen, RappelAutomatiqueTypeServiceGen, RechercheRapideServiceGen, RoleServiceGen, StatistiqueServiceGen, TagServiceGen, TagTypeServiceGen, UtilisateurServiceGen, UtilisateurFonctionServiceGen];
