<div class="form-group my-3 font-size-80" *ngIf="securityLevel>0">
    <p class="m-0"><strong>Le mot de passe doit remplir 4 conditions :</strong></p>
    <ul class="list-unstyled m-0">
        <li class="text-dark" [ngClass]="{ 'condition-respectee': securityLevel > 0 && !lessThanEight }">
            <i
                    [ngClass]="{ 'far fa-circle': securityLevel == 0 || lessThanEight, 'fa fa-check-circle': securityLevel > 0 && !lessThanEight }"
                    aria-hidden="true"></i> Au moins 10 caractères
        </li>
        <li class="text-dark" [ngClass]="{ 'condition-respectee': !missingLetter }">
            <i [ngClass]="{ 'far fa-circle': missingLetter, 'fa fa-check-circle': !missingLetter }"
               aria-hidden="true"></i> Au
            moins une lettre
        </li>
        <li class="text-dark" [ngClass]="{ 'condition-respectee': !missingNumber }">
            <i [ngClass]="{ 'far fa-circle': missingNumber, 'fa fa-check-circle': !missingNumber }"
               aria-hidden="true"></i> Au
            moins un chiffre
        </li>
        <li class="text-dark" [ngClass]="{ 'condition-respectee': !missingSpecial }">
            <i [ngClass]="{ 'far fa-circle': missingSpecial, 'fa fa-check-circle': !missingSpecial }"
               aria-hidden="true"></i>
            Au moins un caractère spécial
        </li>
    </ul>

    <p class="mb-0 mt-2">
        <strong>
            Sécurité :
            <span class="security-level"
                  [ngClass]="{ 'text-danger': securityLevel <= 1, 'text-warning': securityLevel == 2 , 'text-success': securityLevel > 2 }">{{securityText}}</span>
        </strong>
    </p>

    <meter class="security-meter" [value]="securityLevel" min="0" low="2" high="3" optimum="4" max="4"></meter>

    <p class="security-message text-dark"></p>
</div>
