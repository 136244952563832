<ul ngbNav [(activeId)]="active" class="nav-tabs mt-2">
    <li *ngFor="let tab of tabs; let index = index" [ngbNavItem]="index+1">
        <a [class.navbar-error]="isInvalid(index)" ngbNavLink>{{getTitre(tab)}}
            <ng-container
                    *ngTemplateOutlet="tab.customTitreTemplate;context:{element: form?.get(tab.formControlName)?.value}">
            </ng-container>
            <a *ngIf="isInvalid(index)" class="label-required">*</a>
        </a>
    </li>
</ul>

<div *ngFor="let tab of tabs; let index = index"
     [hidden]="(index + 1) !== active"
     class="nav-container">
    <ascore-tab [id]="'tab_' + index"
                [tab]="tab"
                [tabAffiche]="(index + 1) == active"
                [withSearchAudit]="tab.withSearchAudit"
                [form]="form">
    </ascore-tab>
</div>
