import { Injectable } from '@angular/core';
import { IdInstanceLabelGen } from '../../generated';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  // La liste à plat de tous les enums backend avec Id + InstanceLabel
  private allEnums: any[] = [];

  public setAllEnums(allEnums: any) {
    this.allEnums = allEnums;
  }

  // Exemple d'usage : this.getValue('Role', 'ROLE_GESTIONNAIRE')
  public getValue(enumName, id): IdInstanceLabelGen {
    return this.allEnums[enumName].filter(value => value.id === id);
  }

  public getValues(enumName) : IdInstanceLabelGen[] {
    return this.allEnums[enumName];
  }
}
