import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { AsCoreError, ControlErrorComponent } from './control-error/control-error.component';
import { merge } from 'rxjs';
import { AsCoreBaseDomain } from '../../models/ascore-base-domain';
import { looksLikeDate, looksLikeDateTime, todayForInput } from '../../utils/date-util';
import { DateFormatPipe } from '../../pipe/date-format.pipe';
import { DateTimeFormatPipe } from '../../pipe/date-time-format.pipe';
import { isNil } from 'lodash';
import { AsCoreFormControl, AsCoreValidator, TypeAsCoreValidator } from './AsCoreFormControl';
import { AsCoreClearInputComponent } from './ascore-clear-input/ascore-clear-input.component';
import { isBlank } from '../../utils/string-util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from '../../service/message.service';

@UntilDestroy()
@Directive({
  selector: 'input:not([asCoreInputExcluded]), textarea'
})
export class AsCoreInputDirective implements OnInit, OnChanges {

  @HostBinding('class')
  elementClass = this.getClass();

  parentNode: any;

  divElement: any;
  divDirectContainer: any;
  originalInputElement: any;

  controlErrorRef: ComponentRef<ControlErrorComponent>;
  clearInputRef: ComponentRef<AsCoreClearInputComponent>;
  asCoreLabel: any;
  asCoreTextLabel: any;
  container: ViewContainerRef;

  pReadOnly: any;

  requiredSpan: any;

  labelAdded = false;

  @Input() customErrors = {};
  @Input() label: string;
  @Input() recap = false;

  // En px
  @Input() labelWidth = 100;

  @Input() readOnly = false;

  // Permet de conserver l'input en mode readonly (pas de transformation en <p>)
  @Input()
  forceKeepInput = false;

  // Style disabled simulé (le vrai disable est utilisé pour du readOnly)
  @Input() asCoreDisabled = false;

  @Input() containerDivCustomClass = '';

  @Input()
  emptyValue = '-';

  // Utilisé uniquement pour le readonly pour afficher une propriété custom d'un objet complexe.
  @Input()
  propertyToDisplay;

  @Input()
  forceDateFormat = false;

  // Utilisé pour indiqué si l'input doit se comporter à la manière des composants Angular material
  // https://material.angular.io/components/input/overview
  @Input()
  asMaterial = false;

  @Input()
  canCopyValueToClipboard = false;

  constructor(
    private dateFormat: DateFormatPipe,
    private dateTimeFormat: DateTimeFormatPipe,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private app: ApplicationRef,
    private messageService: MessageService,
    @Optional() private ngControl: NgControl) {
    this.container = viewContainerRef;
    this.originalInputElement = elementRef.nativeElement;
  }

  isReadOnly(): boolean {
    return this.asCoreDisabled
      || this.readOnly
      || this.control?.disabled
      || !isNil(this.elementRef.nativeElement.getAttribute('readonly'));
  }

  public isRequired(): boolean {
    return this.hasValidator('required')
      || !isNil(this.elementRef.nativeElement.getAttribute('required'));
  }

  isInline(): boolean {
    return !isNil(this.elementRef.nativeElement.getAttribute('inline'));
  }

  public hasValidator(validator: TypeAsCoreValidator): boolean {
    return (this.control instanceof AsCoreFormControl) ?
      this.control?.hasValidator(validator) : false;
  }

  getValidator(validator: string): AsCoreValidator {
    return this.control?.listValidator?.find(it => it.key === validator);
  }

  /**
   * Permet le multiligne sur les textarea
   */
  @HostListener('keydown.enter', ['$event'])
  public onEnter(event: any): void {
    if (this.elementRef.nativeElement instanceof HTMLTextAreaElement) {
      event.stopPropagation();
    }
  }

  /**
   * Au moment du focus, si asMaterial on vide le placeholder et on ajoute le label au dessus de l'input, si non présent.
   */
  @HostListener('focus', ['$event'])
  onFocus(event: InputEvent): void {
    if (this.asMaterial) {
      this.originalInputElement.placeholder = '';
      if (!this.labelAdded) {
        this.addLabel();
      }
    }
  }

  /**
   * Quand on perd le focus, on remet le placeholder et on retire le label
   * (si pas de valeur, sinon pas besoin de retirer le label car le placeholder est affiché)
   */
  @HostListener('blur', ['$event'])
  onBlur(event: InputEvent): void {
    if (this.asMaterial) {
      this.originalInputElement.placeholder = this.getLabel();
      if (this.control && !this.control.value) {
        this.removeLabel();
      }
    }
  }

  ngOnInit(): void {
    if (this.control && this.control.listValidatorObservable) {
      this.initForFromControl();
      this.control.listValidatorObservable
        .pipe(untilDestroyed(this))
        .subscribe(() => this.refreshInputConstraints());
      this.control.asCoreLabelObservable
        .pipe(untilDestroyed(this))
        .subscribe(() => this.changesLabel());
      this.control.asCoreCustomClassObservable
        .pipe(untilDestroyed(this))
        .subscribe(() => this.changesCustomClass());
    } else {
      // Besoin de validation si pas de formControlName ?
    }

    this.processElementTransformation();
  }

  processElementTransformation(): void {
    if (!this.divElement) {
      this.buildComponent();
    }
    this.processReadOnly();
  }

  initForFromControl(): void {

    // Surcharge du comportement des formControls

    const self = this;
    const origFunc = this.control.markAsDirty;
    this.control.markAsDirty = function(): void {
      origFunc.apply(this, arguments);
      self.checkErrors();
    };

    merge(
      this.control.valueChanges,
      this.control.statusChanges
    ).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.checkErrors();
        this.processReadOnly();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processElementTransformation();
    if (changes.label) {
      this.changesLabel();
    }
  }

  changesLabel(): void {
    if (this.asCoreLabel) {
      this.asCoreTextLabel.textContent = this.getLabel() ? this.getLabel() : '';
    }
  }

  changesCustomClass(): void {
    if (this.divElement) {
      if (this.containerDivCustomClass) {
        // Si on avait une classe deja renseignee
        this.renderer.removeClass(this.divElement, this.containerDivCustomClass);
      }
      this.renderer.addClass(this.divElement, this.control?.containerDivCustomClass);
      this.containerDivCustomClass = this.control?.containerDivCustomClass;
    }
  }

  buildComponent(): void {
    this.addDivContainer();
    if (!this.asMaterial) {
      this.addLabel();
    }
    this.addInputConstraints();
    this.addInputDateClear();
    // Des éléments peuvent deja être en erreur
    this.checkErrors();
  }

  addDivContainer(): void {
    // Pour la gestion du style on commence par ajouter un container (div) autour de notre input

    this.divElement = this.renderer.createElement('div');
    this.renderer.addClass(this.divElement, 'ascore-input');
    if (!this.asMaterial) {
      this.renderer.addClass(this.divElement, 'pb-1');
    }

    this.containerDivCustomClass = this.containerDivCustomClass ? this.containerDivCustomClass : this.control?.containerDivCustomClass;

    if (this.containerDivCustomClass) {
      this.renderer.addClass(this.divElement, this.containerDivCustomClass);
    }

    if (this.isInline() && !this.asMaterial) {
      this.renderer.addClass(this.divElement, 'd-inline-flex');
      this.renderer.addClass(this.divElement, 'ascore-input-inline');
      this.renderer.addClass(this.divElement, 'w-100');
      this.renderer.addClass(this.elementRef.nativeElement, 'mt-0');
      this.renderer.addClass(this.elementRef.nativeElement, 'flex-1');
      if (this.getLabel()) {
        this.renderer.addClass(this.elementRef.nativeElement, 'ml-2');
      }
    }

    // insertion de la div
    this.renderer.insertBefore(this.getParent(), this.divElement, this.elementRef.nativeElement);

    // suppression
    this.renderer.removeChild(this.getParent(), this.elementRef.nativeElement);
    // reinsertion de l'input
    this.divDirectContainer = this.renderer.createElement('div');

    if ((this.originalInputElement.type !== 'date' && !this.isReadOnly()) || this.asMaterial) {
      this.renderer.addClass(this.divDirectContainer, 'd-flex');
      this.renderer.addClass(this.divDirectContainer, 'flex-1');
    }

    this.renderer.appendChild(this.divElement, this.divDirectContainer);
    this.renderer.appendChild(this.divDirectContainer, this.originalInputElement);

    if (this.asMaterial) {
      this.originalInputElement.placeholder = this.getLabel();
      this.renderer.addClass(this.originalInputElement, 'mt-3');
      this.renderer.addClass(this.originalInputElement, 'ml-2');
      this.renderer.setStyle(this.originalInputElement, 'width', '100%');
    }

  }

  removeLabel(): void {
    // Suppression du label
    if (!isNil(this.asCoreLabel)) {
      this.renderer.removeChild(this.getParent(), this.asCoreLabel);
    }
    this.labelAdded = false;
  }

  getLabel(): string {
    if (!isBlank(this.label)) {
      return this.label;
    }

    if (this.control?.getLabel) {
      return this.control?.getLabel();
    } else {
      return null;
    }
  }


  addLabel(): void {

    if (!this.getLabel()) {
      return;
    }

    // Ajout du label
    this.asCoreLabel = this.renderer.createElement('label');
    this.renderer.addClass(this.asCoreLabel, 'label-ascore');
    this.asCoreTextLabel = this.renderer.createText(this.getLabel());
    this.renderer.appendChild(this.asCoreLabel, this.asCoreTextLabel);


    if (this.asMaterial) {
      this.renderer.removeClass(this.asCoreLabel, 'label-ascore');
      this.renderer.addClass(this.asCoreLabel, 'label-ascore-search');
      this.renderer.addClass(this.asCoreLabel, 'ml-2');
    }

    this.requiredSpan = this.renderer.createElement('span');
    this.renderer.addClass(this.requiredSpan, 'label-required');
    this.renderer.addClass(this.requiredSpan, 'd-none');
    this.renderer.appendChild(this.requiredSpan, this.renderer.createText(' *'));
    this.renderer.appendChild(this.asCoreLabel, this.requiredSpan);

    if (this.isCheckBox()) {
      // reinsertion de l'input
      if (this.isReadOnly()) {
        if (this.forceKeepInput) {
          this.renderer.addClass(this.asCoreLabel, 'ml-2');
        } else {
          this.renderer.removeClass(this.asCoreLabel, 'ml-2');
        }
        this.originalInputElement.disabled = true;
      } else {
        this.renderer.addClass(this.asCoreLabel, 'ml-2');
        this.renderer.setStyle(this.asCoreLabel, 'cursor', 'pointer');
        this.renderer.listen(this.asCoreLabel, 'click', (event) => {
          this.control.setValue(!this.control.value);
        }).bind(this);
      }
      this.renderer.addClass(this.divElement, 'checkbox');
      this.renderer.addClass(this.divDirectContainer, 'd-inline-flex');

      this.renderer.appendChild(this.divElement, this.asCoreLabel);
      this.renderer.addClass(this.elementRef.nativeElement, 'mt-1');
      if (!this.isInline()) {
        this.renderer.addClass(this.asCoreLabel, 'mt-2');
      }
    } else {
      if (this.isInline()) {
        this.renderer.addClass(this.asCoreLabel, 'inline');
        this.renderer.addClass(this.asCoreLabel, 'mt-1');
        // this.renderer.addClass(this.asCoreLabel, 'mt-auto');
        // this.renderer.addClass(this.asCoreLabel, 'mb-auto');
        this.renderer.addClass(this.asCoreLabel, 'ml-0');
        this.renderer.addClass(this.asCoreLabel, 'mr-0');
      } else {
        this.renderer.addClass(this.asCoreLabel, 'mt-2');
      }
      this.renderer.insertBefore(this.divElement, this.asCoreLabel, this.divDirectContainer);
    }
    if (this.isInline()) {
      this.renderer.setStyle(this.asCoreLabel, 'width', this.labelWidth + 'px');
    }

    this.labelAdded = true;
  }

  refreshInputConstraints(): void {
    this.elementRef.nativeElement.max = undefined;
    this.elementRef.nativeElement.min = undefined;
    this.elementRef.nativeElement.maxlength = undefined;
    this.elementRef.nativeElement.minlength = undefined;
    this.addInputConstraints();
  }

  addInputConstraints(): void {
    if (!isNil(this.requiredSpan)) {
      if (this.isRequired()) {
        this.renderer.removeClass(this.requiredSpan, 'd-none');
      } else {
        this.renderer.addClass(this.requiredSpan, 'd-none');
      }
    }

    if (this.hasValidator('noFuture')) {
      this.elementRef.nativeElement.max = todayForInput();
    }
    if (this.hasValidator('noPast')) {
      this.elementRef.nativeElement.min = todayForInput();
    }

    const rangeValidator = this.getValidator('range');
    if (rangeValidator) {
      this.elementRef.nativeElement.min = rangeValidator.value.min;
      this.elementRef.nativeElement.max = rangeValidator.value.max;
    }

    const minValidator = this.getValidator('min');
    if (minValidator) {
      this.elementRef.nativeElement.min = minValidator.value;
    }

    const maxValidator = this.getValidator('max');
    if (maxValidator) {
      this.elementRef.nativeElement.max = maxValidator.value;
    }

    const minDateValidator = this.getValidator('minDate');
    if (minDateValidator) {
      this.elementRef.nativeElement.min = minDateValidator.value;
    }

    const maxDateValidator = this.getValidator('maxDate');
    if (maxDateValidator) {
      this.elementRef.nativeElement.max = maxDateValidator.value;
    }

    const minLengthValidator = this.getValidator('minlength');
    if (minLengthValidator) {
      this.elementRef.nativeElement.minlength = minLengthValidator.value;
    }

    const maxLengthValidator = this.getValidator('maxlength');
    if (maxLengthValidator) {
      this.elementRef.nativeElement.maxlength = maxLengthValidator.value;
    }
  }

  getParent(): any {
    if (!this.parentNode) {
      this.parentNode = this.elementRef.nativeElement.parentNode;
    }
    return this.parentNode;
  }

  /**
   * Traitement des champs en readOnly
   */
  processReadOnly(): void {
    if (!this.divElement) {
      // Le componsant n'a pas été initialisé
      return;
    }
    if (this.isReadOnly() && !this.forceKeepInput) {
      if (this.isCheckBox()) {
        // Pour la checkbox, le style n'est pas le même en readonly (case à cocher devient texte 'Oui'/'Non')
        // Cela affecte la position du label or, lors de l'ajout du label, on ne sait pas encore si on est en mode ReadOnly ou non,
        // c'est pourquoi on supprime puis on ajoute à nouveau le label dans processReadonly().
        this.removeLabel();
        this.addLabel();
      }

      const valueForReadOnly = this.calculateTextForReadOnly();
      if (!this.pReadOnly) {
        this.renderer.removeChild(this.divElement, this.elementRef.nativeElement);
      } else {
        this.renderer.removeChild(this.divElement, this.pReadOnly);
      }
      this.pReadOnly = this.renderer.createElement('p');
      this.renderer.addClass(this.pReadOnly, 'p-0');
      this.renderer.addClass(this.pReadOnly, 'm-0');
      this.renderer.addClass(this.pReadOnly, 'form-control');
      this.renderer.addClass(this.pReadOnly, 'form-control-sm');
      this.renderer.setStyle(this.pReadOnly, 'min-width', '100px'); // Taille minimale pour afficher une erreur si champ vide

      if (this.asCoreDisabled && !this.control.disabled && !this.readOnly) {
        this.renderer.addClass(this.pReadOnly, 'ascore-disabled');
      }
      if (this.isInline()) {
        this.renderer.addClass(this.pReadOnly, 'ml-2');
        this.renderer.addClass(this.pReadOnly, 'flex-1');
        this.renderer.setStyle(this.pReadOnly, 'line-height', '1.6rem');
      } else {
        this.renderer.setStyle(this.pReadOnly, 'line-height', '1rem');
      }
      this.renderer.setStyle(this.pReadOnly, 'font-weight', 'bold');

      const textP = this.renderer.createText(valueForReadOnly);
      this.renderer.appendChild(this.pReadOnly, textP);
      this.renderer.appendChild(this.divElement, this.pReadOnly);

      if (this.canCopyValueToClipboard) {
        const copyIcon = this.renderer.createElement('fa-icon');
        this.renderer.addClass(copyIcon, 'ml-2');
        this.renderer.addClass(copyIcon, 'fas');
        this.renderer.addClass(copyIcon, 'fa-copy');
        this.renderer.appendChild(this.pReadOnly, copyIcon);
        copyIcon.addEventListener('click', () => {
          navigator.clipboard.writeText(this.calculateTextForReadOnly())
            .then(r => this.messageService.showSuccess('Copié'));
        });
      }

      if (!isNil(this.clearInputRef)) {
        this.clearInputRef.destroy();
      }

    } else if (this.isReadOnly() && isBlank(this.elementRef.nativeElement.value)) {
      this.elementRef.nativeElement.value = this.emptyValue;
    } else if (this.pReadOnly) {
      this.renderer.removeChild(this.divElement, this.pReadOnly);
      this.pReadOnly = null;
      this.renderer.appendChild(this.divElement, this.originalInputElement);
    }
  }

  private calculateTextForReadOnly(): string {

    let value = this.control ? this.control.value : this.originalInputElement.value;

    // Tester les valeurs boolennes avant le 'isBlank'
    if (value === true) {
      return 'Oui';
    } else if (value === false) {
      return 'Non';
    }

    if (isBlank(value)) {
      return this.emptyValue;
    }
    if (!isBlank(this.propertyToDisplay)) {
      value = value[this.propertyToDisplay];
      if (isBlank(value)) {
        return this.emptyValue;
      }
    }

    // Si besoin faire propertyToDisplay='code'
    // if (value.hasOwnProperty('code') && !isNil(value.code)) {
    //   return value.code;
    // }
    if (value.hasOwnProperty('instanceLabel') || value instanceof AsCoreBaseDomain) {
      return value.instanceLabel;
    }
    if (this.forceDateFormat || looksLikeDate(value)) {
      return this.dateFormat.transform(value);
    }
    if (!this.forceDateFormat && looksLikeDateTime(value)) {
      return this.dateTimeFormat.transform(value);
    }
    if (value === true) {
      return 'Oui';
    } else if (value === false) {
      return 'Non';
    }
    return value;
  }

  get control(): AsCoreFormControl {
    // @ts-ignore
    return this.ngControl?.control as AsCoreFormControl;
  }

  checkErrors(): void {
    const controlErrors = this.control?.errors;
    if (controlErrors && this.control.dirty) {
      const firstKey = Object.keys(controlErrors)[0];
      this.setError({code: firstKey, context: controlErrors[firstKey]});
    } else if (this.controlErrorRef) {
      this.setError(null);
    }
  }

  setError(error: AsCoreError): void {

    if (!this.controlErrorRef) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);

      const divErrorNode = document.createElement('div');
      this.divElement.appendChild(divErrorNode);

      this.controlErrorRef = factory.create(this.injector, [], divErrorNode);
      this.app.attachView(this.controlErrorRef.hostView);
    }

    this.controlErrorRef.instance.inline = this.isInline();
    this.controlErrorRef.instance.customErrors = this.customErrors;
    this.controlErrorRef.instance.error = error;
  }

  isCheckBox(): boolean {
    return this.elementRef?.nativeElement?.type === 'checkbox';
  }

  private getClass(): string {
    if (!this.isCheckBox()) {
      return 'form-control form-control-sm';
    }
    return '';
  }

  private addInputDateClear(): void {
    const isChrome = !isNil((window as any).chrome);

    if (!this.clearInputRef && this.originalInputElement.type === 'date' && isChrome && !this.isReadOnly()) {
      if (this.asMaterial) {
        this.updateInputDateBehaviourToAsMaterial();
      } else {
        this.addInputDateClearFromContext(this);
      }
    }
  }

  private updateInputDateBehaviourToAsMaterial(): void {
    this.originalInputElement.type = 'text';
    const self = this;
    // A la perte de focus, on passe en texte si pas de date saisie.
    this.originalInputElement.onblur = function(event): void {
      if (!this.value) {
        this.type = 'text';
        for (let i = 0; i < self.divDirectContainer.children.length - 1; i++) {
          // On supprime les éventuels clear.
          self.divDirectContainer.removeChild(self.divDirectContainer.children[i]);
        }
      }
    };
    // Au focus, on transforme le type en date et on ajoute le clear.
    this.originalInputElement.onfocus = function(event): void {
      if (this.type !== 'date') {
        this.type = 'date';
        self.addInputDateClearFromContext(self);
        self.renderer.setStyle(self.divDirectContainer.childNodes[0], 'top', '20px');
      }
    };
  }

  private addInputDateClearFromContext(context: any): void {
    const factory = context.resolver.resolveComponentFactory(AsCoreClearInputComponent);
    const divClearNode = document.createElement('div');

    context.renderer.setStyle(context.divDirectContainer, 'position', 'relative');
    context.renderer.insertBefore(context.divDirectContainer, divClearNode, context.originalInputElement);

    context.clearInputRef = factory.create(context.injector, [], divClearNode);
    context.clearInputRef.instance.formControl = context.control;

    // déplace la croix pour éviter superposition avec l'icône calendrier :
    context.renderer.setStyle(divClearNode, 'margin-right', '15px');

    context.app.attachView(context.clearInputRef.hostView);
  }
}
