<div class="modal-header text-center">
    <h4 class="modal-title">Ajouter un document</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <ngx-file-drop contentClassName="filedrop" dropZoneClassName="drop-zone" dropZoneLabel='Déposer les fichiers ici'
                   (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <img src="assets/images/cloudImage.png" alt="" style="width: 60px">
                </div>
                <div class="row justify-content-center">
                    <h5>Glisser et déposer le document</h5>
                </div>
                <div class="row justify-content-center">
                    <h5>Ou</h5>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-auto">
                        <button type="button" class="btn btn-primary" (click)="openFileSelector()">
                            <i class="fa fa-folder mr-1"></i>Parcourir
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>

    <div [formGroup]="form" class="mt-2">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Fichier</th>
                <th>Commentaire</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
            <ng-container formArrayName="formArray" *ngFor="let group of getFormArray().controls; let i=index">

                <tr [formGroupName]="i">
                    <td>
                        {{group.get('file').value.name}}
                    </td>
                    <td>
                        <input type="text"
                               formControlName="commentaire">
                    </td>

                    <td>
                        <button
                                (click)="removeDocument(i)"
                                class="btn btn-danger btn-sm" placement="left">
                            <i class="uil uil-times text-danger"></i>
                            <span class="d-none d-md-inline ml-1">Supprimer</span>
                        </button>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button ngbAutofocus class="btn btn-primary"
            type="submit"
            (click)="uploadAllDocument()">Enregistrer
    </button>
</div>
