import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() type: string;
  @Input() dismiss: string;

  faTimes = faTimes;

  public dismissAlert(element) {
    element.parentElement.removeChild(element);
  }

  constructor() {
  }
}
