import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { ElementRef, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentDetailComponent } from './document-detail/document-detail.component';
import { AsCoreColumn } from "../../../shared/ascore/component/ascore-table/ascore-table.model";
import { DocumentServiceGen } from "../../../shared/generated";

@Injectable()
export class DocumentUtil {

    constructor(private documentService: DocumentServiceGen,
                private modalService: NgbModal) {
    }

    // L'usage du callback est à privilégier si le readonly est "dynamique" et change selon le form.
    public tabListDocumentWithCallbackOnReadonly(hideAdd: () => boolean,
                                                 hideDelete: () => boolean,
                                                 ignoreOpen: () => boolean,
                                                 readonly: boolean,
                                                 downloadDocumentTpl: ElementRef,
                                                 titleDocumentTabTpl: ElementRef,
                                                 addTemplate: any): any {

        const columnsDocument: AsCoreColumn[] = this.getColumnsDocument(downloadDocumentTpl);

        return {
            titre: 'Documents',
            type: 'composition',
            withRead: this.documentService,
            withCreate: this.documentService,
            withDelete: this.documentService,
            columns: columnsDocument,
            placeHolder: 'Ajouter un document',
            hideAdd: hideAdd,
            hideDelete: hideDelete,
            ignoreOpen: ignoreOpen,
            formControlName: 'listDocument',
            detailTemplate: DocumentDetailComponent,
            addTemplate: addTemplate,
            skipAutosaveAfterClosePopup: true,
            customTitreTemplate: titleDocumentTabTpl,
        };

    }

    private getColumnsDocument(downloadDocumentTpl: ElementRef<any>) {
        return [
            { header: 'Libellé', fieldName: 'libelle', sortFieldName: 'libelle' },
            {
                header: 'Date de création',
                fieldName: 'created'
            },
            {
                header: 'Commentaire',
                fieldName: 'commentaire'
            },
            { header: 'Actions', template: downloadDocumentTpl }
        ];
    }

    public tabListDocument(readonly: boolean,
                           downloadDocumentTpl: ElementRef,
                           statutDocumentTpl: ElementRef,
                           titleDocumentTabTpl: ElementRef): any {

        return this.tabListDocumentWithCallbackOnReadonly(
            () => readonly,
            () => readonly,
            () => readonly,
            readonly,
            downloadDocumentTpl,
            titleDocumentTabTpl,
            DocumentUploadComponent);
    }

    public tabListDocumentWithUniqueCallbackOnReadonly(readonly: () => boolean,
                                                       downloadDocumentTpl: ElementRef,
                                                       statutDocumentTpl: ElementRef,
                                                       titleDocumentTabTpl: ElementRef): any {

        return this.tabListDocumentWithCallbackOnReadonly(
            readonly,
            readonly,
            readonly,
            readonly(),
            downloadDocumentTpl,
            titleDocumentTabTpl,
            DocumentUploadComponent);
    }
}
