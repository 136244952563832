import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: any): string {
    const format = 'dd/MM/yyyy, HH:mm';
    return value !== null ? this.datePipe.transform(new Date(value), format) : value;
  }
}
