/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EnergieCommercialeDtoGen { 
    clientId?: number;
    clientLibelle?: string;
    entiteId?: number;
    entiteLibelle?: string;
    montant?: number;
    nombreContrat?: number;
    nombreHeure?: number;
}

export class EnergieCommercialeDtoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientLibelle = 'clientLibelle';
  readonly clientLibelle = 'clientLibelle';
  static readonly entiteId = 'entiteId';
  readonly entiteId = 'entiteId';
  static readonly entiteLibelle = 'entiteLibelle';
  readonly entiteLibelle = 'entiteLibelle';
  static readonly montant = 'montant';
  readonly montant = 'montant';
  static readonly nombreContrat = 'nombreContrat';
  readonly nombreContrat = 'nombreContrat';
  static readonly nombreHeure = 'nombreHeure';
  readonly nombreHeure = 'nombreHeure';
  static fields() {
    return new EnergieCommercialeDtoGen_();
  }
}

