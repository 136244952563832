/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientSousFamilleGen } from './client-sous-famille.gen.model';
import { ClientFamilleGen } from './client-famille.gen.model';
import { ContactGen } from './contact.gen.model';
import { DocumentGen } from './document.gen.model';
import { ClientTagGen } from './client-tag.gen.model';
import { ActionClientGen } from './action-client.gen.model';


export interface ClientGen { 
    adresse?: string;
    clientFamille?: ClientFamilleGen;
    clientSousFamille?: ClientSousFamilleGen;
    codeClient: string;
    created: Date;
    createdBy: string;
    dateCreation?: Date;
    dtype?: string;
    email?: string;
    id?: number;
    instanceLabel: string;
    listAction?: Set<ActionClientGen>;
    listContact?: Set<ContactGen>;
    listDocument?: Set<DocumentGen>;
    listTag?: Set<ClientTagGen>;
    nom: string;
    nombreHeure?: number;
    prospect?: boolean;
    quadra?: boolean;
    telephone?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ClientGen_ {
  static readonly adresse = 'adresse';
  readonly adresse = 'adresse';
  static readonly clientFamille = 'clientFamille';
  readonly clientFamille = 'clientFamille';
  static readonly clientSousFamille = 'clientSousFamille';
  readonly clientSousFamille = 'clientSousFamille';
  static readonly codeClient = 'codeClient';
  readonly codeClient = 'codeClient';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateCreation = 'dateCreation';
  readonly dateCreation = 'dateCreation';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listAction = 'listAction';
  readonly listAction = 'listAction';
  static readonly listContact = 'listContact';
  readonly listContact = 'listContact';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listTag = 'listTag';
  readonly listTag = 'listTag';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly nombreHeure = 'nombreHeure';
  readonly nombreHeure = 'nombreHeure';
  static readonly prospect = 'prospect';
  readonly prospect = 'prospect';
  static readonly quadra = 'quadra';
  readonly quadra = 'quadra';
  static readonly telephone = 'telephone';
  readonly telephone = 'telephone';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ClientGen_();
  }
}

