import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';
import { PasswordConstants } from './password-constants';

@Directive({
  selector: '[appPassword]',
  providers: [{multi: true, provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective}]
})
export class PasswordValidatorDirective implements Validator {

  validate(control: FormControl): ValidationErrors {
    if (!control.value) return null
    return Validators.compose([
      Validators.minLength(10),
      (c) => {
        const value = c.value || '';

        let nbValidConditions = 0;

        // Lettre obligatoire
        if (value.match(PasswordConstants.REGEX_LETTER)) {
          nbValidConditions += 1;
        }

        // Numérique obligatoire
        if (value.match(PasswordConstants.REGEX_NUMERIC)) {
          nbValidConditions += 1;
        }

        // Caractère spécial obligatoire
        if (value.match(PasswordConstants.REGEX_SPECIAL)) {
          nbValidConditions += 1;
        }

        // Vérification du nb de conditions minimum qui doivent être respectées pour que le mot de passe soit valide
        if (nbValidConditions < PasswordConstants.NB_VALID_REGEX_MIN) {
          return {passwordTooSimple: true};
        }

        return null;
      }
    ])(control);
  }

}
