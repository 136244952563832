<app-navigation class="pcoded-navbar"
                [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.flatConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
                [collapsed]="navCollapsed"
                (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light header-light headerpos-fixed"
             (onNavCollapse)="this.navCollapsed = !this.navCollapsed;"
             (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
    <div class="pcoded-content"
         [ngClass]="{'container': this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'}">
        <router-outlet></router-outlet>
    </div>
</div>
<app-configuration></app-configuration>
