<ascore-search typeDetail="viewable"
               [columns]="columns"
               [withSearch]="withSearch"
               [searchForm]="searchForm"
               [hideSearchForm]="true"
               [header]="false"
               [customOpen]="true"
               [closable]="false">
    <!-- customOpen = true pour éviter de planter quand on double-clique sur une ligne -->
</ascore-search>

<ng-template #listAuditChangeTemplate let-value="value">
    <pre>{{this.arrayToListRepr(value)}}</pre>
</ng-template>
