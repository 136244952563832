<div class="auth-wrapper maintenance">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="text-center">
                    <img src="../../../assets/images/maintenance/404.png" alt="" class="img-fluid">
                    <h5 class="text-muted my-4">Oups! La page n'a pas été trouvée !</h5>
                    <button type="submit" class="btn waves-effect waves-light btn-primary mb-4"
                            (click)="retourAccueil()"><i
                            class="feather icon-refresh-ccw mr-2"></i>Retour à l'accueil
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
