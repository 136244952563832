/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuditChangeGen } from './audit-change.gen.model';


export interface AuditCommitGen { 
    author?: string;
    dateTime?: Date;
    listAuditChange?: Array<AuditChangeGen>;
}

export class AuditCommitGen_ {
  static readonly author = 'author';
  readonly author = 'author';
  static readonly dateTime = 'dateTime';
  readonly dateTime = 'dateTime';
  static readonly listAuditChange = 'listAuditChange';
  readonly listAuditChange = 'listAuditChange';
  static fields() {
    return new AuditCommitGen_();
  }
}

