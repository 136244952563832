import { Component, Input, OnInit } from '@angular/core';

export interface AsCoreStep {
  name: string;
  current: boolean;
  previous: boolean;
  future: boolean;
}

@Component({
  selector: 'ascore-progress-step',
  templateUrl: './ascore-progress-step.component.html',
  styleUrls: ['./ascore-progress-step.component.scss']
})
export class AsCoreProgressStepComponent {

  @Input()
  steps: string[];

  @Input()
  currentStep: string;

  constructor() {
  }

  getListStep(): AsCoreStep[] {
    const asCoreListStep: AsCoreStep[] = [];
    let currentStepHandled = false;
    for (const step of this.steps) {
      currentStepHandled = currentStepHandled || step === this.currentStep;
      asCoreListStep.push({
        name: step,
        current: step === this.currentStep,
        previous: !currentStepHandled,
        future: (step !== this.currentStep) && currentStepHandled
      });
    }
    return asCoreListStep;
  }

  getStepClass(step: AsCoreStep): string {
    if (step.previous) {
      return 'step-previous';
    } else if (step.current) {
      return 'step-current';
    } else if (step.future) {
      return 'step-futur';
    } else {
      return '';
    }
  }
}
