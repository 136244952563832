import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsCoreColumn } from '../ascore-search/ascore-search.component';
import { SelectTableEvent } from '../ascore-table/ascore-table.model';
import { getValueRecursively } from '../../utils/object-util';
import { looksLikeDate, looksLikeDateTime } from '../../utils/date-util';
import { DateFormatPipe } from '../../pipe/date-format.pipe';
import { DateTimeFormatPipe } from '../../pipe/date-time-format.pipe';
import { IdInstanceLabel } from '../../models/id-instance-label';

@Component({
  selector: 'ascore-mobile-item',
  templateUrl: './ascore-mobile-item.component.html',
  styleUrls: ['./ascore-mobile-item.component.scss']
})
export class AsCoreMobileItemComponent {

  @Input()
  columns: AsCoreColumn[] = [];

  @Input()
  content: any[] = [];

  @Input()
  enableExternalStyle = false;

  @Output()
  openEvent = new EventEmitter<SelectTableEvent>();

  @Output()
  swipeEvent = new EventEmitter<boolean>();

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(private dateFormat: DateFormatPipe,
              private dateTimeFormat: DateTimeFormatPipe) {
  }

  getStyle(element: any): string {
    if (this.enableExternalStyle) {
      return element.style.replace('15px', '5px');
    } else {
      return null;
    }
  }

  getValue(element: IdInstanceLabel, fieldName: string): any {
    const value = element[fieldName];

    if (fieldName?.includes('.')) {
      return getValueRecursively(element, fieldName);
    } else if (value?.hasOwnProperty('instanceLabel')) {
      return value.instanceLabel;
    } else if (element[fieldName] instanceof Boolean || typeof element[fieldName] === 'boolean') {
      return element[fieldName] ? 'Oui' : 'Non';
    }
    if (looksLikeDate(value)) {
      return this.dateFormat.transform(value);
    }
    if (looksLikeDateTime(value)) {
      return this.dateTimeFormat.transform(value);
    }

    return value;
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        // const swipe = direction[0] < 0 ? 'next' : 'previous';
        this.swipeEvent.emit(direction[0] < 0);
      }
    }
  }

}
