import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ascore-slice-pagination',
  templateUrl: './slice-pagination.component.html',
  styleUrls: ['./slice-pagination.component.scss']
})
export class SlicePaginationComponent {

  @Input()
  first: boolean;

  @Input()
  last: boolean;

  @Input()
  page: number;

  @Output()
  pageChange = new EventEmitter();

  constructor() {
  }

  selectPreviousSlice(): void {
    this.pageChange.emit(this.page - 1);
  }

  selectNextSlice(): void {
    this.pageChange.emit(this.page + 1);
  }
}
