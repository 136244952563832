import { isBlank } from '../utils/string-util';
import { formatDateHeureToStringFrancais } from '../utils/date-util';
import { AsCoreBaseDomain } from './ascore-base-domain';
import { isNil, isString } from 'lodash';

export function infoCreation(entite: AsCoreBaseDomain): string {
  if (!entite.created) {
    return '';
  }
  const infoPar = !isBlank(entite.createdBy) ? ' par ' + entite.createdBy : '';
  return 'Créé(e) le ' + formatDateHeureToStringFrancais(entite.created) + infoPar;
}


export function infoHistorique(entite: AsCoreBaseDomain): string {
  let result = infoCreation(entite);
  if (entite.updated && entite.updated !== entite.created) {
    const infoPar = !isBlank(entite.updatedBy) ? ' par ' + entite.updatedBy : '';
    result += ', modifié(e) le ' + formatDateHeureToStringFrancais(entite.updated) + infoPar;
  }
  return result;
}


export function enumEquals(enumValue, valeur): boolean {
  if (isNil(enumValue) && isNil(valeur)) {
    return true;
  }
  if (isNil(enumValue) || isNil(valeur)) {
    return false;
  }
  if (isString(enumValue) && isString(valeur)) {
    return enumValue === valeur;
  }
  return enumValue === valeur ||
    (enumValue as any).id === valeur ||
    (enumValue as any).id === (valeur as any).id;
}
