import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AsCoreFormControl } from '../../shared/ascore/component/ascore-input/AsCoreFormControl';
import { UtilisateurCourantService } from '../../administration/referentiel/utilisateur-courant.service';
import { UtilisateurGen, UtilisateurGen_, UtilisateurServiceGen } from '../../shared/generated';
import { Observable } from 'rxjs';
import {
  ChangePasswordModalComponent
} from '../../authentication/password/change-password/change-password-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faKey } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mon-compte',
  templateUrl: './mon-compte.component.html',
  styleUrls: ['./mon-compte.component.scss']
})
export class MonCompteComponent implements OnInit {

  form: FormGroup;
  utilisateur: UtilisateurGen;

  faKey = faKey;

  constructor(private fb: FormBuilder,
              public utilisateurService: UtilisateurServiceGen,
              public utilisateurCourantService: UtilisateurCourantService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.utilisateur = this.utilisateurCourantService.getUtilisateurCourant()
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      id: AsCoreFormControl.init(),
      nom: AsCoreFormControl.init().label('Nom'),
      prenom: AsCoreFormControl.init().label('Prénom'),
      fonction: AsCoreFormControl.init().label('Fonction'),
      email: AsCoreFormControl.init().email().required().label('Email'),
      tel1: AsCoreFormControl.init().pattern('\\+?[\\d\\. -]*\\d').label('Téléphone 1'),
      tel2: AsCoreFormControl.init().pattern('\\+?[\\d\\. -]*\\d').label('Téléphone 2'),
      listRole: AsCoreFormControl.init([]).label('Rôles'),
      username: AsCoreFormControl.init().label('Idenfiant de connexion'),
      ancienUsername: AsCoreFormControl.init().label('Idenfiant de connexion'),
      ancienPassword: AsCoreFormControl.init().label('Ancien mot de passe'),
      nouveauPassword: AsCoreFormControl.init().label('Nouveau mot de passe'),
      password: AsCoreFormControl.init().label('Mot de passe'),
      actif: AsCoreFormControl.init().label('Actif')
    });
  }

  // @HostListener va empêcher la navigation hors de l'application (si on rafraîchit, si on clique sur un lien externe).
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.form.pristine;
  }

  changePassword(): void {
    const modal = this.modalService.open(ChangePasswordModalComponent, {backdrop: 'static'});
    modal.componentInstance.utilisateurId = this.form.get(UtilisateurGen_.id).value;
    modal.result.then(() => {
    }, () => {
    });
  }
}


