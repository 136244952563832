/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UtilisateurPojoGen { 
    actif?: boolean;
    created?: Date;
    createdBy?: string;
    email?: string;
    fonctionId?: number;
    id?: number;
    instanceLabel?: string;
    nom?: string;
    password?: string;
    prenom?: string;
    receptionRapportMail?: boolean;
    tel1?: string;
    tel2?: string;
    updated?: Date;
    updatedBy?: string;
    username?: string;
    versionNum?: number;
}

export class UtilisateurPojoGen_ {
  static readonly actif = 'actif';
  readonly actif = 'actif';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly fonctionId = 'fonctionId';
  readonly fonctionId = 'fonctionId';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly password = 'password';
  readonly password = 'password';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly receptionRapportMail = 'receptionRapportMail';
  readonly receptionRapportMail = 'receptionRapportMail';
  static readonly tel1 = 'tel1';
  readonly tel1 = 'tel1';
  static readonly tel2 = 'tel2';
  readonly tel2 = 'tel2';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly username = 'username';
  readonly username = 'username';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new UtilisateurPojoGen_();
  }
}

