export const PasswordConstants = {
  NB_REGEX: 3,
  NB_VALID_REGEX_MIN: 3,
  MIN_LENGTH: 10,
  SECURE_LENGTH: 10,
  REGEX_LETTER: /[A-Za-z]+/g,
  REGEX_LOWERCASE: /[a-z]+/g,
  REGEX_UPPERCASE: /[A-Z]+/g,
  REGEX_NUMERIC: /\d+/g,
  REGEX_SPECIAL: /\W+/g
};
