/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DureeTypeGen = 'MINUTE' | 'HEURE' | 'JOUR';

export const DureeTypeGen = {
        MINUTE: 'MINUTE' as DureeTypeGen,
        HEURE: 'HEURE' as DureeTypeGen,
        JOUR: 'JOUR' as DureeTypeGen
        };

export class DureeTypeGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new DureeTypeGen_();
}
}

