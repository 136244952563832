/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LabelValueDtoGen } from './label-value-dto.gen.model';


export interface TauxTransformationDtoGen { 
    listDevis?: Array<LabelValueDtoGen>;
    taux?: number;
}

export class TauxTransformationDtoGen_ {
  static readonly listDevis = 'listDevis';
  readonly listDevis = 'listDevis';
  static readonly taux = 'taux';
  readonly taux = 'taux';
  static fields() {
    return new TauxTransformationDtoGen_();
  }
}

