import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TypeAsCoreValidator } from '../AsCoreFormControl';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ascore-control-error',
  templateUrl: './control-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent {
  _error: AsCoreError;
  _hide = true;

  faExclamationTriangle = faExclamationTriangle;

  @Input() customErrors = {};

  @Input() inline = false;

  @Input() set error(value: AsCoreError) {
    if (value !== this._error) {
      this._error = value;
      this._hide = !value;
      this.changeDetectorRef.detectChanges();
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  getError(): string {

    // On commence par les messages custom
    if (this.customErrors && this.customErrors[this._error?.code]) {
      return this.customErrors[this._error?.code];
    }

    switch (this._error?.code as TypeAsCoreValidator) {
      case 'required':
        return `Champ obligatoire`;
      case 'email':
        return `Format e-mail invalide`;
      case 'min':
        return `Doit être supérieur ou égal à ${this._error.context.min}`;
      case 'max':
        return `Doit être inférieur ou égal à ${this._error.context.max}`;
      case 'minlength':
        return `Doit contenir au moins ${this._error.context.requiredLength} caractères`;
      case 'maxlength':
        return `Doit contenir au plus ${this._error.context.requiredLength} caractères`;
      case 'minDate':
        return `Doit être postérieur ou égal  ${this._error.context.minDate}`;
      case 'maxDate':
        return `Doit être antérieur ou égal ${this._error.context.maxDate}`;
      case 'pattern':
        return `Ne correspond pas au format attendu : ${this._error.context.requiredPattern}`;
      case 'noFuture':
        return `Ne doit pas être dans le futur`;
      case 'requiredIfEmpty':
        return `Champ obligatoire si ${this._error.context.requiredIfEmpty} est vide`;
      case 'range':
        return `Doit être compris entre ${this._error.context.min} et ${this._error.context.max}`;
      default:
        return `Erreur de validation`;
    }
  }
}

export interface AsCoreError {
  code: string;
  context: any;
}
