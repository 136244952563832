<div class="modal-header">
    <h6 class="modal-title"><i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;{{titre}}</h6>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="label-ascore">{{message}}<span class="label-required"> *</span></div>

    <ng-select
            [clearable]="false"
            [(ngModel)]="selectedItem"
    >
        <ng-option *ngFor="let item of listItem"
                   [value]="item.id">{{item.instanceLabel}}</ng-option>
    </ng-select>

    <div class="modal-footer">
        <button *ngIf="labelAnnuler" type="button" class="btn btn-secondary"
                (click)="modal.dismiss()">{{labelAnnuler}}
        </button>
        <button *ngIf="labelOk" type="button" class="btn" [class]="btnClass" ngbAutofocus
                [disabled]="!selectedItem"
                (click)="modal.close(this.selectedItem)">
            {{labelOk}}
        </button>
    </div>
</div>
