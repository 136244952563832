import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsCoreBaseDomain } from '../../models/ascore-base-domain';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AsCoreTab } from '../ascore-tab/ascore-tab.model';
import { WithCreate } from '../../service/api/with-create';

@Component({
  selector: 'ascore-create-detail',
  templateUrl: './ascore-create-detail.component.html',
  styleUrls: ['./ascore-create-detail.component.scss']
})
export class AscoreCreateDetailComponent<T extends AsCoreBaseDomain> {

  faTimes = faTimes;

  @Input()
  titre = "Création";

  @Input()
  service: WithCreate<T>;

  @Input()
  public form: FormGroup;

  @Input()
  tabs: AsCoreTab[] = [];

  constructor(public modal: NgbActiveModal) {}

  onSave(): void {
    this.service.create(this.form.getRawValue()).subscribe(result => {
      this.modal.close(result);
    });
  }
}
