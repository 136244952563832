import packageInfo from '../../package.json';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = {
  production: false,
  appVersion: packageInfo.version,
  dev: true,
  actionsTest: true,
  defaultDebounceTime: 200,  // durée tampon par défaut pour limiter le déclenchement de recherches trop rapprochées
  webSocketHandshakeURI: 'ws://localhost:8085/.stomp',
};
