<div class="card">
    <div *ngIf="header" class="card-header bg-info text-white d-inline-flex">
        {{title}}
        <div *ngIf="status" class="m-1 badge badge-pill badge-warning">{{status}}</div>
        <div class="flex-fill"></div>
        <button type="button" *ngIf="closable" class="close customClose" (click)="close()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
