<ascore-detail
        [form]="form"
        domainName="Utilisateur"
        [withCrud]="service"
        [tabs]="tabs"
>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 p-0 d-inline-flex flex-wrap">
                <div class="col-md-3">
                    <input type="text"
                           [formControlName]="fields.username"
                    >
                </div>
                <div class="col-md-2">
                    <input type="text"
                           asCoreUppercase
                           [formControlName]="fields.nom"
                    >
                </div>
                <div class="col-md-2">
                    <input type="text"
                           asCoreCapitalizeFully
                           [formControlName]="fields.prenom"
                    >
                </div>
                <div class="col-md-2">
                    <ascore-select #fonctionSelectSearch
                                   label="Fonction"
                                   [form]="form"
                                   [formControlName_]="fields.fonction"
                                   [withFilterSearchHelper]="fonctionWithFilterSearchHelper"
                                   showFieldName="instanceLabel"
                                   [minChar]="0">
                    </ascore-select>
                </div>
                <div class="col-md-1">
                    <input type="text"
                           [formControlName]="fields.tel1"
                    >
                </div>
                <div class="col-md-1">
                    <input type="text"
                           [formControlName]="fields.tel2"
                    >
                </div>
                <div class="col-md-3">
                    <input type="text"
                           asCoreLowercase
                           [formControlName]="fields.email"
                    >
                </div>
                <div class="col-md-2" *ngIf="isAdmin()">
                    <input type="password"
                           [formControlName]="fields.password"
                    >
                </div>
                <div class="col-md-1 mt-4">
                    <input type="checkbox"
                           [formControlName]="fields.actif"
                    >
                </div>
                <div class="col-md-3 mt-4">
                    <input type="checkbox"
                           [formControlName]="fields.receptionRapportMail"
                    >
                </div>
            </div>
        </div>
    </form>
</ascore-detail>
