/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UtilisateurGen } from './utilisateur.gen.model';
import { RappelAutomatiqueGen } from './rappel-automatique.gen.model';
import { ActionTypeGen } from './action-type.gen.model';
import { EntiteGen } from './entite.gen.model';
import { ClientGen } from './client.gen.model';
import { ContactGen } from './contact.gen.model';
import { DocumentGen } from './document.gen.model';
import { DureeTypeGen } from './duree-type.gen.model';
import { DevisStatutGen } from './devis-statut.gen.model';
import { TagGen } from './tag.gen.model';
import { ActionObjetGen } from './action-objet.gen.model';


export interface ActionClientGen { 
    actionType: ActionTypeGen;
    besoins?: string;
    client: ClientGen;
    commentaire?: string;
    commercial?: UtilisateurGen;
    contexte?: string;
    created: Date;
    createdBy: string;
    date: Date;
    devisStatut?: DevisStatutGen;
    dtype?: string;
    duree?: number;
    dureeMinute?: number;
    dureeType?: DureeTypeGen;
    dureeValue?: number;
    id?: number;
    instanceLabel: string;
    listContact?: Set<ContactGen>;
    listDocument?: Set<DocumentGen>;
    listEntite?: Set<EntiteGen>;
    listRappelAutomatique?: Set<RappelAutomatiqueGen>;
    listTag?: Set<TagGen>;
    montantXPF?: number;
    objet: ActionObjetGen;
    prochaineEtape?: string;
    proposition?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ActionClientGen_ {
  static readonly actionType = 'actionType';
  readonly actionType = 'actionType';
  static readonly besoins = 'besoins';
  readonly besoins = 'besoins';
  static readonly client = 'client';
  readonly client = 'client';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly commercial = 'commercial';
  readonly commercial = 'commercial';
  static readonly contexte = 'contexte';
  readonly contexte = 'contexte';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly date = 'date';
  readonly date = 'date';
  static readonly devisStatut = 'devisStatut';
  readonly devisStatut = 'devisStatut';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly duree = 'duree';
  readonly duree = 'duree';
  static readonly dureeMinute = 'dureeMinute';
  readonly dureeMinute = 'dureeMinute';
  static readonly dureeType = 'dureeType';
  readonly dureeType = 'dureeType';
  static readonly dureeValue = 'dureeValue';
  readonly dureeValue = 'dureeValue';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listContact = 'listContact';
  readonly listContact = 'listContact';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listEntite = 'listEntite';
  readonly listEntite = 'listEntite';
  static readonly listRappelAutomatique = 'listRappelAutomatique';
  readonly listRappelAutomatique = 'listRappelAutomatique';
  static readonly listTag = 'listTag';
  readonly listTag = 'listTag';
  static readonly montantXPF = 'montantXPF';
  readonly montantXPF = 'montantXPF';
  static readonly objet = 'objet';
  readonly objet = 'objet';
  static readonly prochaineEtape = 'prochaineEtape';
  readonly prochaineEtape = 'prochaineEtape';
  static readonly proposition = 'proposition';
  readonly proposition = 'proposition';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ActionClientGen_();
  }
}

