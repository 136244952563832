/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrFacturePojoGen { 
    clientId?: number;
    clientInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    date?: Date;
    entiteId?: number;
    entiteInstanceLabel?: string;
    exploitation?: boolean;
    id?: number;
    instanceLabel?: string;
    libelle?: string;
    montantApresRemise?: number;
    montantAvantRemise?: number;
    numero?: string;
    remise?: number;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrFacturePojoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly date = 'date';
  readonly date = 'date';
  static readonly entiteId = 'entiteId';
  readonly entiteId = 'entiteId';
  static readonly entiteInstanceLabel = 'entiteInstanceLabel';
  readonly entiteInstanceLabel = 'entiteInstanceLabel';
  static readonly exploitation = 'exploitation';
  readonly exploitation = 'exploitation';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly libelle = 'libelle';
  readonly libelle = 'libelle';
  static readonly montantApresRemise = 'montantApresRemise';
  readonly montantApresRemise = 'montantApresRemise';
  static readonly montantAvantRemise = 'montantAvantRemise';
  readonly montantAvantRemise = 'montantAvantRemise';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrFacturePojoGen_();
  }
}

