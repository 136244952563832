import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AsCoreTab } from '../ascore-tab/ascore-tab.model';

@Component({
  selector: 'ascore-navbar',
  templateUrl: './ascore-navbar.component.html',
  styleUrls: ['./ascore-navbar.component.scss']
})
export class AsCoreNavbarComponent {

  @ViewChild('test', {static: true}) test: ElementRef;

  active = 1;

  @Input()
  tabs: AsCoreTab[];

  @Input()
  form: FormGroup;

  constructor() {
  }

  getTitre(tab: AsCoreTab): string {
    const nombre = this.form?.get(tab.formControlName)?.value?.length;
    const infoNombre = nombre ? `(${nombre})` : '';
    return `${tab.titre} ${infoNombre}`;
  }

  isInvalid(index: number): boolean {
    const errors = document.getElementById('tab_' + index)?.querySelectorAll('.control-error');
    return errors?.length > 0;
  }
}
