<ascore-create-detail titre="Utilisateurs" [form]="form" [tabs]="tabs" [service]="service">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 p-0 d-inline-flex flex-wrap">
                <div class="col-md-4">
                    <input type="text"
                           [formControlName]="fields.username"
                    >
                </div>
                <div class="col-md-4">
                    <input type="text"
                           [formControlName]="fields.password"
                    >
                </div>
                <div class="col-md-4">
                </div>
                <div class="col-md-4">
                    <input type="checkbox"
                           [formControlName]="fields.actif"
                    >
                </div>
            </div>
        </div>
    </form>
</ascore-create-detail>
