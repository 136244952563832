/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrTagPojoGen { 
    color?: string;
    created?: Date;
    createdBy?: string;
    icone?: string;
    id?: number;
    instanceLabel?: string;
    libelle?: string;
    types?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrTagPojoGen_ {
  static readonly color = 'color';
  readonly color = 'color';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly icone = 'icone';
  readonly icone = 'icone';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly libelle = 'libelle';
  readonly libelle = 'libelle';
  static readonly types = 'types';
  readonly types = 'types';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrTagPojoGen_();
  }
}

