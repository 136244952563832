/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TagTypeGen = 'ACTION' | 'CLIENT' | 'CONTACT' | 'TOUS';

export const TagTypeGen = {
        ACTION: 'ACTION' as TagTypeGen,
        CLIENT: 'CLIENT' as TagTypeGen,
        CONTACT: 'CONTACT' as TagTypeGen,
        TOUS: 'TOUS' as TagTypeGen
        };

export class TagTypeGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TagTypeGen_();
}
}

