import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { isBlank } from '../../utils/string-util';
import { AsCoreCtrlOpts, AsCoreFormControl } from '../ascore-input/AsCoreFormControl';

@Component({
  selector: 'lisa-time-picker',
  templateUrl: './ascore-time-picker.component.html',
  styleUrls: ['./ascore-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AscoreTimePickerComponent implements OnInit {

  model: TimeStructure = {hour: 0, minute: 0};
  isInit = false;

  @Input()
  label: string = null;

  @Input()
  form: FormGroup;

  @Input()
  formControlName_: string;

  @Input()
  asMaterial = false;

  @Input()
  inline = false;

  // En px
  @Input() labelWidth;

  @Input()
  minHour = 0;

  @Input()
  maxHour = 24;

  @Input()
  hourStep = 1;

  @Input()
  minuteStep = 1;

  @Input()
  secondStep = 1;

  @Input()
  type: typeTime = 'TimeStructure';

  constructor() {
  }

  ngOnInit(): void {

    const control = this.getControl();

    const validators = new AsCoreCtrlOpts(control.listValidator).validators as ValidatorFn[];
    validators.push((ctrl: FormControl) => {
      const value = ctrl.value;

      if (!value) {
        return null;
      }

      if (value.hour < this.minHour) {
        return {tooEarly: true};
      }
      if (value.hour > this.maxHour) {
        return {tooLate: true};
      }

      return null;
    });
    control.setValidators(validators);

    this.toStructure();

    control.valueChanges.subscribe(change => {
      this.toStructure();
    });
  }

  getLabel() {
    return !isBlank(this.label) ? this.label : this.getControl().getLabel();
  }

  getControl(): AsCoreFormControl {
    return this.form.get(this.formControlName_) as AsCoreFormControl;
  }

  getWidth(): string {
    return this.inline && this.labelWidth ? 'width:' + this.labelWidth + 'px' : '';
  }

  toStructure() {

    if (this.isInit) {
      return;
    }

    const value = this.getControl().value;

    if (value) {
      this.isInit = true;
    }

    if (this.type === 'TimeStructure') {
      this.model = value;
    } else if (value instanceof Date) {
      this.model = {hour: value.getHours(), minute: value.getMinutes(), second: value.getSeconds()};
    } else if (typeof value === 'string') {
      this.model = {
        hour: +value.split(':')[0],
        minute: +value.split(':')[1],
        second: +value.split(':')[2]
      };
    }
  }

  fromStructure(value: any) {
    this.model = value;
    if (this.type === 'TimeStructure') {
      this.getControl().setValue(value);
    } else {
      this.getControl().setValue(`${String(this.model.hour).padStart(2, '0')}` +
        `:${String(this.model.minute).padStart(2, '0')}` +
        `:${String(this.model.second).padStart(2, '0')}`);
    }
  }
}

export type typeTime = 'TimeStructure' | 'LocalTime';

export interface TimeStructure {
  hour: number;
  minute: number;
  second?: number;
}
