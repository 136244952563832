import { IdInstanceLabel } from './id-instance-label';

export abstract class AsCoreBaseDomain extends IdInstanceLabel {
  /**
   * L'utilisation de "declare" permet de ne pas transpiler cette ligne en js.
   * Elle est seulement utilisée par le compilateur TS pour typer en "number".
   * Autrement, on aurait une erreur (depuis ES2022) car on override la propriété id du parent.
   * (cf https://stackoverflow.com/a/70250602)
   */
  declare id?: number;
  createdBy: string;
  created: Date;
  updatedBy?: string;
  updated?: Date;
}
