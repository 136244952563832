/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ClientStatistiqueTypeGen = 'PERDU' | 'CONSERVE' | 'NOUVEAU';

export const ClientStatistiqueTypeGen = {
        PERDU: 'PERDU' as ClientStatistiqueTypeGen,
        CONSERVE: 'CONSERVE' as ClientStatistiqueTypeGen,
        NOUVEAU: 'NOUVEAU' as ClientStatistiqueTypeGen
        };

export class ClientStatistiqueTypeGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new ClientStatistiqueTypeGen_();
}
}

