import { Component, Input, OnInit } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';
import { DocumentGen, DocumentServiceGen } from "../../../../shared/generated";
import {
    AbstractCompositionDetailComponent
} from "../../../../shared/ascore/component/composition/abstract-composition-detail.component";
import { MessageService } from "../../../../shared/ascore/service/message.service";
import { AsCoreFormControl } from "../../../../shared/ascore/component/ascore-input/AsCoreFormControl";
import { checkFormForSubmit } from "../../../../shared/ascore/component/ascore-input/form-submit.directive";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent extends AbstractCompositionDetailComponent<DocumentGen> implements OnInit {

    @Input() titre = 'Ajout de documents';
    @Input() maxSize ? = 1000 * 1_048_576; // TODO : fixer taille raisonnable
    @Input() documentSizeTooLargeErrorMsg ? = 'Certains fichiers trop volumineux n\'ont pas été ajoutés';
    @Input() evenement = '';

    constructor(private messageService: MessageService,
                public modal: NgbActiveModal,
                private documentJointService: DocumentServiceGen,
                private fb: FormBuilder,
                public httpClient: HttpClient,) {
        super();
    }

    public dropped(listDroppedEntry: NgxFileDropEntry[]) {
        let documentSizeTooLarge = false;
        for (const droppedEntry of listDroppedEntry) {
            if (droppedEntry.fileEntry.isFile) {
                const fileEntry = droppedEntry.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (file.size <= this.maxSize) {
                        this.addDocument(file);
                    } else {
                        documentSizeTooLarge = true;
                    }
                });
            }
        }

        if (documentSizeTooLarge) {
            this.messageService.showWarning(this.documentSizeTooLargeErrorMsg);
        }
    }

    private addDocument(file: File) {
        this.getFormArray().push(this.documentForm(file));
    }

    private documentForm(file: File): FormGroup {
        return this.fb.group({
            file: AsCoreFormControl.init(file),
            commentaire: AsCoreFormControl.init().noLabel(),
            evenement: AsCoreFormControl.init(this.evenement)
        });
    }

    removeDocument(i: number) {
        this.getFormArray().removeAt(i);
    }

    getFormArray(): FormArray {
        return this.form.get('formArray') as FormArray;
    }

    createForm(): void {
        this.form = this.fb.group({
            formArray: this.fb.array([])
        });
    }

    uploadAllDocument() {

        if (this.form.invalid) {
            this.getFormArray().controls.forEach((formGroup: FormGroup) => {
                checkFormForSubmit(formGroup, this.messageService);
            });
            return;
        }

        const formData: any = new FormData();
        const listDocumentJoint = [];
        this.getFormArray().controls.forEach(formGroup => {
            const file = formGroup.get('file').value as File;
            formData.append('content', file);

            const documentJoint: any = {};
            documentJoint.contentType = file.type;
            documentJoint.libelle = file.name;
            documentJoint.commentaire = !isNil(formGroup.get('commentaire').value) ? formGroup.get('commentaire').value : '';
            this.updateDocumentLienAvecParent(documentJoint, this.parent?.id?.toString());

            listDocumentJoint.push(documentJoint);
        });
        formData.append('listDocument',
            new Blob([JSON.stringify(listDocumentJoint)],
                {
                    type: 'application/json'
                }
            ));
        this.documentJointService.upload([formData.get('content')], [formData.get('listDocument')]).subscribe(result => {
            this.messageService.showSuccess(`Document(s) ajouté(s)`);
            this.modal.close(result);
        });
    }

    /**
     * méthode qu'on peut redéfinir
     */
    protected updateDocumentLienAvecParent(documentJoint: any, parentId: string) {
        documentJoint.clientId = parentId;
    }
}
