/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditChangeGen { 
    description?: string;
    entityId?: number;
    entityInstanceLabel?: string;
    entityType?: string;
    instanceLabel?: string;
}

export class AuditChangeGen_ {
  static readonly description = 'description';
  readonly description = 'description';
  static readonly entityId = 'entityId';
  readonly entityId = 'entityId';
  static readonly entityInstanceLabel = 'entityInstanceLabel';
  readonly entityInstanceLabel = 'entityInstanceLabel';
  static readonly entityType = 'entityType';
  readonly entityType = 'entityType';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static fields() {
    return new AuditChangeGen_();
  }
}

