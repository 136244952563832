import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { MessageService } from '../ascore/service/message.service';
import { UtilisateurCourantService } from '../../administration/referentiel/utilisateur-courant.service';

@Injectable()
export class AutorisationGuard implements CanActivate {

  constructor(private utilisateurCourantService: UtilisateurCourantService,
              private router: Router,
              private messageService: MessageService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const utilisateur = this.utilisateurCourantService.getUtilisateurCourant();
    const autorisation = route.data.autorisation;
    //TODO config
    return true
    //Exemple config autorisation
    // switch (autorisation) {
    //   case AutorisationEnum.BRIA :
    //     if (utilisateur.isAdministrateurBria() || utilisateur.isSuperviseurBria() || utilisateur.isAdministrateurIa()) return true;
    //     break
    // }
    this.messageService.showError('Vous n\'avez pas les droits pour accéder à cette ressource !');
    this.router.navigate(['/']);
    return false;
  }

}
