<div class="container"
     *ngIf="this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'; else mainHeader">
    <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>

    <a href="https://gcf.nc" alt="AgileSoft.NC">
        <img [style.max-height]="'70px'" class="collapse navbar-collapse logo-navbar-collapse" [style.display]="this.collapseStyle"
             alt="Logo"
             src="assets/images/logo-GCF.png">
    </a>
    <div class="m-header">
        <a *ngIf="this.windowWidth < 992" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>

        <a [routerLink]="['/']" class="b-brand">
            <img [style.max-height]="'70px'" src="assets/images/boost.png" alt="Logo">
        </a>
    </div>
    <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
        <!--    <app-nav-left class="mr-auto"></app-nav-left>-->
        <app-nav-right class="ml-auto"></app-nav-right>
    </div>

    <!--  -->
    <!--  <a [routerLink]="['/']">-->
    <!--    <img class="collapse navbar-collapse logo-navbar-collapse ml-2 mr-2" [style.display]="this.collapseStyle" alt="Logo"-->
    <!--         src="assets/images/logo_small.png">-->
    <!--  </a>-->

    <!--  <a [routerLink]="['/']" class="b-brand">-->
    <!--    <h1 class="tw-text-2xl tw-font-bold tw-pt-1">Hermès</h1>-->
    <!--  </a>-->

    <!--  <li class="tw-mt-2 tw-ml-3">-->
    <!--    <span class="tw-text-gray-300">v{{frontendVersion}} / {{backendVersion}}</span>-->
    <!--  </li>-->

    <!--  <span *ngIf="devMode" class="tw-text-red-500 tw-mt-2 tw-ml-5">-->
    <!--    <i class="fas fa-exclamation-triangle"></i>Serveur de test-->
    <!--  </span>-->

    <!--  <div class="m-header">-->
    <!--    <a *ngIf="this.windowWidth < 992" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>-->
    <!--  </div>-->
    <!--  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">-->
    <!--    <app-nav-right class="ml-auto"></app-nav-right>-->
    <!--  </div>-->
</ng-template>
