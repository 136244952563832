import { FormGroup } from '@angular/forms';
import { Injectable, Input, OnInit } from '@angular/core';
import { AsCoreBaseDomain } from '../../models/ascore-base-domain';
import { IdInstanceLabel } from '../../models/id-instance-label';


@Injectable()
export abstract class AbstractCompositionDetailComponent<T extends IdInstanceLabel> implements OnInit {

  public form: FormGroup;

  @Input()
  titre: string;

  @Input()
  entity: T;

  @Input()
  parent: AsCoreBaseDomain;

  @Input()
  readonly = false;

  // AbstractCompositionDetailComponent ne déclenche pas le ngOnInit() mais les classes enfants oui.
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    this.createForm();
  }

  abstract createForm(): void;
}
