import { Component } from '@angular/core';
import { NextConfig } from '../../../../../app-config';
import { UtilisateurCourantService } from '../../../../../administration/referentiel/utilisateur-courant.service';
import { UtilisateurGen } from '../../../../../shared/generated';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent {
  public flatConfig: any;

  utilisateurCourant: UtilisateurGen

  constructor(public utilisateurCourantService: UtilisateurCourantService) {
    this.flatConfig = NextConfig.config;
    this.utilisateurCourant = utilisateurCourantService.getUtilisateurCourant()
  }
}
