import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './as-core-map.component.html',
  styleUrls: ['./as-core-map.component.scss']
})
export class AsCoreMapComponent implements OnInit {
  @Input()
  listLayers: L.Layer[];

  @Input()
  zoom = 12;

  @Input()
  center = L.latLng(-22.27631, 166.4572); // Nouméa

  @Input()
  marker: L.Marker;

  mapOptions: L.MapOptions;
  map: L.Map;

  @Output()
  onMapClicked = new EventEmitter<L.Marker>();

  constructor() {
  }

  ngOnInit(): void {
    this.initializeMapOptions();
  }

  private initializeMapOptions(): void {
    this.mapOptions = {
      layers: this.getLayers(),
      zoom: this.zoom,
      center: this.center
    };
  }

  onMapReady(map: L.Map): void {
    this.map = map;
  }

  getLayers = (): L.Layer[] => {
    return [
      new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      } as L.TileLayerOptions)
    ] as L.Layer[];
  }

  onMapClick(leafletMouseEvent): void {
    const latlng = leafletMouseEvent.latlng;
    this.replaceMarker(latlng.lat, latlng.lng);
    this.onMapClicked.emit(this.marker);
  }

  public getMarkerFromLatitudeLongitude(lat, lng): L.Marker {
    return new L.Marker([lat, lng]);
  }

  // Example de technique sur l'overload de methode en Angular
  public replaceMarker(marker: L.Marker): void;
  public replaceMarker(latitude: number, longitude: number): void;

  replaceMarker(markerOrLatitude: any, longitude?: number): void {
    this.removeOldMarker();
    if (markerOrLatitude && typeof markerOrLatitude === 'number') {
      markerOrLatitude = this.getMarkerFromLatitudeLongitude(markerOrLatitude, longitude);
    }
    this.setMarker(markerOrLatitude);
  }

  removeOldMarker(): void {
    if (this.marker != null) {
      this.map.removeLayer(this.marker);
    }
  }

  setMarker(marker): void {
    this.marker = new L.Marker([marker.getLatLng().lat, marker.getLatLng().lng])
      .setIcon(
        L.icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'assets/marker-icon.png'
        }));
    this.marker.addTo(this.map);
  }

  panTo(latLng: L.LatLng): void {
    this.map.panTo(latLng);
  }

}
