import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

export interface DisplayState {
  sidebarToggled: boolean;
  displaySpinner: boolean;
}

const initialState: DisplayState = {
  sidebarToggled: false,
  displaySpinner: false
};

@Injectable({
  providedIn: 'root'
})
export class DisplaySettingsModel {

  private displaySettings = new Subject<DisplayState>();
  public displaySettingsObservable = this.displaySettings.asObservable();

  public sidebarToggleObservable: Observable<boolean>;
  public displaySpinnerObservable: Observable<boolean>;

  constructor() {
    this.sidebarToggleObservable = this.displaySettingsObservable.pipe(map((state: DisplayState) => state.sidebarToggled), distinctUntilChanged());
    this.displaySpinnerObservable = this.displaySettingsObservable.pipe(map((state: DisplayState) => state.displaySpinner), distinctUntilChanged());
  }

  /**
   *   Toggle Navbar
   */
  toggleSidebar(): void {
    this.displaySettingsObservable.pipe(first()).subscribe(settings => {
      settings.sidebarToggled = !settings.sidebarToggled;
      this.displaySettings.next(settings);
    });
  }

  /**
   * Display spinner
   */
  displaySpinner(spinner: boolean): void {
    this.displaySettingsObservable.pipe(first()).subscribe(settings => {
      settings.displaySpinner = spinner;
      this.displaySettings.next(settings);
    });
  }
}
