<div class="w-100" [class.invalid]="!getControl().valid" style="margin-top: 2px">
    <label class="label-lisa mt-auto mb-auto mr-2" *ngIf="getLabel() && !asMaterial"
           [class.inline]="inline"
           [style]="getWidth()">{{getLabel()}}</label>
    <ngb-timepicker [ngModel]="model"
                    (ngModelChange)="fromStructure($event)"
                    [hourStep]="hourStep"
                    [minuteStep]="minuteStep"
                    [secondStep]="secondStep">
    </ngb-timepicker>

    <div *ngIf="getControl().errors" class="small form-text text-danger">
        <div *ngIf="getControl().errors['required']">Champ obligatoire</div>
        <div *ngIf="getControl().errors['tooLate']">Horaire trop tardif</div>
        <div *ngIf="getControl().errors['tooEarly']">Horaire trop tôt</div>
    </div>
</div>
