import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = [value.substring(0, 2), value.substring(2, 4), value.substring(4, 6)]
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: 2000 + parseInt(date[0], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? '' + pad(date.year - 2000) + pad(date.month) + pad(date.day) : '';
  }
}

function pad(n): string {
  return n < 10 ? '0' + n : n;
}
