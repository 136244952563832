import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../service/message.service';
import { DisplaySettingsModel } from './display-settings.service';
import { Router } from '@angular/router';
import { isBlank } from '../utils/string-util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsCoreConflictModalComponent } from '../component/ascore-conflict-modal/ascore-conflict-modal.component';
import { AsCoreConfirmModalComponent } from '../component/ascore-confirm-modal/ascore-confirm-modal.component';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private messageService: MessageService,
                private router: Router,
                private displaySettingsModel: DisplaySettingsModel,
                private modalService: NgbModal,
                private httpService: HttpClient) {
    }

    private static getMessagePertinent(error: HttpErrorResponse): any {
        if (error.status === HttpStatusCode.Forbidden && error.url.endsWith('/api/login')) {
            return 'Erreur sur identifiant ou mot de passe';
        }

        const list: string[] =
            [error.error?.cause?.mostSpecificCause?.message,
                error.error?.message,
                error.error,
                error.message,
                'Erreur technique'];

        return list.find(txt => !isBlank(txt));
    }

    /**
     * Interceptor
     * @return Observable<HttpEvent<any>>
     * @param request HttpRequest<any>
     * @param next HttpHandler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.displaySettingsModel.displaySpinner(true);
        const authorization = localStorage.getItem('jwt_token') ? localStorage.getItem('jwt_token') : '';
        request = request.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                authorization
            }
        });

        // Gestion de l'affichage des erreurs dans le front
        const showErrorHeader = request.headers.get('show-error');
        const showError = showErrorHeader == null || showErrorHeader !== 'false';

        return next.handle(request) // add error handling
            .pipe(catchError(
                (error: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => this.handleError(error, showError, request)
            ));
    }

    handleError(error: HttpErrorResponse, showError: boolean, request: HttpRequest<any>): any {
        // On enlève le spinner pour ne pas qu'il spin à l'infini
        this.displaySettingsModel.displaySpinner(false);

        // cas spécifique :
        if (error?.status?.toString()?.startsWith('4')) {
            return this.handleError4xx(showError, error, request);
        }

        if (error?.status === undefined) {
            this.messageService.showError(error.message);
        } else if (error.status !== 0) {
            if (showError) {
                this.messageService.showError('Une erreur technique est survenue');
            }
            console.error(error);
        }
        return throwError(error);
    }

    private handleError4xx(showError: boolean, error: HttpErrorResponse, request: HttpRequest<any>): any {
        const errorMessage = HttpInterceptorService.getMessagePertinent(error);

        if (error.status === HttpStatusCode.Unauthorized) {
            this.router.navigate(['/auth/connexion']);
            return;
        }

        if (error.status === HttpStatusCode.Conflict) {
            this.resolveConflict(error, request);
            return;
        }

        if (showError) {
            if (errorMessage instanceof Blob) {
                const reader = new FileReader();
                // tslint:disable-next-line:only-arrow-functions
                reader.onload = (function (messageService): any {
                    return function (): void {
                        messageService.showError(this.result);
                    };
                })(this.messageService);
                reader.readAsText(errorMessage);
            } else {
                this.messageService.showError(errorMessage);
            }
        }

        return throwError(error);
    }

    private resolveConflict(error: HttpErrorResponse, request: HttpRequest<any>): void {
        const modalConflict = this.modalService.open(AsCoreConflictModalComponent, { backdrop: 'static', size: 'xxl' });
        modalConflict.componentInstance.updatedVersion = error.error?.updatedDatabaseVersion;
        modalConflict.componentInstance.localVersion = error.error?.localVersion;
        modalConflict.componentInstance.mapPropertyToLibelle = error.error?.mapPropertyToLibelle;
        modalConflict.componentInstance.request = request;
        modalConflict.result.then((result) => {
                if (result) {
                    const modalConfirm = this.modalService.open(AsCoreConfirmModalComponent, { backdrop: 'static' });
                    modalConfirm.componentInstance.messageImportant = 'Confirmez-vous la sauvegarde de la nouvelle version ?';
                    modalConfirm.result.then(() => {
                        // On modifie le contenu de la requête et on l'exécute à nouveau.
                        result.request.body = result.form;
                        this.httpService.request(result.request).subscribe(() => window.location.reload());
                    }, (error) => {
                        // L'utilisateur a dismiss la modal de confirmation, on ne fait rien
                    });
                } else {
                    // Pas de résultat, l'utilisateur a décidé de recharger ses données et d'abandonner ses modifications
                    window.location.reload();
                }
            }, (error) => {
                // L'utilisateur a dismiss la modal, on ne fait rien
            }
        );
    }
}
