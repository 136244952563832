import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from '../ascore/service/message.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private messageService: MessageService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (localStorage.getItem('utilisateur')) {
            return true
        }
        sessionStorage.setItem('routeBeforeLogin', state.url);
        this.router.navigate(['/auth/connexion'])
        return false
    }

}
