<ascore-search #auditAsCoreSearch
               typeDetail="viewable"
               [columns]="columns"
               [withSearch]="auditCommitService"
               [searchForm]="searchForm"
               [hideSearchForm]="false"
               [customOpen]="true"
               [closable]="false">

    <!-- customOpen = true pour éviter de planter quand on double-clique sur une ligne -->

    <div [formGroup]="searchForm" class="row">
        <ascore-select
                [form]="searchForm"
                formControlName_="author"
                [withSearch]="auditAuthorService"
                searchFieldName="recherche"
                showFieldName="instanceLabel"
                class="pl-5"
                [asMaterial]="true"
                (selectEvent)="auditAsCoreSearch.triggerFilter()"
                [minChar]="0">
        </ascore-select>

        <input type="date"
               [asMaterial]="true"
               formControlName="dateMin">

        <input type="date"
               [asMaterial]="true"
               formControlName="dateMax">
    </div>

</ascore-search>

<ng-template #listAuditChangeTemplateWithEntityDetail let-value="value">
    <table class="table p-0 m-0">
        <tbody>
        <tr *ngFor=" let element of value; let index=index;">
            <td style="width: 300px" class="sans-bord">
                <!-- astuce pour éviter de répéter les mêmes valeurs d'une ligne à l'autre : -->
                <span class="text-red-600 font-weight-bolder">
        {{index >= 1 && value[index - 1].entityType === value[index].entityType ? '' : value[index].entityType}}<br/>
        </span>
                {{index >= 1 && value[index - 1].entityInstanceLabel === value[index].entityInstanceLabel ? '' : value[index].entityInstanceLabel}}
            </td>
            <td class="sans-bord">
<pre>
{{element.description}}
</pre>
            </td>
        </tr>
        </tbody>
    </table>
</ng-template>
