<div class="h-100" [class.card]="header" [@cardRemove]="cardRemove" #toAnimate>
    <div class="card-header" *ngIf="header" [ngClass]="headerClass" [class.bg-light]="light">
        <h4 *ngIf="!customHeader && !customTitle">{{ title }}</h4>
        <div *ngIf="customTitle">
            <ng-container
                    *ngTemplateOutlet="customTitle">
            </ng-container>
        </div>
        <div *ngIf="status" class="m-1 badge badge-pill badge-warning">{{status}}</div>
        <p *ngIf="!customHeader && cardCaption" [ngClass]="captionClass">{{cardCaption}}</p>
        <div class="card-header-right">
            <h5 *ngIf="history && (!customTitle || forceShowHistory)" class="historique m-2">
                {{history}}
            </h5>
            <button *ngIf="closable" type="button" class="btn dropdown-toggle btn-icon" (click)="close()">
                <i class="feather icon-x"></i>
            </button>
        </div>
        <ng-content *ngTemplateOutlet="customHeader" select=".app-card-header">
        </ng-content>
    </div>
    <div [@collapsedCard]="collapsedCard" *ngIf="this.options; else subMenuContent">
        <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </div>
    <ng-template #subMenuContent class="h-100">
        <div class="card-body" [ngClass]="blockClass" [class.bg-light]="light">
            <ng-content></ng-content>
        </div>
    </ng-template>
    <div *ngIf="isCardFooter" class="card-footer" [ngClass]="footerClass">
        <ng-content select=".app-card-footer"></ng-content>
    </div>
    <div class="card-loader" *ngIf="loadCard"><i class="pct-loader1 anim-rotate"></i></div>
</div>
