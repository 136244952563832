import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigDtoGen, AppConfigServiceGen } from '../generated';
import { isNil } from 'lodash';
import { MessageService } from './service/message.service';
import { UtilisateurCourantService } from '../../administration/referentiel/utilisateur-courant.service';

/**
 * Service injecté au début de l'application pour récuperer des éléments de configuration
 */
@Injectable({providedIn: 'root'})
export class AppConfigService {

  appConfig: AppConfigDtoGen;

  constructor(protected http: HttpClient,
              public messageService: MessageService,
              public utilisateurCourantService: UtilisateurCourantService,
              public appConfigServiceGen: AppConfigServiceGen) {
  }

  loadConf(): void {
    this.appConfigServiceGen.getConfig()
      .subscribe(value => {
        this.appConfig = value;
      });
  }

  checkConfigChargee(): void {
    if (isNil(this.appConfig)) {
      this.messageService.showError('Un souci technique a été rencontré ' +
        '(configuration applicative non chargée). Merci de vous reconnecter');
      this.utilisateurCourantService.logout();
    }
  }
}
