import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from '../../../shared/ascore/service/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AsCoreFormControl } from '../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import { UtilisateurCourantService } from '../../../administration/referentiel/utilisateur-courant.service';
import { ChangePasswordDtoGen_, PasswordServiceGen } from '../../../shared/generated';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  faTimes = faTimes;
  public form: FormGroup;
  fields = ChangePasswordDtoGen_.fields();

  @Input()
  utilisateurId: number;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder,
              private msgService: MessageService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private utilisateurCourantService: UtilisateurCourantService,
              private passwordService: PasswordServiceGen) {
  }

  ngOnInit() {
    this.createForm()
  }

  private createForm() {
    this.form = this.fb.group({
      [this.fields.oldPassword]: AsCoreFormControl.init().required().label('Ancien mot de passe'),
      [this.fields.newPassword]: AsCoreFormControl.init().required().label('Nouveau mot de passe'),
      ['confirmNewPassword']: AsCoreFormControl.init().required().label('Confirmer le nouveau mot de passe')
    })
  }

  onSubmit(): void {
    if (this.form.get(this.fields.newPassword).value !== this.form.get('confirmNewPassword').value) {
      this.msgService.showError('Le nouveau mot de passe est différent du mot de passe de confirmation')
      return;
    }

    this.passwordService.changePassword(this.utilisateurId, this.form.getRawValue())
      .pipe(untilDestroyed(this)).subscribe(() => {
      this.msgService.showSuccess('Le mot de passe a été modifié')
      this.modal.close();
    });
  }

}

