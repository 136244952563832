/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactGen } from './contact.gen.model';
import { TagGen } from './tag.gen.model';


export interface ContactTagGen { 
    contact?: ContactGen;
    createdBy?: string;
    id?: number;
    instanceLabel?: string;
    tag?: TagGen;
}

export class ContactTagGen_ {
  static readonly contact = 'contact';
  readonly contact = 'contact';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly tag = 'tag';
  readonly tag = 'tag';
  static fields() {
    return new ContactTagGen_();
  }
}

