<div class="modal-header">
    <h6 class="modal-title">Changement de mot de passe</h6>
    <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>
<div class="modal-body" [formGroup]="form">
    <form [formGroup]="form" autocomplete="off">
        <input type="password"
               [formControlName]="fields.oldPassword">
        <input type="password"
               [formControlName]="fields.newPassword">
        <input type="password"
               formControlName="confirmNewPassword">
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.dismiss()">Annuler
    </button>
    <button type="button" class="btn btn-primary"
            [disabled]="this.form.invalid"
            (keydown.enter)="onSubmit()"
            (click)="onSubmit()">
        OK
    </button>
</div>
