/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChangePasswordDtoGen { 
    newPassword?: string;
    oldPassword?: string;
}

export class ChangePasswordDtoGen_ {
  static readonly newPassword = 'newPassword';
  readonly newPassword = 'newPassword';
  static readonly oldPassword = 'oldPassword';
  readonly oldPassword = 'oldPassword';
  static fields() {
    return new ChangePasswordDtoGen_();
  }
}

