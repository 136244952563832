import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { isNil } from 'lodash';
import {
  email,
  maxDate,
  minDate,
  noFuture,
  noPast,
  range,
  requiredIfEmpty,
  requiredIfNotBrouillon
} from './AsCoreValidators';
import { Subject } from 'rxjs';
import { capitalize, decamelize, isBlank } from '../../utils/string-util';

/**
 * Permet de récupérer les validateurs appliqués à un formGroup.
 *
 * Utiliser les builder init ou with pour initialiser la classe.
 */
export class AsCoreFormControl extends FormControl {

  /**
   * Si brouillon is true alors les champs marqués 'required' ne sont pas bloquants pour l'envoi au back-end (le formControl reste valid),
   * l'astérisque est cependant toujours présente
   */
  public brouillon = false;
  public defaultValue: any = null;
  private asCoreLabel = new AsCoreFormLabel();
  public containerDivCustomClass = '';

  public listValidator: AsCoreValidator[] = [];

  listValidatorSubject = new Subject<any>();
  public listValidatorObservable = this.listValidatorSubject.asObservable();

  asCoreLabelSubject = new Subject<any>();
  public asCoreLabelObservable = this.asCoreLabelSubject.asObservable();

  asCoreCustomClassSubject = new Subject<any>();
  public asCoreCustomClassObservable = this.asCoreCustomClassSubject.asObservable();

  private constructor(formState?: any,
                      validatorOrOpts?: ValidatorFn | ValidatorFn[] | AsCoreCtrlOpts | null,
                      asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {

    super(formState, validatorOrOpts as ValidatorFn[], asyncValidator);

    if (validatorOrOpts instanceof AsCoreCtrlOpts) {
      this.listValidator = validatorOrOpts.asCoreConf;
    }
  }

  // Builder avec une valeur par defaut (null par defaut)
  public static init(formState?: any): AsCoreFormControl {
    const formControl = new AsCoreFormControl(isNil(formState) ? null : formState);
    formControl.defaultValue = formState;
    formControl.asCoreLabel = new AsCoreFormLabel();
    return formControl;
  }

  // Chainage des validators
  public required(): AsCoreFormControl {
    return this.and('required');
  }

  public email(): AsCoreFormControl {
    return this.and('email');
  }

  public noFuture(): AsCoreFormControl {
    return this.and('noFuture');
  }

  public noPast(): AsCoreFormControl {
    return this.and('noPast');
  }

  public positive(): AsCoreFormControl {
    return this.min(0);
  }

  public min(min: any): AsCoreFormControl {
    return this.and('min', min);
  }

  public max(max: number): AsCoreFormControl {
    return this.and('max', max);
  }

  public range(min: any, max: number): AsCoreFormControl {
    return this.and('range', {min, max});
  }

  public minlength(min: any): AsCoreFormControl {
    return this.and('minlength', min);
  }

  public maxlength(max: number): AsCoreFormControl {
    return this.and('maxlength', max);
  }

  public pattern(pattern: string): AsCoreFormControl {
    return this.and('pattern', pattern);
  }

  public minDate(min: any): AsCoreFormControl {
    return this.and('minDate', min);
  }

  public maxDate(max: number): AsCoreFormControl {
    return this.and('maxDate', max);
  }

  public requiredIfEmpty(property: string): AsCoreFormControl {
    return this.and('requiredIfEmpty', property);
  }

  private and(key: TypeAsCoreValidator, value?: any): AsCoreFormControl {

    // on supprime l'entrée si elle existe deja
    const previousIndex = this.listValidator.findIndex(it => it.key === key);
    if (previousIndex >= 0) {
      this.listValidator.splice(previousIndex, 1);
    }

    if (key === 'min' || key === 'max' || key === 'range') {
      // Traitement spécial pour conserver de la cohérence entre min, max et range
      this.addValidatorMinMaxRange(key, value);
    } else {
      this.addValidator(key, value);
    }

    // pour les observables
    this.listValidatorSubject.next(this.listValidator);

    return this;
  }


  private addValidator(key: TypeAsCoreValidator, value?: any): void {
    this.listValidator.push({key, value});
    this.setValidators(new AsCoreCtrlOpts(this.listValidator).validators as ValidatorFn[]);
  }

  /**
   * Gestion du lien entre range, min et max lors de l'ajout d'un validateur
   */
  private addValidatorMinMaxRange(key: TypeAsCoreValidator, value?: any): void {
    if (key === 'range') {
      this.addValidatorRange(value);
    }
    if (key === 'min') {
      this.addValidatorMin(value);
    }
    if (key === 'max') {
      this.addValidatorMax(value);
    }
  }

  private addValidatorMax(value: any): void {
    const minValidatorIndex = this.listValidator.findIndex(it => it.key === 'min');
    const rangeValidator = this.listValidator.find(it => it.key === 'range');
    if (minValidatorIndex >= 0) {
      const minValidator = this.listValidator[minValidatorIndex];
      // Si on a deja un max, alors on le supprime et on ajoute un 'range'
      this.listValidator.splice(minValidatorIndex, 1);
      this.range(minValidator.value, value);
    } else if (rangeValidator) {
      // Si on a deja un range, alors on le met à jour
      this.range(rangeValidator.value.min, value);
    } else {
      this.addValidator('max', value);
    }
  }

  private addValidatorMin(value: any): void {
    const maxValidatorIndex = this.listValidator.findIndex(it => it.key === 'max');
    const rangeValidator = this.listValidator.find(it => it.key === 'range');
    if (maxValidatorIndex >= 0) {
      const maxValidator = this.listValidator[maxValidatorIndex];
      // Si on a deja un max, alors on le supprime et on ajoute un 'range'
      this.listValidator.splice(maxValidatorIndex, 1);
      this.range(value, maxValidator.value);
    } else if (rangeValidator) {
      // Si on a deja un range, alors on le met à jour
      this.range(value, rangeValidator.value.max);
    } else {
      this.addValidator('min', value);
    }
  }

  private addValidatorRange(value?: any): void {
    // On supprime les validateurs min et max qui n'ont plus de sens si on a un range
    const minValidatorIndex = this.listValidator.findIndex(it => it.key === 'min');
    if (minValidatorIndex >= 0) {
      this.listValidator.splice(minValidatorIndex, 1);
    }
    const maxValidatorIndex = this.listValidator.findIndex(it => it.key === 'max');
    if (maxValidatorIndex >= 0) {
      this.listValidator.splice(maxValidatorIndex, 1);
    }

    this.addValidator('range', value);
  }

  /**
   * Gestion du lien entre range, min et max lors de la suppression d'un validateur
   */
  private removeValidatorMinMaxRange(key: TypeAsCoreValidator, value?: any): void {
    if (key === 'min') {
      this.removeValidatorMin(key);
    }
    if (key === 'max') {
      this.removeValidatorMax(key);
    }
  }

  private removeValidatorMax(value: any): void {
    const rangeValidatorIndex = this.listValidator.findIndex(it => it.key === 'range');
    const rangeValidator = this.listValidator.find(it => it.key === 'range');
    if (rangeValidatorIndex >= 0) {
      this.not('range');
      this.min(rangeValidator.value.min);
    }
  }

  private removeValidatorMin(value: any): void {
    const rangeValidatorIndex = this.listValidator.findIndex(it => it.key === 'range');
    const rangeValidator = this.listValidator.find(it => it.key === 'range');
    if (rangeValidatorIndex >= 0) {
      this.not('range');
      this.max(rangeValidator.value.max);
    }
  }

  public getValidator(key: string): AsCoreValidator {
    return this.listValidator.find(it => it.key === key);
  }

  not(key: TypeAsCoreValidator): AsCoreFormControl {
    this.listValidator = this.listValidator.filter(validator => validator.key !== key);
    this.setValidators(new AsCoreCtrlOpts(this.listValidator).validators as ValidatorFn[]);

    if (key === 'min' || key === 'max') {
      this.removeValidatorMinMaxRange(key);
    }

    // pour les observables
    this.listValidatorSubject.next(this.listValidator);

    return this;
  }

  reset(formState?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    if (!isNil(formState)) {
      super.reset(formState, options);
    } else {
      super.reset(this.defaultValue);
    }
  }

  public hasValidator(validator: TypeAsCoreValidator | ValidatorFn): boolean {
    return this.listValidator?.find(it => it.key === validator) != null;
  }

  /**
   * Surcharge pour pouvoir cabler le disable dans le chainage
   */
  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }): AsCoreFormControl {
    super.disable(opts);
    return this;
  }

  noLabel(): AsCoreFormControl {
    this.asCoreLabel.hideLabel = true;

    // pour les observables
    this.asCoreLabelSubject.next(this.asCoreLabel);
    return this;
  }

  upperLabel(): AsCoreFormControl {
    this.asCoreLabel.upperLabel = true;
    this.asCoreLabelSubject.next(this.asCoreLabel);
    return this;
  }

  label(label: string): AsCoreFormControl {

    if (isNil(label)) {
      return this.noLabel();
    }

    this.asCoreLabel.hideLabel = false;
    this.asCoreLabel.label = label;
    this.asCoreLabelSubject.next(this.asCoreLabel);
    return this;
  }

  customClass(customClass: string): AsCoreFormControl {
    this.containerDivCustomClass = customClass;
    // pour les observables
    this.asCoreCustomClassSubject.next(this.containerDivCustomClass);
    return this;
  }

  getName(): string {
    const parent = this.parent;

    if (!parent) {
      return null;
    }

    let name = null;
    Object.keys(parent.controls).forEach((key, index) => {
        // @ts-ignore
        if (parent.get(key) === this) {
          name = key;
          return false;
        }
        return undefined;
      }
    );
    return name;
  }

  getLabel(): string {

    if (this.asCoreLabel.hideLabel) {
      return null;
    }

    if (isBlank(this.asCoreLabel.label)) {
      this.asCoreLabel.label = capitalize(decamelize(this.getName(), ' '));
      if (this.asCoreLabel.upperLabel) {
        this.asCoreLabel.label = this.asCoreLabel.label.toUpperCase();
      }
    }

    return this.asCoreLabel.label;
  }

}

/**
 * Liste des validateurs disponibles : attention min, max et range sont liés
 */
export type TypeAsCoreValidator = 'min' | 'max' | 'range' | 'required' | 'email' | 'noFuture' | 'noPast' | 'pattern'
  | 'minlength' | 'maxlength' | 'minDate' | 'maxDate' | 'requiredIfEmpty';

export interface HandledValidator {
  key: TypeAsCoreValidator;
  function: (arg: any) => ValidationErrors;
  params?: boolean;
}

// la configuration des validateurs pris en compte par le form control custom d'AsCore
export const listHandledValidators: HandledValidator[] = [
  {key: 'required', function: requiredIfNotBrouillon},
  {key: 'email', function: email},
  {key: 'noFuture', function: noFuture},
  {key: 'noPast', function: noPast},
  {key: 'min', function: Validators.min, params: true},
  {key: 'max', function: Validators.max, params: true},
  {key: 'pattern', function: Validators.pattern, params: true},
  {key: 'minlength', function: Validators.minLength, params: true},
  {key: 'maxlength', function: Validators.maxLength, params: true},
  {key: 'minDate', function: minDate, params: true},
  {key: 'maxDate', function: maxDate, params: true},
  {key: 'requiredIfEmpty', function: requiredIfEmpty, params: true},
  {key: 'range', function: range, params: true}
];

export interface AsCoreValidator {
  key: TypeAsCoreValidator;
  value?: any;
}

export class AsCoreCtrlOpts implements AbstractControlOptions {

  validators?: ValidatorFn | ValidatorFn[] | null;
  asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null;
  updateOn?: 'change' | 'blur' | 'submit';

  private _asCoreConf?: AsCoreValidator[];

  constructor(value: AsCoreValidator[]) {
    this.asCoreConf = value;
  }

  get asCoreConf(): AsCoreValidator[] {
    return this._asCoreConf;
  }

  set asCoreConf(value: AsCoreValidator[]) {

    this._asCoreConf = value;

    if (!value) {
      return;
    }

    this.validators = [];

    value.forEach(asCoreValidator => {
      const validator = listHandledValidators.find(it => it.key === asCoreValidator.key);

      if (validator.params && isNil(asCoreValidator.value)) {
        throw new Error(`Le validateur ${asCoreValidator.key} attend un argument`);
      } else if (!validator.params && asCoreValidator.value) {
        throw new Error(`Le validateur ${asCoreValidator.key} n'attend pas d'argument`);
      }

      if (validator.params) {
        (this.validators as ValidatorFn[]).push(validator.function(asCoreValidator.value) as ValidatorFn);
      } else {
        (this.validators as ValidatorFn[]).push(validator.function as ValidatorFn);
      }
    });
  }
}

export class AsCoreFormLabel {
  label: string;
  hideLabel = false;
  upperLabel = false;
}
