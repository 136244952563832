import { WithSearchAudit } from '../../service/api/with-search-audit';
import { WithSearch } from '../../service/api/with-search';
import { PageableGen, SliceAuditCommitGen } from '../../../generated';
import { Observable } from 'rxjs';

/**
 * Adapter pour exposer une interface de recherche d'AuditCommit, pour une entité donnée avec un service de recherche d'audit compatible
 */
export class WithSearchAuditAdapter implements WithSearch<any, SliceAuditCommitGen> {

  constructor(private entityId: any,
              private withSearchAudit: WithSearchAudit) {
  }

  search(recherche?: any, pageable?: PageableGen, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: '*/*' }): Observable<SliceAuditCommitGen> {
    return this.withSearchAudit.searchAudit(this.entityId, pageable, observe, reportProgress, options);
  }
}
