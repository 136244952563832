import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[asCoreIban]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IbanDirective),
    multi: true
  }]
})
export class IbanDirective extends DefaultValueAccessor {

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  private static transformValue(value: any): any {
    if (!value || (typeof value) !== 'string') {
      return value;
    }
    let result: string = value;

    // Suppression de tous les espaces
    result = result.split(' ').join('');

    const ibanParts = result.split(/(.{4})/).filter(it => it !== '');
    result = ibanParts.join(' ');

    return result.toUpperCase();
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const transformed = IbanDirective.transformValue((event.target as any).value);
    super.writeValue(transformed);
    this.onChange(transformed);
    this.onTouched();
  }
}
