import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UtilisateurCourantService } from '../../../../../administration/referentiel/utilisateur-courant.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  today: number = Date.now();
  utilisateur: any;
  devMode = false;
  utilisateurConnecte = '';

  constructor(private router: Router,
              public utilisateurCourantService: UtilisateurCourantService) {
    this.devMode = environment.dev;

    this.utilisateurConnecte = utilisateurCourantService.getUtilisateurCourant()?.instanceLabel;
    if (this.utilisateurConnecte == null) {
      this.utilisateurConnecte = ''; // ne devrait pas arriver
    }
  }

  ngOnInit(): void {
    setInterval(() => {
      this.today = Date.now();
    }, 1000);
  }

  logout(): void {
    this.utilisateurCourantService.logout();
  }

  ouvreCompte() {
    this.router.navigate(['/mon-compte']);
  }
}
