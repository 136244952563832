<ascore-create-detail titre="Recherche rapide" [form]="form" [tabs]="tabs" [service]="service">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 p-0 d-inline-flex flex-wrap">
                <div class="col-md-4">
                    <input type="text"
                           [formControlName]="fields.libelle"
                    >
                </div>
                <div class="col-md-4 d-flex justify-content-around">
                    <input type="checkbox" class="d-flex"
                           [containerDivCustomClass]="'d-flex'"
                           [formControlName]="fields.parDefaut"
                    >
                </div>
                <div class="col-md-4 pt-1">
                    <ascore-select #asCoreSelectComponent
                                   [form]="form"
                                   [multiSelect]="true"
                                   [formControlName_]="'displayListColumn'"
                                   [showFieldName]="'header'"
                                   (selectEvent)="listColumnChanged()">
                    </ascore-select>
                </div>
            </div>
        </div>
    </form>
</ascore-create-detail>
