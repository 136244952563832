/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LabelValueDtoGen } from './label-value-dto.gen.model';


export interface MouvementClientDtoGen { 
    listClient?: Array<LabelValueDtoGen>;
}

export class MouvementClientDtoGen_ {
  static readonly listClient = 'listClient';
  readonly listClient = 'listClient';
  static fields() {
    return new MouvementClientDtoGen_();
  }
}

