import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[asCoreReadonly]'
})
export class AsCoreReadonlyDirective implements OnInit {

  @Input() asCoreReadonly: boolean;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    if (this.asCoreReadonly) {
      this.el.nativeElement.classList.add('readonly');
    }
  }
}
