import { Router } from '@angular/router';
import { joinListStringNotBlank } from './string-util';
import { join } from 'lodash';

/**
 * Navigation vers l'URL avec un / de moins (url "parent"), avec les paramètres optionnels indiqués
 */
export function back(router: Router, params = ''): void {
  const parentUrl = getParentUrl(router);
  router.navigateByUrl(parentUrl + (params ? `?${params}` : ''));
}

export function currentUrlWithoutParam(router: Router): string {
  return getUrlWithoutParam(router.url);
}

export function getUrlWithoutParam(url: string): string {
  return url.split('?')[0];
}

export function getParentUrl(router: Router): string {
  return router.url.substr(0, router.url.lastIndexOf('/'));
}

export function getParentDomainFromUrl(router: Router): string {
  return router.url.split('/')[router.url.split('/').length - 2];
}

// export function isModeTablette(router: Router): boolean {
//   return router.url.indexOf('/tablette') > -1;
// }

export function extractSearchParams(form: any): string {
  const searchParams = [];
  Object.keys(form).forEach((key, index) => {
      if (form[key] != null && form[key].hasOwnProperty('id')) {
        searchParams.push(`${key}=${form[key].id}`);
      } else if (typeof form[key] === 'string' || typeof form[key] === 'number' || typeof form[key] === 'boolean') {
        const encodeValue = encodeURIComponent(form[key]);
        searchParams.push(`${key}=${encodeValue}`);
      } else if (form[key] instanceof Array) {
        searchParams.push(`${key}=${joinListStringNotBlank(',', form[key].map(it => it.id ? it.id : encodeURIComponent(it)))}`);
      }
    }
  );
  return join(searchParams, '&');
}

export function extractSearchParamsForRouter(form: any): any {
  const searchParams = {};
  Object.keys(form)
    .forEach((key, index) => {
        if (form[key] != null && form[key].hasOwnProperty('id')) {
          searchParams[key] = form[key].id;
        } else if (typeof form[key] === 'string' || typeof form[key] === 'number' || typeof form[key] === 'boolean') {
          // pas d'encodage URI, les services générés le font tout seul
          searchParams[key] = form[key];
        } else if (form[key] instanceof Array) {
          // pas d'encodage URI, les services générés le font tout seul
          searchParams[key] = joinListStringNotBlank(',', form[key].map(it => it.id ? it.id : it));
        }
      }
    );
  return searchParams;
}
