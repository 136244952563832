import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: '', '': 'asc'};

export function sortDirectionFromString(directionAsText: string): SortDirection {
  if (directionAsText === 'asc' || directionAsText === 'desc' || directionAsText === '') {
    return directionAsText;
  } else {
    return '';
  }
}

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[asCoreSortable]'
})
export class SortableHeaderDirective {

  /** nom de la colonne de tri */
  @Input() asCoreSortable = '';

  /** asc | desc | '' */
  @Input() direction: SortDirection = '';

  @Output() sort = new EventEmitter<SortEvent>();

  @HostListener('click') onClick(): void {
    this.rotate();
  }

  rotate(): void {
    if (this.asCoreSortable) {
      this.direction = rotate[this.direction];
      this.sort.emit({column: this.asCoreSortable, direction: this.direction});
    }
  }
}
