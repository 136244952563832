import { AfterContentInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';

@Component({
  selector: 'ascore-confirm-modal',
  templateUrl: './ascore-confirm-modal.component.html',
  styleUrls: ['./ascore-confirm-modal.component.scss']
})
export class AsCoreConfirmModalComponent implements AfterContentInit {

  faTimes = faTimes;

  @Input()
  message: string;

  @Input()
  labelOk = 'Ok';

  @Input()
  labelAnnuler = 'Annuler';

  @Input()
  titre = 'Confirmation';

  @Input()
  questionRaison: string = null;

  @Input()
  btnClass: typeBtn = 'btn-primary';

  @Input()
  isRaisonRequired = true;

  @Input()
  defaultRaison = '';

  @Input()
  messageImportant: string;

  form: FormGroup;
  faExclamationTriangle = faExclamationTriangle;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {

  }

  ngAfterContentInit(): void {
    if (this.questionRaison) {
      const raisonFormControl = AsCoreFormControl.init(this.defaultRaison).label(this.questionRaison);
      if (this.isRaisonRequired) {
        raisonFormControl.required();
      }
      this.form = this.fb.group({
        raison: raisonFormControl
      });
    } else {
      this.form = this.fb.group({});
    }
  }

  getRaison(): string {
    return this.form?.get('raison')?.value;
  }
}

export type typeBtn = 'btn-danger' | 'btn-primary';
