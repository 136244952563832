<div class="modal-header">
    <h6 class="modal-title">{{titre}}</h6>
    <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>
<div class="modal-body">

    <div class="d-flex ascore-content-container" [class.flex-fill]="!tabs || tabs.length === 0">
        <ng-content></ng-content>
    </div>

    <div *ngIf="form && tabs && tabs.length > 0" class="navbar-container">
        <ascore-navbar [form]="form" [tabs]="tabs"></ascore-navbar>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button *ngIf="showEnregistrer && !readonly"
            type="button" ngbAutofocus class="btn btn-primary"
            (click)="onSave()">Enregistrer
    </button>
    <div *ngIf="moreActionTemplate">
        <ng-container
                *ngTemplateOutlet="moreActionTemplate;context:{entity: entity}">
        </ng-container>
    </div>
</div>
