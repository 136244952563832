import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadonlyDirective } from './directives/readonly.directive';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { CapitalizeFullyDirective } from './directives/capitalize-fully.directive';
import { AscoreCompositionComponent } from './component/composition/ascore-composition.component';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { DateFormatPipe } from './pipe/date-format.pipe';
import { DateTimeFormatPipe } from './pipe/date-time-format.pipe';
import { AsCoreInputDirective } from './component/ascore-input/ascore-input.directive';
import { MontantNumeraireComponent } from './component/montant-numeraire/montant-numeraire.component';
import { FormSubmitDirective } from './component/ascore-input/form-submit.directive';
import { ControlErrorComponent } from './component/ascore-input/control-error/control-error.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RechercheComponent } from './component/recherche/recherche.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisableControlDirective } from './directives/disable.directive';
import { AsCoreSearchComponent } from './component/ascore-search/ascore-search.component';
import { AsCoreTableComponent } from './component/ascore-table/ascore-table.component';
import { AsCoreContainerComponent } from './component/ascore-container/ascore-container.component';
import { AsCoreProgressStepComponent } from './component/ascore-progress-step/ascore-progress-step.component';
import { AsCoreNavbarComponent } from './component/ascore-navbar/ascore-navbar.component';
import { AsCoreConfirmModalComponent } from './component/ascore-confirm-modal/ascore-confirm-modal.component';
import { AsCoreMobileItemComponent } from './component/ascore-mobile-item/ascore-mobile-item.component';
import { AsCoreSelectComponent } from './component/ascore-select/ascore-select.component';
import { AsCoreDetailComponent } from './component/ascore-detail/ascore-detail.component';
import { AsCoreToasterComponent } from './ascore-toaster/ascore-toaster.component';
import { AsCoreTabComponent } from './component/ascore-tab/ascore-tab.component';
import { AsCoreCardComponent } from './component/ascore-card/ascore-card.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SlicePaginationComponent } from './component/slice-pagination/slice-pagination.component';
import { AsCoreClearInputComponent } from './component/ascore-input/ascore-clear-input/ascore-clear-input.component';
import { AsCoreAuditComponent } from './component/ascore-audit/ascore-audit.component';
import { AsCoreAuditSearchComponent } from './component/ascore-audit-search/ascore-audit-search.component';
import { AsCoreSearchByFilterComponent } from './component/ascore-search/ascore-search-by-filter.component';
import { LowerCaseDirective } from './directives/lower-case.directive';
import { PasswordValidatorComponent } from './component/password-validator/password-validator.component';
import { AsCoreSelectModalComponent } from './component/ascore-select-modal/ascore-select-modal.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AscoreTimePickerComponent } from './component/ascore-time-picker/ascore-time-picker.component';
import { AsCoreMapComponent } from './component/ascore-map/as-core-map.component';
import {
  AsCoreRadioBtnContainerComponent
} from './component/ascore-container-with-radio-button/ascore-radio-btn-container.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { AsCoreReadonlyDirective } from './directives/ascore-readonly.directive';
import { IbanDirective } from './directives/iban.directive';
import { PasswordValidatorDirective } from './component/password-validator/password-validator.directive';
import { RibDirective } from './directives/rib.directive';
import { PreventMultipleClickDirective } from './directives/prevent-multiple-click.directive';
import { AlertModule } from '../flatable-components';
import { AscoreCreateDetailComponent } from './component/ascore-create-detail/ascore-create-detail.component';
import { AsCoreConflictModalComponent } from './component/ascore-conflict-modal/ascore-conflict-modal.component';
import {
  RechercheRapideCreateDetailComponent
} from './component/recherche-rapide/recherche-rapide-create-detail.component';


const ANGULAR_MODULES: any[] = [
  FormsModule, ReactiveFormsModule
];

export const xpfMaskConfig = {
  allowNegative: false,
  allowZero: true,
  align: 'right',
  decimal: ',',
  precision: 0,
  prefix: '',
  suffix: ' XPF',
  thousands: ' ',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ANGULAR_MODULES,
    FontAwesomeModule,
    NgbModule,
    NgxCurrencyModule.forRoot(xpfMaskConfig),
    NgSelectModule,
    NgxSpinnerModule,
    LeafletModule,
    AlertModule
  ],
  exports: [
    ANGULAR_MODULES,
    AsCoreCardComponent,
    AsCoreClearInputComponent,
    AsCoreContainerComponent,
    AsCoreDetailComponent,
    AsCoreRadioBtnContainerComponent,
    AsCoreInputDirective,
    AsCoreNavbarComponent,
    AsCoreProgressStepComponent,
    AsCoreSearchComponent,
    AsCoreSearchByFilterComponent,
    AsCoreSelectComponent,
    AsCoreTabComponent,
    AsCoreTableComponent,
    AsCoreToasterComponent,
    AutofocusDirective,
    IbanDirective,
    AsCoreReadonlyDirective,
    CapitalizeFullyDirective,
    CommonModule,
    AscoreCompositionComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    DisableControlDirective,
    FontAwesomeModule,
    FormSubmitDirective,
    MontantNumeraireComponent,
    NgbModule,
    NgxCurrencyModule,
    OnlyDigitsDirective,
    ReadonlyDirective,
    RechercheComponent,
    SortableHeaderDirective,
    LowerCaseDirective,
    UpperCaseDirective,
    PasswordValidatorDirective,
    RibDirective,
    AsCoreAuditComponent,
    PasswordValidatorComponent,
    AsCoreSelectModalComponent,
    AsCoreMapComponent,
    AscoreTimePickerComponent,
    PreventMultipleClickDirective,
    AscoreCreateDetailComponent
  ],
  declarations: [
    AsCoreCardComponent,
    AsCoreClearInputComponent,
    AsCoreConfirmModalComponent,
    AsCoreContainerComponent,
    AsCoreDetailComponent,
    AsCoreInputDirective,
    AsCoreMobileItemComponent,
    AsCoreNavbarComponent,
    AsCoreProgressStepComponent,
    AsCoreRadioBtnContainerComponent,
    AutofocusDirective,
    IbanDirective,
    PasswordValidatorDirective,
    AsCoreReadonlyDirective,
    AsCoreSearchComponent,
    AsCoreSearchByFilterComponent,
    AsCoreSelectComponent,
    AsCoreTabComponent,
    AsCoreTableComponent,
    AsCoreToasterComponent,
    CapitalizeFullyDirective,
    ClickOutsideDirective,
    AscoreCompositionComponent,
    ControlErrorComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    DisableControlDirective,
    FormSubmitDirective,
    MontantNumeraireComponent,
    OnlyDigitsDirective,
    ReadonlyDirective,
    RechercheComponent,
    RibDirective,
    SlicePaginationComponent,
    SortableHeaderDirective,
    LowerCaseDirective,
    UpperCaseDirective,
    AsCoreAuditComponent,
    AsCoreAuditSearchComponent,
    PasswordValidatorComponent,
    AsCoreSelectModalComponent,
    AsCoreMapComponent,
    AscoreTimePickerComponent,
    PreventMultipleClickDirective,
    AscoreCreateDetailComponent,
    AsCoreConflictModalComponent,
    RechercheRapideCreateDetailComponent
  ]
})
export class AsCoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: AsCoreModule,
      providers: [
        NgbActiveModal,
        DatePipe,
        DateFormatPipe,
        DateTimeFormatPipe]
    };
  }
}
