<div class="subSearchForm flex-1" [formGroup]="form"
     [class.invisible-options]="!optionsVisible()">

    <div *ngIf="multiSelect" class="d-inline-flex w-100">
        <div class="flex-1 w-100" [class.d-inline-flex]="inline">
            <label class="label-ascore mr-2 mt-2" *ngIf="getLabel() && !asMaterial"
                   [class.inline]="inline"
                   [style]="getWidth()">{{getLabel()}}</label>

            <label class="label-ascore-search ml-2 mt-2 ng-star-inserted w-100 justify-content-start"
                   *ngIf="getLabel() && asMaterial">
                {{getLabel()}}
            </label>
            <div clickOutside class="flex-1 tagfield form-control d-inline-flex mt-2"
                 [class.ml-2]="asMaterial"
                 [class.mt-3]="asMaterial"
                 [class.multi-select-material]="asMaterial"
                 [class.mt-auto]="!asMaterial"
                 [class.asCoreDisabled]="isDependsOnDisabled()"
                 (clickOutside)="onFocusOut()"
                 (click)="!isSearchDisabled() ? clickOnCaret($event) : {}">
        <span
                *ngIf="!isDisabled() && !isReadOnly() && !isDependsOnDisabled() && !isSelectedValue()">{{placeHolder}}</span>
                <div class="d-block flex-1">
                    <div class="tag" *ngFor="let item of getControl().value;let index = index">
                        {{formatData(item)}}
                        <fa-icon *ngIf="!isDisabled() && !isReadOnly() && !isDependsOnDisabled()"
                                 [icon]="iconTimes"
                                 (click)="removeItemMultiSelect($event, index,item)">
                        </fa-icon>
                    </div>
                </div>
                <div class="m-auto" *ngIf="!isDisabled() && !isReadOnly() && !isDependsOnDisabled() && !isSearchDisabled()">
                    <fa-icon class="align-arrow-right pr-2 icon-select"
                             [class.withoutLabel]="getLabel() == null"
                             [class.inline]="inline"
                             [icon]="iconCaretDown"
                             (click)="clickOnCaret($event)">
                    </fa-icon>
                    <fa-icon class="align-cross-right pr-2 icon-select"
                             ngbTooltip="Vider le champ"
                             [class.withoutLabel]="getLabel() == null "
                             [class.inline]="inline"
                             [icon]="iconTimes"
                             (click)="clear($event)">
                    </fa-icon>
                </div>
            </div>
        </div>
        <div class="dropdown-list" *ngIf="rechercheEnCours" (click)="$event.stopPropagation()">
            <ul class="multiselect">
                <li class="p-0">
                    <fa-icon class="close-multi-selection"
                             [icon]="faArrowUp"
                             (click)="onFocusOut()">
                    </fa-icon>
                    <input #inputMultiSelect
                           asCoreInputExcluded
                           type="text"
                           class="dropdown multiselect form-control"
                           placeholder="Recherche..."
                           [ngModel]="[lastMultiselected]"
                           [ngbTypeahead]="search"
                           [inputFormatter]="formatDataCode"
                           [resultFormatter]="formatData"
                           (ngModelChange)="onMultiSelect($event)"
                           (click)="onFocus($event)"
                           (keyup.enter)="keyEnterEvent.emit()"
                           [ngModelOptions]="{standalone: true}"
                           [editable]='false'
                           [attr.required]="required ? 'true' : null"/>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="!multiSelect">
        <input #inputSingleSelect
               type="text"
               [label]="label"
               [asMaterial]="asMaterial"
               class="dropdown form-control select"
               [attr.inline]="inline ? '' : null"
               [class.diff-style]="showDiffStyle"
               [ngClass]="{'text-uppercase': uppercase}"
               [placeholder]="placeHolder"
               [formControlName]="formControlName_"
               [ngbTypeahead]="search"
               [inputFormatter]="formatDataCode"
               [resultFormatter]="formatData"
               [resultTemplate]="resultTemplate"
               (ngModelChange)="onSelect($event)"
               (focusout)="onFocusOut()"
               (click)="onFocus($event)"
               (keyup.enter)="keyEnterEvent.emit()"
               [readOnly]="isReadOnly() || isDisabled()"
               [asCoreDisabled]="isDependsOnDisabled()"
               [labelWidth]="labelWidth"
               [attr.required]="required ? 'true' : null"
               [propertyToDisplay]="propertyToDisplay"
               [containerDivCustomClass]="containerDivCustomClass"
               [editable]='false'/>

        <fa-icon *ngIf="!isDisabled() && !isReadOnly() && !isDependsOnDisabled()"
                 ngbTooltip="Vider le champ"
                 class="align-cross-right single-select icon-select"
                 [class.mt-2]="!asMaterial && getLabel()"
                 [class.withoutLabel]="getLabel() == null "
                 [class.inline]="inline"
                 [class.search-select-icon]="asMaterial"
                 [icon]="iconTimes"
                 (click)="clear($event)">
        </fa-icon>

        <fa-icon *ngIf="!isDisabled() && !isReadOnly() && !isDependsOnDisabled()"
                 class="align-arrow-right single-select icon-select"
                 [class.mt-2]="!asMaterial && getLabel()"
                 [class.withoutLabel]="getLabel() == null"
                 [class.inline]="inline"
                 [class.search-select-icon]="asMaterial"
                 [icon]="iconCaretDown"
                 (click)="clickOnCaret($event)">
        </fa-icon>
    </div>
</div>

