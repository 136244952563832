import { isNil } from 'lodash';

export function getValueRecursively(element: any, fieldName: string,
                                    getValue: (element: any, fieldName: string) => string = defaultGetValue): any {
  if (isNil(element)) {
    return element;
  }

  if (fieldName.indexOf('.') < 0) {
    return getValue(element, fieldName);
  }

  if (fieldName.split('.').length > 2) {
    return getValueRecursively(element[fieldName.split('.')[0]], fieldName.split('.').slice(1).join('.'), getValue);
  } else {
    const [field, property] = fieldName.split('.');
    if (element[field] instanceof Array) {
      return element[field]
        .map(item => item[property])
        .sort()
        .join(', ');
    } else if (!isNil(element[field])) {
      return getValue(element[field], property);
    } else {
      return null;
    }
  }
}

export function defaultGetValue(el, field): any {
  const value = el[field];
  return !isNil(value) ? value : '';
}
