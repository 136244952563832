import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AsCoreTab } from '../../../../shared/ascore/component/ascore-tab/ascore-tab.model';
import { AsCoreFormControl } from '../../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import { UtilisateurGen_, UtilisateurServiceGen } from '../../../../shared/generated';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ComponentCanDeactivate } from '../../../../shared/guards/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-utilisateur-create-detail',
    templateUrl: './utilisateur-create-detail.component.html',
    styleUrls: ['./utilisateur-create-detail.component.scss']
})
export class UtilisateurCreateDetailComponent implements ComponentCanDeactivate, OnInit {


    faTimes = faTimes;

    public form: FormGroup;


    tabs: AsCoreTab[] = [];

    creation = false;

    fields = UtilisateurGen_.fields();

    constructor(private fb: FormBuilder,
                public service: UtilisateurServiceGen) {
    }

    canDeactivate: () => boolean | Observable<boolean> = () => {
        return this.form.pristine;
    };


    ngOnInit(): void {

        this.createForm();

    }

    createForm(): void {
        this.form = this.fb.group({
            [this.fields.username]: AsCoreFormControl.init().required().label('Identifiant'),
            [this.fields.password]: AsCoreFormControl.init().label('Mot de passe'),
            [this.fields.fonction]: AsCoreFormControl.init().required().label('Fonction'),
            [this.fields.actif]: AsCoreFormControl.init().required().label('Actif'),
        });
    }
}
