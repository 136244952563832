<ascore-card [title]="title" [status]="status"
             [header]="header"
             [options]="true" [history]="infoHistorique" (keyup.control.enter)="save()"
             [customTitle]="customTitle" *ngIf="loaded" [forceShowHistory]="forceShowHistory">

    <form [formGroup]="form"
          (keydown.enter)="$event.preventDefault()"
          (ngSubmit)="save()"
          class="detail-form flex-fill">

        <div class="d-flex ascore-content-container" [ngClass]="(disablePadding?'':'mb-4 ml-4 mr-4')"
             [class.flex-fill]="!tabs || tabs.length === 0">
            <ng-content></ng-content>
        </div>

        <div *ngIf="form && tabs && tabs.length > 0" class="navbar-container">
            <ascore-navbar [form]="form" [tabs]="tabs"></ascore-navbar>
        </div>

        <div class="ascore-footer btn-container pr-4 pt-1 pb-1" *ngIf="toolbarVisible===true">
            <!-- type button important ci-dessous, sinon ça provoque un save! -->
            <button class="btn btn-outline-secondary" (click)="cancel()" *ngIf="annulerVisible" type="button">
                <fa-icon [icon]="faTimes"></fa-icon>
                {{readOnly ? 'Retour' : 'Annuler'}}
            </button>
            <button *ngIf="!readOnly"
                    class="btn btn-primary"
                    type="submit">
                <fa-icon [icon]="faSave"></fa-icon>
                {{ this.exitAfterUpdate ? 'Enregistrer et Fermer' : 'Enregistrer'}}
            </button>

            <button class="btn btn-outline-secondary ml-2"
                    (click)="this.cancel()"
                    type="button">
                Fermer
            </button>

        </div>
    </form>
</ascore-card>
<ngx-spinner name="ascore-detail-spinner" [fullScreen]="false" color="#1abc9c" type="ball-scale-multiple"></ngx-spinner>
