/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortObjectGen } from './sort-object.gen.model';


export interface PageableObjectGen { 
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: SortObjectGen;
    unpaged?: boolean;
}

export class PageableObjectGen_ {
  static readonly offset = 'offset';
  readonly offset = 'offset';
  static readonly pageNumber = 'pageNumber';
  readonly pageNumber = 'pageNumber';
  static readonly pageSize = 'pageSize';
  readonly pageSize = 'pageSize';
  static readonly paged = 'paged';
  readonly paged = 'paged';
  static readonly sort = 'sort';
  readonly sort = 'sort';
  static readonly unpaged = 'unpaged';
  readonly unpaged = 'unpaged';
  static fields() {
    return new PageableObjectGen_();
  }
}

