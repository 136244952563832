import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { NgbNavConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, pairwise } from 'rxjs/operators';
import { ServiceWorkerUpdateService } from './shared/ascore/service/service-worker-update.service';
import { AllEnumServiceGen } from './shared/generated';
import { EnumService } from './shared/ascore/service/enum.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private ngbTooltipConfig: NgbTooltipConfig,
              private ngbNavConfig: NgbNavConfig,
              private http: HttpClient,
              private serviceWorkerUpdateService: ServiceWorkerUpdateService,
              private allEnumService: AllEnumServiceGen,
              private enumService: EnumService) {

    const headerDict = {
      'Accept': 'application/json',
      'show-error': 'false'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
  }

  ngOnInit() {
    this.ngbTooltipConfig.openDelay = 1000;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      window.sessionStorage.setItem('previousUrl', this.router.url);
    });

    this.serviceWorkerUpdateService.checkForUpdates();

    this.allEnumService.findAll().subscribe(result => {
      this.enumService.setAllEnums(result);
    });
  }
}
