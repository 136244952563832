import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AsCoreFormControl } from '../AsCoreFormControl';

@Component({
  selector: 'ascore-clear-input',
  templateUrl: './ascore-clear-input.component.html',
  styleUrls: ['./ascore-clear-input.component.scss']
})
export class AsCoreClearInputComponent {

  faTimes = faTimes;

  @Input()
  formControl: AsCoreFormControl;

  constructor() {
  }

  clear(): void {
    this.formControl.reset();
  }

}
