import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[asCoreLowercase]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LowerCaseDirective),
    multi: true
  }]
})
export class LowerCaseDirective extends DefaultValueAccessor {

  constructor(renderer: Renderer2, private elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const start = this.elementRef.nativeElement.selectionStart;
    const end = this.elementRef.nativeElement.selectionEnd;
    const transformed = this.transformValue((event.target as any).value);
    super.writeValue(transformed);
    this.elementRef.nativeElement.setSelectionRange(start, end);
    this.onChange(transformed);
    this.onTouched();
  }

  private transformValue(value: any): any {
    return value && typeof value === 'string' ? value.toLowerCase() : value;
  }
}
