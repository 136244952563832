import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  /* Ne pas supprimer, nécessaire à l'affichage des pages /auth/xxx sans la mise en forme */

}
