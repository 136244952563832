import { isNil } from 'lodash';

export function isBlank(str: string): boolean {
  return (!str || /^\s*$/.test(str));
}

export function simpleClassName(fullClassName: string): string {
  if (isBlank(fullClassName)) {
    throw new Error('fullClassName is blank');
  }
  const lastDotIndex = fullClassName.lastIndexOf('.');
  return lastDotIndex === -1 ? fullClassName : fullClassName.substring(lastDotIndex + 1);
}


export function joinListStringNotBlank(separator: string, ...values): string {
  let result = '';

  values.forEach(ligne => {
    if (!isBlank(ligne)) {
      result += `${ligne}${separator}`;
    }
  });

  if (result.length > 0) {
    result = result.slice(0, -separator.length);
  }

  return result;
}

/**
 * Supprime les caractères qui pourraient être gênants lors du download d'un fichier vers un utilisateur
 */
export function simplifyFilename(filename: string): string {
  if (isNil(filename)) {
    return filename;
  }
  return filename.replace(/[^a-zA-Z\- \.]/g, '');
}

export function capitalize(value: string): string {

  if (isBlank(value)) {
    return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function decamelize(str, separator): string {
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
}

export function formatNombreAvecSeparateur(value): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
