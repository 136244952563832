import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: Date): string {
    const format = 'dd/MM/yyyy';
    return value !== null ? this.datePipe.transform(value, format) : null;
  }
}
