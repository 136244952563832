/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PageableGen { 
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export class PageableGen_ {
  static readonly page = 'page';
  readonly page = 'page';
  static readonly size = 'size';
  readonly size = 'size';
  static readonly sort = 'sort';
  readonly sort = 'sort';
  static fields() {
    return new PageableGen_();
  }
}

