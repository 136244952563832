import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NextConfig } from '../../../../app-config';
import { environment } from '../../../../../environments/environment';
import { AppConfigServiceGen } from '../../../../shared/generated';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  public flatConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  backendVersion = '';
  frontendVersion = '';

  collapsed = false;
  private devMode: boolean;

  constructor(public appConfigServiceGen: AppConfigServiceGen) {
    this.flatConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
    this.devMode = environment.dev;
    this.appConfigServiceGen.getConfig()
      .subscribe(value => {
        this.backendVersion = value.backendVersion;
      });

    this.frontendVersion = environment.appVersion;
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    this.collapsed = !this.collapsed;
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
