/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrRappelAutomatiquePojoGen { 
    actionClientId?: number;
    actionClientInstanceLabel?: string;
    clientId?: number;
    clientInstanceLabel?: string;
    commentaire?: string;
    commercialId?: number;
    commercialInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    date?: Date;
    duree?: string;
    dureeAvantRappel?: number;
    dureeTypeId?: string;
    dureeTypeInstanceLabel?: string;
    entites?: string;
    entitesexport?: string;
    entitesid?: string;
    id?: number;
    instanceLabel?: string;
    rappelAutomatiqueTypeId?: string;
    rappelAutomatiqueTypeInstanceLabel?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrRappelAutomatiquePojoGen_ {
  static readonly actionClientId = 'actionClientId';
  readonly actionClientId = 'actionClientId';
  static readonly actionClientInstanceLabel = 'actionClientInstanceLabel';
  readonly actionClientInstanceLabel = 'actionClientInstanceLabel';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly commercialId = 'commercialId';
  readonly commercialId = 'commercialId';
  static readonly commercialInstanceLabel = 'commercialInstanceLabel';
  readonly commercialInstanceLabel = 'commercialInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly date = 'date';
  readonly date = 'date';
  static readonly duree = 'duree';
  readonly duree = 'duree';
  static readonly dureeAvantRappel = 'dureeAvantRappel';
  readonly dureeAvantRappel = 'dureeAvantRappel';
  static readonly dureeTypeId = 'dureeTypeId';
  readonly dureeTypeId = 'dureeTypeId';
  static readonly dureeTypeInstanceLabel = 'dureeTypeInstanceLabel';
  readonly dureeTypeInstanceLabel = 'dureeTypeInstanceLabel';
  static readonly entites = 'entites';
  readonly entites = 'entites';
  static readonly entitesexport = 'entitesexport';
  readonly entitesexport = 'entitesexport';
  static readonly entitesid = 'entitesid';
  readonly entitesid = 'entitesid';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly rappelAutomatiqueTypeId = 'rappelAutomatiqueTypeId';
  readonly rappelAutomatiqueTypeId = 'rappelAutomatiqueTypeId';
  static readonly rappelAutomatiqueTypeInstanceLabel = 'rappelAutomatiqueTypeInstanceLabel';
  readonly rappelAutomatiqueTypeInstanceLabel = 'rappelAutomatiqueTypeInstanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrRappelAutomatiquePojoGen_();
  }
}

