import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IdInstanceLabel } from '../../models/id-instance-label';

@Component({
  selector: 'ascore-select-modal',
  templateUrl: './ascore-select-modal.component.html',
  styleUrls: ['./ascore-select-modal.component.scss']
})
export class AsCoreSelectModalComponent implements OnInit {

  @Input()
  message: string;

  @Input()
  labelOk = 'Ok';

  @Input()
  labelAnnuler = 'Annuler';

  @Input()
  titre = 'Demande de confirmation';

  selectedItem: any;

  @Input()
  btnClass: typeBtn = 'btn-danger';

  @Input()
  placeholder: 'Sélectionnez un élément';

  @Input()
  listItem: IdInstanceLabel[] = [];

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.selectedItem = this.listItem ? this.listItem[0].id : null;
  }

}

export type typeBtn = 'btn-danger' | 'btn-success' | 'btn-info';
