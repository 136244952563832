import { WithFilterSearch } from './with-filter-search';
import { Filter } from '../../component/ascore-search/filter';
import { PagedRessource } from '../paged-ressources';
import { PageableGen } from '../../../generated';
import { Observable } from 'rxjs';

/**
 * Informations nécessaires pour une recherche par filtre
 */
export class WithFilterSearchHelper {

  withFilterSearch: WithFilterSearch<PagedRessource<any>>;
  filterBuilder: () => Filter;
  customSortForBackend: () => Array<string>;

  // Pour AsCoreSearch + pour select sur SR avec filterBuilder custom (voir ActeurDetail)
  static forAsCoreSearch(withFilterSearch: WithFilterSearch<any>,
                         filterBuilder: () => Filter,
                         customSortForBackend: () => Array<string> = null): WithFilterSearchHelper {
    const helper = new WithFilterSearchHelper();
    helper.withFilterSearch = withFilterSearch;
    helper.filterBuilder = filterBuilder;
    helper.customSortForBackend = customSortForBackend;
    return helper;
  }

  // Pour select sur SR sans filterBuilder custom.
  static forAsCoreSelect(withFilterSearch: WithFilterSearch<PagedRessource<any>>,
                         customSortForBackend: () => Array<string> = null): WithFilterSearchHelper {
    const helper = new WithFilterSearchHelper();
    helper.withFilterSearch = withFilterSearch;
    helper.customSortForBackend = customSortForBackend;
    return helper;
  }

  setSortOnPageable(pageable: PageableGen): PageableGen {
    if (this.customSortForBackend) {
      pageable.sort = this.customSortForBackend();
    }
    return pageable;
  }

  callSearchWithFilter(filter: Filter, pageable: PageableGen): Observable<PagedRessource<any>> {
    const sortedPageable = this.setSortOnPageable(pageable);
    return this.withFilterSearch.searchFilter(filter.build(), sortedPageable);
  }

  callSearch(pageable: PageableGen): Observable<PagedRessource<any>> {
    return this.callSearchWithFilter(this.filterBuilder(), pageable);
  }
}

