import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerUpdateService {
  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
        .then());
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe(event => this.reloadApp());
  }

  private reloadApp(): void {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

}
