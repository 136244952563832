import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

export function triggerFileDownload(file: AsCoreFile): void {
  const binaryData = [];
  binaryData.push(file.data);
  const url = window.URL.createObjectURL(new Blob(binaryData, {type: file.contentType}));
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.setAttribute('target', 'blank');
  a.href = url;
  a.download = file.filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export class AsCoreFile {
  filename: string;
  contentType: string;
  data: Blob;
}

export function downloadDocument(documentQueryReponse: Observable<HttpResponse<Blob>>): void {
  getDocument(documentQueryReponse).subscribe();
}

export function getDocument(documentQueryReponse: Observable<HttpResponse<Blob>>): Observable<AsCoreFile | null> {

  return documentQueryReponse
    .pipe(first())
    .pipe(map((response) => {
      return {
        filename: response.headers.get('Filename'),
        data: response?.body,
        contentType: response.headers.get('content-type'),
      } as AsCoreFile;
    }))
    .pipe(
      tap(response => {
        if (response !== null) {
          triggerFileDownload(response);
        }
      })
    );
}
