<div class="item">
    <div *ngFor="let element of content; let index = index; let last = last"
         class="tr"
         [style]="getStyle(element)"
         (contextmenu)="openEvent.emit({ entity: element, index: index})"
         (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')"
    >
        <hr>
        <div *ngFor="let column of columns" class="pl-2" [class.template-container]="column.template != null">
            <div style="font-weight: bold">{{column.header}}</div>
            <div *ngIf="column.template" class="container-mobile">
                <ng-container
                        *ngTemplateOutlet="column.template;context:{element: element, index: index}">
                </ng-container>
            </div>
            <div class="pre-line container-mobile" *ngIf="!column.template">
                <div>{{ getValue(element, column.fieldName)}}</div>
            </div>
            <hr>
        </div>
    </div>
</div>

