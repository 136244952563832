/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagPojoGen } from './tag-pojo.gen.model';


export interface SrClientPojoGen { 
    adresse?: string;
    clientFamilleId?: number;
    clientFamilleInstanceLabel?: string;
    clientSousFamilleId?: number;
    clientSousFamilleInstanceLabel?: string;
    codeClient?: string;
    created?: Date;
    createdBy?: string;
    id?: number;
    instanceLabel?: string;
    nom?: string;
    prospect?: boolean;
    quadra?: boolean;
    tags?: Array<TagPojoGen>;
    tagsexport?: string;
    tagsid?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrClientPojoGen_ {
  static readonly adresse = 'adresse';
  readonly adresse = 'adresse';
  static readonly clientFamilleId = 'clientFamilleId';
  readonly clientFamilleId = 'clientFamilleId';
  static readonly clientFamilleInstanceLabel = 'clientFamilleInstanceLabel';
  readonly clientFamilleInstanceLabel = 'clientFamilleInstanceLabel';
  static readonly clientSousFamilleId = 'clientSousFamilleId';
  readonly clientSousFamilleId = 'clientSousFamilleId';
  static readonly clientSousFamilleInstanceLabel = 'clientSousFamilleInstanceLabel';
  readonly clientSousFamilleInstanceLabel = 'clientSousFamilleInstanceLabel';
  static readonly codeClient = 'codeClient';
  readonly codeClient = 'codeClient';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly prospect = 'prospect';
  readonly prospect = 'prospect';
  static readonly quadra = 'quadra';
  readonly quadra = 'quadra';
  static readonly tags = 'tags';
  readonly tags = 'tags';
  static readonly tagsexport = 'tagsexport';
  readonly tagsexport = 'tagsexport';
  static readonly tagsid = 'tagsid';
  readonly tagsid = 'tagsid';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrClientPojoGen_();
  }
}

