/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ChiffreAffaireDtoGen } from '../model/models';
import { ClientStatistiqueTypeGen } from '../model/models';
import { LabelValueDtoGen } from '../model/models';
import { MouvementClientDtoGen } from '../model/models';
import { PageEnergieCommercialeDtoGen } from '../model/models';
import { PageableGen } from '../model/models';
import { TauxRetentionDtoGen } from '../model/models';
import { TauxTransformationDtoGen } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { AsCoreConfiguration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class StatistiqueServiceGen {

    protected basePath = 'http://localhost:8085';
    public defaultHeaders = new HttpHeaders();
    public configuration = new AsCoreConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: AsCoreConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exportChiffreAffairesCumul.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling exportChiffreAffairesCumul.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling exportChiffreAffairesCumul.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/statistiques/export-chiffre-affaires-cumul`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebutPeriodereference 
     * @param dateFinPeriodereference 
     * @param dateFin 
     * @param clientStatistiqueType 
     * @param listEntite 
     * @param clientFamille 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportClients(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, clientStatistiqueType: ClientStatistiqueTypeGen, listEntite?: Array<number>, clientFamille?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportClients(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, clientStatistiqueType: ClientStatistiqueTypeGen, listEntite?: Array<number>, clientFamille?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportClients(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, clientStatistiqueType: ClientStatistiqueTypeGen, listEntite?: Array<number>, clientFamille?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportClients(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, clientStatistiqueType: ClientStatistiqueTypeGen, listEntite?: Array<number>, clientFamille?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exportClients.');
        }
        if (dateDebutPeriodereference === null || dateDebutPeriodereference === undefined) {
            throw new Error('Required parameter dateDebutPeriodereference was null or undefined when calling exportClients.');
        }
        if (dateFinPeriodereference === null || dateFinPeriodereference === undefined) {
            throw new Error('Required parameter dateFinPeriodereference was null or undefined when calling exportClients.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling exportClients.');
        }
        if (clientStatistiqueType === null || clientStatistiqueType === undefined) {
            throw new Error('Required parameter clientStatistiqueType was null or undefined when calling exportClients.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dateDebutPeriodereference !== undefined && dateDebutPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebutPeriodereference, 'dateDebutPeriodereference');
        }
        if (dateFinPeriodereference !== undefined && dateFinPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFinPeriodereference, 'dateFinPeriodereference');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientStatistiqueType !== undefined && clientStatistiqueType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientStatistiqueType, 'clientStatistiqueType');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }
        if (clientFamille !== undefined && clientFamille !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientFamille, 'clientFamille');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/statistiques/export-clients`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param utilisateurId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exportEnergieCommerciale.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling exportEnergieCommerciale.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling exportEnergieCommerciale.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (utilisateurId !== undefined && utilisateurId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>utilisateurId, 'utilisateurId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/statistiques/export-energie-commerciale`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exportTauxTransformation.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling exportTauxTransformation.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling exportTauxTransformation.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/statistiques/export-taux-transformation`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public exportTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public exportTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public exportTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling exportTempsCycleVente.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling exportTempsCycleVente.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling exportTempsCycleVente.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/statistiques/export-temps-cycle-vente`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ChiffreAffaireDtoGen>>;
    public getChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ChiffreAffaireDtoGen>>>;
    public getChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ChiffreAffaireDtoGen>>>;
    public getChiffreAffairesCumul(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getChiffreAffairesCumul.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getChiffreAffairesCumul.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getChiffreAffairesCumul.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ChiffreAffaireDtoGen>>(`${this.configuration.basePath}/api/statistiques/chiffre-affaires-cumul/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChiffreAffairesEvolution(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ChiffreAffaireDtoGen>>;
    public getChiffreAffairesEvolution(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ChiffreAffaireDtoGen>>>;
    public getChiffreAffairesEvolution(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ChiffreAffaireDtoGen>>>;
    public getChiffreAffairesEvolution(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getChiffreAffairesEvolution.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getChiffreAffairesEvolution.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getChiffreAffairesEvolution.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ChiffreAffaireDtoGen>>(`${this.configuration.basePath}/api/statistiques/chiffre-affaires-evolution/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param utilisateurId 
     * @param listEntite 
     * @param pageable 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, pageable?: PageableGen, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PageEnergieCommercialeDtoGen>;
    public getEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, pageable?: PageableGen, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PageEnergieCommercialeDtoGen>>;
    public getEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, pageable?: PageableGen, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PageEnergieCommercialeDtoGen>>;
    public getEnergieCommerciale(id: number, dateDebut: Date, dateFin: Date, clientId?: number, utilisateurId?: number, listEntite?: Array<number>, pageable?: PageableGen, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEnergieCommerciale.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getEnergieCommerciale.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getEnergieCommerciale.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (utilisateurId !== undefined && utilisateurId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>utilisateurId, 'utilisateurId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }
        if (pageable !== undefined && pageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageable, 'pageable');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageEnergieCommercialeDtoGen>(`${this.configuration.basePath}/api/statistiques/energie-commerciale/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnergieCommercialeClient(dateDebut: Date, dateFin: Date, clientId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<LabelValueDtoGen>>;
    public getEnergieCommercialeClient(dateDebut: Date, dateFin: Date, clientId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<LabelValueDtoGen>>>;
    public getEnergieCommercialeClient(dateDebut: Date, dateFin: Date, clientId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<LabelValueDtoGen>>>;
    public getEnergieCommercialeClient(dateDebut: Date, dateFin: Date, clientId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getEnergieCommercialeClient.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getEnergieCommercialeClient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LabelValueDtoGen>>(`${this.configuration.basePath}/api/statistiques/energie-commerciale-client`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebutPeriodereference 
     * @param dateFinPeriodereference 
     * @param dateFin 
     * @param listEntite 
     * @param clientFamille 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMouvementClient(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<MouvementClientDtoGen>;
    public getMouvementClient(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<MouvementClientDtoGen>>;
    public getMouvementClient(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<MouvementClientDtoGen>>;
    public getMouvementClient(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMouvementClient.');
        }
        if (dateDebutPeriodereference === null || dateDebutPeriodereference === undefined) {
            throw new Error('Required parameter dateDebutPeriodereference was null or undefined when calling getMouvementClient.');
        }
        if (dateFinPeriodereference === null || dateFinPeriodereference === undefined) {
            throw new Error('Required parameter dateFinPeriodereference was null or undefined when calling getMouvementClient.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getMouvementClient.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebutPeriodereference !== undefined && dateDebutPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebutPeriodereference, 'dateDebutPeriodereference');
        }
        if (dateFinPeriodereference !== undefined && dateFinPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFinPeriodereference, 'dateFinPeriodereference');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }
        if (clientFamille !== undefined && clientFamille !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientFamille, 'clientFamille');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<MouvementClientDtoGen>(`${this.configuration.basePath}/api/statistiques/mouvement-client/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebutPeriodereference 
     * @param dateFinPeriodereference 
     * @param dateFin 
     * @param listEntite 
     * @param clientFamille 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTauxRetention(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TauxRetentionDtoGen>;
    public getTauxRetention(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TauxRetentionDtoGen>>;
    public getTauxRetention(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TauxRetentionDtoGen>>;
    public getTauxRetention(id: number, dateDebutPeriodereference: Date, dateFinPeriodereference: Date, dateFin: Date, listEntite?: Array<number>, clientFamille?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTauxRetention.');
        }
        if (dateDebutPeriodereference === null || dateDebutPeriodereference === undefined) {
            throw new Error('Required parameter dateDebutPeriodereference was null or undefined when calling getTauxRetention.');
        }
        if (dateFinPeriodereference === null || dateFinPeriodereference === undefined) {
            throw new Error('Required parameter dateFinPeriodereference was null or undefined when calling getTauxRetention.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getTauxRetention.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebutPeriodereference !== undefined && dateDebutPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebutPeriodereference, 'dateDebutPeriodereference');
        }
        if (dateFinPeriodereference !== undefined && dateFinPeriodereference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFinPeriodereference, 'dateFinPeriodereference');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }
        if (clientFamille !== undefined && clientFamille !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientFamille, 'clientFamille');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TauxRetentionDtoGen>(`${this.configuration.basePath}/api/statistiques/taux-retention/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TauxTransformationDtoGen>;
    public getTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TauxTransformationDtoGen>>;
    public getTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TauxTransformationDtoGen>>;
    public getTauxTransformation(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTauxTransformation.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getTauxTransformation.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getTauxTransformation.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<TauxTransformationDtoGen>(`${this.configuration.basePath}/api/statistiques/taux-transformation/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<LabelValueDtoGen>>;
    public getTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<LabelValueDtoGen>>>;
    public getTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<LabelValueDtoGen>>>;
    public getTempsCycleVente(id: number, dateDebut: Date, dateFin: Date, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTempsCycleVente.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getTempsCycleVente.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getTempsCycleVente.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LabelValueDtoGen>>(`${this.configuration.basePath}/api/statistiques/temps-cycle-vente/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param dateDebut 
     * @param dateFin 
     * @param clientId 
     * @param listEntite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getValeurMoyenneContrat(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<ChiffreAffaireDtoGen>>;
    public getValeurMoyenneContrat(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<ChiffreAffaireDtoGen>>>;
    public getValeurMoyenneContrat(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<ChiffreAffaireDtoGen>>>;
    public getValeurMoyenneContrat(id: number, dateDebut: Date, dateFin: Date, clientId?: number, listEntite?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getValeurMoyenneContrat.');
        }
        if (dateDebut === null || dateDebut === undefined) {
            throw new Error('Required parameter dateDebut was null or undefined when calling getValeurMoyenneContrat.');
        }
        if (dateFin === null || dateFin === undefined) {
            throw new Error('Required parameter dateFin was null or undefined when calling getValeurMoyenneContrat.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dateDebut !== undefined && dateDebut !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDebut, 'dateDebut');
        }
        if (dateFin !== undefined && dateFin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateFin, 'dateFin');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (listEntite) {
            listEntite.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'listEntite');
            })
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ChiffreAffaireDtoGen>>(`${this.configuration.basePath}/api/statistiques/valeur-moyenne-contrat/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
