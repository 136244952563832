/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DevisStatutGen = 'AUCUN' | 'ENVOYE' | 'REFUSE' | 'ACCEPTE' | 'EN_ATTENTE' | 'ANNULATION';

export const DevisStatutGen = {
        AUCUN: 'AUCUN' as DevisStatutGen,
        ENVOYE: 'ENVOYE' as DevisStatutGen,
        REFUSE: 'REFUSE' as DevisStatutGen,
        ACCEPTE: 'ACCEPTE' as DevisStatutGen,
        EN_ATTENTE: 'EN_ATTENTE' as DevisStatutGen,
        ANNULATION: 'ANNULATION' as DevisStatutGen
        };

export class DevisStatutGen_ {
    static readonly id = 'id';
    readonly id = 'id';
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new DevisStatutGen_();
}
}

