/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagPojoGen } from './tag-pojo.gen.model';


export interface SrContactPojoGen { 
    clientId?: number;
    clientInstanceLabel?: string;
    commentaire?: string;
    contactFonctionId?: number;
    contactFonctionInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    fonction?: string;
    id?: number;
    instanceLabel?: string;
    mail?: string;
    nom?: string;
    prenom?: string;
    tags?: Array<TagPojoGen>;
    tagsid?: string;
    telephone?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrContactPojoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly contactFonctionId = 'contactFonctionId';
  readonly contactFonctionId = 'contactFonctionId';
  static readonly contactFonctionInstanceLabel = 'contactFonctionInstanceLabel';
  readonly contactFonctionInstanceLabel = 'contactFonctionInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly fonction = 'fonction';
  readonly fonction = 'fonction';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly mail = 'mail';
  readonly mail = 'mail';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly tags = 'tags';
  readonly tags = 'tags';
  static readonly tagsid = 'tagsid';
  readonly tagsid = 'tagsid';
  static readonly telephone = 'telephone';
  readonly telephone = 'telephone';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrContactPojoGen_();
  }
}

